const APP_STRING = {
    "en-US": {
        name: "English (US)",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "Cannot find activity {0}",
            vdpActivity_closeModal: "Close",
            vdpActivity_correctExclamation: "Correct!",
            vdpActivity_correct: "Correct",
            vdpActivity_correctAnswerBadge: "Correct Answer",
            vdpActivity_partiallyCorrect: "Partially correct!",
            vdpActivity_incorrect: "Incorrect",
            vdpActivity_notCorrect: "Not Correct",
            vdpActivity_continue: "Continue",
            vdpActivity_checkAnswer: "Check Answer",
            vdpActivity_submit: "Submit",
            vdpActivity_submitted: "Submitted",
            vdpActivity_ready: "Ready.",
            vdpActivity_cardsPlaced: "All the cards have been placed.",
            vdpActivity_cardsCorrectHeader: "Success!",
            vdpActivity_cardsCorrect: "All cards have been placed successfully.",
            vdpActivity_cardsCorrectContinue: "Continue to the next activity.",
            vdpActivity_cardsPartiallyCorrectHeader: "Not quite.",
            vdpActivity_cardsPartiallyCorrect: "Review the incorrect answers and try moving those to a new place.",
            vdpActivity_cardsCheckAnswers: "Check Your Answers",
            vdpActivity_textEntryPlaceholder: "Your response",
            vdpActivity_textEntryNavigatorPrompt: "The navigator, {0}, will enter text below",
            vdpActivity_textEntryPrompt: "Enter text below:",
            vdpActivity_textEntrySubmit: "Submit",
            vdpActivity_editableCardWarning: "Please fill out all the empty cards before submitting.",
            vdpActivity_selectOne: "Select one",
            vdpActivity_selectOneOrMany: "Select one or many",
            vdpActivity_defaultCurrentParticipantName: "Me",
            vdpActivity_hotspotTooltip: "Select the hotspots to learn more.",
            vdpActivity_matchingSuccess: "Success!",
            vdpActivity_matchingSuccessDescription: "All the cards have been placed correctly.",
            vdpActivity_matchingFail: "Not quite.",
            vdpActivity_matchingFailDescription:
                "Drag the incorrect cards back to the left column. Then try matching them to a new spot.",
            vdpActivity_matchingReady: "Ready.",
            vdpActivity_matchingReadyDescription: "All the cards have been placed.",
            vdpActivity_matchingCheckAnswers: "Check Your Answers",
            vdpActivity_matchingContinue: "Continue to the next activity.",
            vdpActivity_cardStackWithVisualAriaLabel: "Card Stack With Visuals",
            vdpActivity_cardStackWithVisualAriaDescription: "Put the cards into the correct order",
            vdpActivity_revealCardAriaLabel: "Reveal Card Step",
            vdpActivity_revealCardAriaDescription: "Select the card to reveal it",
            vdpActivity_dragAndDropAriaLabel: "Drag and Drop Step",
            vdpActivity_dragAndDropAriaDescription: "Drag and drop the cards in the correct columns",
            vdpActivity_matchingStepAriaLabel: "Matching Cards Step",
            vdpActivity_matchingStepAriaDescription: "Move the card to the correct place to match it",
            vdpActivity_recallStepAriaLabel: "Recall Step",
            vdpActivity_recallStepAriaDescription: "This is a recall step, follow the instructions",
            vdpActivity_multipleChoiceAriaLabel: "Multiple Choice Step",
            vdpActivity_multipleChoiceAriaDescription: "Choose from the multiple choice options.",
            vdpActivity_textEntryAriaLabel: "Text Entry Activity",
            vdpActivity_textEntryAriaDescription: "Submit your response into the text field",
            vdpActivity_singleAssetStepAriaLabel: "Single Asset Step",
            vdpActivity_singleAssetStepAriaDescription: "Follow the instructions.",
            vdpActivity_comparisonStepAriaLabel: "Comparison Step",
            vdpActivity_comparisonStepAriaDescription: "Follow the instructions.",
            vdpActivity_FirstTitlePosition: "You are at the first item",
            vdpActivity_LastTitlePosition: "You are at the last item",
            vdpActivity_videoStepAriaLabel: "Video Step",
            vdpActivity_videoStepAriaDescription: "In this step, a video will be show to the participants",
            vdpActivity_progressCheckAriaLabel: "Progress Check",
            vdpActivity_progressCheckAriaDescription:
                "You are ahead of pace. Try not to rush through the remaining material",
            vdpActivity_exitStepAriaLabel: "Exit Step",
            vdpActivity_exitStepAriaDescription: "We've completed our experience. You may now close this window",
            vdpClipboard_copied: "Copied to clipboard!",
            vdpClipboard_copy: "Copy to clipboard",
            vdpClipboard_error: "Unable to copy to clipboard",
            vdpHud_hide: "Hide",
            vdpHud_show: "Show",
            vdpHud_changeNavigatorTitle: "Change the navigator to a new person.",
            vdpHud_changeNavigatorText1:
                'You selected "Change the Navigator". This will randomly pick another navigator and will briefly disable all interactions on this page.',
            vdpHud_changeNavigatorText2: "Are you sure you want to change the navigator?",
            vdpHud_close: "Close",
            vdpHud_accept: "Change the Navigator",
            vdpHud_back: "Back",
            vdpHud_next: "Next",
            vdpHud_endExperience: "End Experience",
            vdpHud_defaultExitMsg:
                "We’ve completed our experience. Thanks for participating! We hope you found today’s discussion insightful. You may close this window.",
            vdpHud_navigatorReminder: "You're the navigator. Please read the following:",
            vdpHud_timerNoTimeSpecified: "No time specified for section",
            vdpHud_timerRemaining: "remaining in section",
            vdpHud_timerLessThanOneMinute: "Less than one minute left",
            vdpHud_timerOver: "over",
            vdpHud_roleNone: "None Assigned",
            vdpHud_infoTableSection: "Section",
            vdpHud_infoTableRecommended: "Recommended",
            vdpHud_infoTableActual: "Actual",
            vdpHud_infoTableTotal: "Total",
            vdpHud_infoTableRemaining: "Remaining",
            vdpHud_infoTeamButton: "Team",
            vdpHud_infoTeamButtonDescription:
                "Select this item to view a list of the session participants, rotate the navigator role to a random participant, and change your participant name.",
            vdpHud_infoTeamParticipantJoining: "User joining...",
            vdpHud_navigator: "Navigator",
            vdpHud_facilitator: "Facilitator",
            vdpHud_facilitatorMenuMakeNavigator: "Make Navigator",
            vdpHud_facilitatorMenuRemoveFacilitator: "Remove Facilitator Role",
            vdpHud_removeFacilitatorWarningConfirm: "Confirm",
            vdpHud_removeFacilitatorWarningCancel: "Cancel",
            vdpHud_removeFacilitatorWarningMessage:
                "You are about to leave your role as facilitator. Are you sure you want to continue?",
            vdpHud_progressOnPaceMessage: "On Pace",
            vdpHud_progressAheadOfPaceMessage: "Ahead of Pace",
            vdpHud_progressBehindPaceMessage: "Behind Pace",
            vdpHud_progressTimeRemaining: "{0} remaining in section",
            vdpHud_progressStepsInSection: "Page {0} of {1}",
            vdpHud_zoomControlsTooltip: "Visual Zoom Controls",
            vdpHud_zoomControlsDescription: "Use these actions to zoom in and out of the visual.",
            vdpHud_zoomControlsZoomIn: "Zoom In",
            vdpHud_zoomControlsZoomOut: "Zoom Out",
            sidebar_scheduleButton: "Schedule",
            sidebar_scheduleButtonDescription:
                "View a summary estimating the time for which each section is expected to take and how much time has been spent in each section.",
            sidebar_teamAssignNavigator: "Assign Navigator",
            sidebar_teamChooseRandomly: "Choose Randomly",
            sidebar_teamChooseHelperText:
                "Chooses an eligible participant at random to serve as the navigator. Participants will not be chosen again until everyone has had a turn.",
            sidebar_editNameMenuItem: "Edit Name",
            sidebar_editNameSave: "Save Changes",
            sidebar_nameEditPlaceholder: "Enter the name you want to be called...",
            sidebar_manageRolesMenuLabel: "Manage Roles",
            sidebar_navigatorDescription:
                "Reads questions, prompts, and instructions aloud. Advances the group through the experience by selecting Next and following other directions noted on each screen.",
            sidebar_facilitatorDescription:
                "Serves as a guide throughout the session to make sure the group has a rich discussion and the conversation stays on track.",
            sidebar_previousButton: "Previous",
            sidebar_previousButtonDescription:
                "Select this button to advance the group's session to the previous page.",
            sidebar_nextButton: "Next",
            sidebar_nextButtonDescription: "Select this button to advance the group's session to the next page.",
            sidebar_lockTooltip: "Only the navigator can change the page.",
            sidebar_navigatorBadge: "You're the Navigator",
            sidebar_navigatorBadgeDescription:
                "The navigator is responsible for advancing the group to the next steps in the session. Use the 'Next' and 'Previous' buttons to navigate the group.",
            sidebar_facilitatorBadge: "You're a Facilitator",
            sidebar_introductionLabel: "Introduction",
            sidebar_introductionInstruction: "Read aloud to the group:",
            sidebar_directionsLabel: "Directions",
            sidebar_nonNavigatorDirectionsLabel: "{0} does the following:",
            sidebar_directionsInstruction: "Do the following:",
            sidebar_facilitationNotesLabel: "Facilitation Notes",
            sidebar_skipButtonDescription:
                "Select this button to skip the welcome section for the entire group's session.",
            genUrl_copyLink: "Copy link",
            genUrl_sessionLinkDetails:
                "These session links will disappear when you refresh the page. Be sure to download or copy these links before leaving this page.",
            genUrl_exportAsCsv: "Export as CSV",
            genUrl_copyTable: "Copy table",
            genUrl_linkExpiration: "Link Expiration",
            genUrl_link: "Link",
            genUrl_title: "{0} Session Link Generator",
            genUrl_experience: "Experience: {0}",
            genUrl_description:
                "This portal lets you generate session links for this experience. To generate session links, enter the number of sessions you would like to generate, pick a date the sessions should expire, and select Generate Sessions. Once these links are generated, copy them and paste into your favorite text editor or spreadsheet program. Once you refresh the page, all the links will disappear.",
            genUrl_numSessions: "Number of sessions:",
            genUrl_sessionsExpirationLabel: "Session links expire on:",
            genUrl_sessionsOpenAtLabel: "Session links are valid starting on:",
            genUrl_numSessionsInputLabel: "Number of sessions",
            genUrl_sessionLanguageLabel: "Session Language",
            genUrl_numSessionsInputTypeError: "Must be a number",
            genUrl_closesOnError: "Pick a date in the future",
            genUrl_experienceClosesOnError: "Pick a date prior to {0}",
            genUrl_notifSuccessSingle: "Successfully generated {0} link",
            genUrl_notifSuccessMultiple: "Successfully generated {0} links",
            genUrl_notifErrorGenerating: "Unable to generate sessions. Please try again or contact administrator.",
            genUrl_notifErrorProcessing: "Unable to process. Contact an administrator.",
            genUrl_sessionsExpireToday: "Sessions will expire today",
            genUrl_sessionsExpireInOneDay: "Sessions will expire in {0} day",
            genUrl_sessionsExpireInDays: "Sessions will expire in {0} days",
            genUrl_sessionsOpenToday: "Sessions will open today",
            genUrl_sessionsOpenOneDay: "Sessions will open in {0} day",
            genUrl_sessionsOpenDays: "Sessions will open in {0} days",
            genUrl_generate: "Generate sessions",
            genUrl_schedulingLinkExpired: "Scheduling link has expired.",
            genUrl_error: "Error",
            genUrl_dataCollectionLabel: "Data Collection",
            genUrl_dataCollectionDescription:
                "Session links are intended for a scheduled experience. Turn off this button if the links are intended for previewing or conducting a demo or training of the experience.",
            genUrl_dataCollectionDefaultLabel: "Data Collection Default",
            genUrl_emailContact: "Email Contact",
            genUrl_goToSessionRepoReport: "Reporting Tool",
            genUrl_archiveButton: "Archive",
            genUrl_resurrectButton: "Resurrect",
            genUrl_destroyButton: "Destroy",
            vdpLoading_waitForContent: "Please wait while your content loads…",
            vdpNotFound_title: "Virtual Dialogue Platform",
            vdpNotFound_message: "The page you are looking for could not be found.",
            vdpRenderer_refresh: "Refresh",
            vdpRenderer_refreshDescription:
                "You need to refresh the page in order to attempt to reconnect to the session.",
            vdpRenderer_sessionDisconnectedHeader: "You've been disconnected.",
            vpdRenderer_inactiveDisconnectedBody:
                "You've been disconnected due to inactivity. Select the button below to attempt to reconnect.",
            vdpRenderer_inactiveHeader: "You're inactive.",
            vdpRenderer_inactiveBody:
                "You've been inactive for too long. Select the button below to remain active or you will be removed from the session when the timer runs out.",
            vdpRenderer_setSelfActiveButton: "Set yourself as active",
            vdpRenderer_setSelfActiveButtonDescription:
                "Select this button to remain an active and connected participant of the current session.",
            vdpRenderer_sessionDisconnected: "Attempting to reconnect",
            vdpRenderer_sessionDisconnectedReload: "Please reload",
            vdpRenderer_endOfSession: "End of Session",
            vdpRenderer_invalidMapUrl:
                "An invalid URL was provided for the visual. Please contact Root for assistance.",
            vdpRenderer_unexpectedError: "An unexpected system error has occurred. Here are the technical details:",
            vdpRenderer_unexpectedErrorMsg: "An unexpected error has occurred: unknown session state {0}",
            vdpRenderer_defaultEndingMessage: "You may now close your window and disconnect from audio.",
            vdpErrorModal_title: "Cannot Load Page",
            vdpErrorModal_message: "Something went wrong! Please contact us for assistance at: vmap.help@rootinc.com",
            vdpErrorModal_body: "The error may be resolved by reloading this page or selecting below:",
            vdpErrorModal_button: "Reload this experience.",
            vdpNameEntry_welcomeText: "Welcome",
            vdpNameEntry_defaultWelcomeMessage:
                "This virtual dialogue session will be fully interactive. Prepare to explore information and share your thoughts and opinions with your peers.",
            vdpNameEntry_inputName: "What do you want to be called?",
            vdpNameEntry_inputNamePlaceholder: "Enter the name you want to be called:",
            vdpNameEntry_inputDepartment: "What department are you in?",
            vdpNameEntry_inputEmail: "What's your email address?",
            vdpNameEntry_inputTeam: "What team are you on?",
            vdpNameEntry_saveDescription: "Submit what you would like to be called and await the start of the session.",
            vdpNameEntry_continueDescription: "Submit what you would like to be called and join the ongoing session.",
            vdpNameEntry_identifiersName: "Please enter name",
            vdpNameEntry_identifiersDuplicateName:
                "It looks like there's already a {0} connected today. Would you like to try entering a different name so we can tell you apart? Consider adding an initial for your last name or entering a nickname.",
            vdpNameEntry_identifiersDepartment: "Please enter department name",
            vdpNameEntry_identifiersEmail: "Please enter email",
            vdpNameEntry_identifiersInvalidEmailFormat: "Invalid email format",
            vdpNameEntry_identifiersTeam: "Please enter team name",
            vdpNameEntry_welcomeHeader: "Nice to meet you, {0}!",
            vdpNameEntry_welcomeAdvance: "Meet Your Team",
            vdpNameEntry_claimFacilitatorTitle: "Claim the Facilitator Role",
            vdpNameEntry_facilitatorClaimed: "Facilitator Role Claimed",
            vdpNameEntry_claimFacilitatorButton: "Claim Facilitator",
            vdpNameEntry_claimFacilitatorModalCancel: "Cancel",
            vdpNameEntry_claimFacilitatorModalParagraphOne:
                "If you are the facilitator of this session, select Claim Facilitator below.",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "As facilitator, you will have control of the first few pages to get us started. Then, the system will automatically select a new navigator.",
            vdpNameEntry_claimFacilitatorModalParagraphThree:
                "If you have been invited to this session as a participant, please select Cancel.",
            vdpNameEntry_smallScreenWarningTitle: "We're glad you're here!",
            vdpNameEntry_smallScreenWarningBody: "... For the best experience, please log in from a laptop or desktop.",
            vdpNameEntry_smallScreenWarningDismiss: "Dismiss",
            vdpNameEntry_numParticipantsInSession: "{0} participants are waiting...",
            vdpNameEntry_oneParticipantsInSession: "1 participant is waiting...",
            vdpNameEntry_save: "Save",
            vdpNameEntry_continue: "Continue",
            vdpNameEntry_thanks: "Thanks, {0}. We'll get started soon!",
            vdpNameEntry_intro: "Enter your name and wait for others to join. We'll get started soon!",
            vdpNameEntry_introStarted: "Enter your name and join your peers.",
            vdpNameEntry_navDirections: "Wait for the team to join.",
            vdpValidationErrorMinLength: "Please enter at least {0} characters.",
            vdpValidationErrorMaxLength: "Please enter no more than {0} characters.",
            toolbar_stepOf: "Section {0}, Page {1} - {2} ({3})",
            toolbar_intro: "Step {0} of Section {1}",
            toolbar_welcome: "Welcome Sequence",
            toolbar_exit: "Exit Sequence",
            toolbar_reloadContent: "Reload Content",
            toolbar_commentCreationButton: "Comment ({0})",
            toolbar_buildComments: "Build Comments",
            toolbar_commentListButton: "View Comments ({0})",
            toolbar_commentCreationTitle: "Add Build Comment",
            toolbar_commentCreationDescription:
                "Comments will be saved and sent to the build team. Please include your name or initials with your comment.",
            toolbar_commentCreationCancelButton: "Cancel",
            toolbar_commentCreationSubmitButton: "Post Comment",
            toolbar_commentCreationSubmitButtonDisabled: "Posting Comment...",
            toolbar_commentCreationPlaceholder: "Enter Comment...",
            toolbar_previewMode: "Preview Mode:",
            toolbar_aboutPreviewMode: "About Preview Mode",
            toolbar_focusAreaSelector: "Focus Area Selector",
            toolbar_hotspotSelector: "Hotspot Selector",
            toolbar_previewModeModalTitle: "Preview Mode",
            toolbar_previewModeModalFirstIntroduction:
                "You are viewing this experience in Preview Mode. This view is designed for Root internal review only and provides access to Authoring Tools.",
            toolbar_previewModeModalSecondIntroduction:
                "These options are only available in Preview Mode and will not be visible to the client or participants.",
            toolbar_previewModeModalReloadContent: "Reload Content",
            toolbar_previewModeModalReloadContentDescription:
                "Use this button to refresh the experience with the latest updates from Contentful without having to reload the session.",
            toolbar_previewModeModalComments: "Comments",
            toolbar_previewModeModalCommentsDescription:
                "Add a comment to provide feedback or note any changes that need to be made. Comments will be captured in Contentful for the build team.",
            toolbar_previewModeModalMapCoordinates: "Visual Coordinates",
            toolbar_previewModeModalMapCoordinatesDescription: "Copy the coordinates of the visual.",
            toolbar_previewModeModalHotspotSelector: "Hotspot Selector",
            toolbar_previewModeModalHotspotSelectorDescription:
                "Preview hotspot locations and copy coordinates for use in Contentful.",
            coordinatePreview_coordinatesSelectorTitle: "Coordinates Selector",
            coordinatePreview_hotspotMode: "Hotspot Mode",
            coordinatePreview_hotspotModeCoordinatesCopiedToast:
                "Hotspot coordinates have been copied to your clipboard.",
            coordinatePreview_focusAreaMode: "Focus Area Mode",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast:
                "Focus area coordinates have been copied to your clipboard.",
            coordinatePreview_directionsTitle: "Coordinates Selector Directions",
            coordinatePreview_hotspotDirectionsLineOne: "Select a point on the visual to create a hotspot.",
            coordinatePreview_hotspotDirectionsLineTwo:
                "Select the Copy button on each hotspot to copy coordinates to Contentful.",
            coordinatePreview_focusAreaDirectionsLineOne: "Select and drag to draw a rectangle on the visual.",
            coordinatePreview_focusAreaDirectionsLineTwo:
                "Place the rectangle over the area that will be visible for a step.",
            coordinatePreview_focusAreaDirectionsLineThree:
                "Select the Copy button on each focus area to copy coordinates to Contentful.",
            coordinatePreview_reset: "Reset",
            coordinatePreview_done: "Done",
            navigatorDefaultInstructions_selectNextToContinue: "Select ***Next*** to continue.",
            navigatorDefaultInstructions_progressCheckLineOne: "We've reached the end of section {0}.",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace: "We’re on pace. Let’s keep up the good work!",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "We’re ahead of pace. Let’s try not to rush through the remaining material.",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "We’re behind pace. We'll need to move faster to complete the session on time.",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav:
                "On the next page, we’ll introduce a new navigator for section {0}.",
            maintenanceWindow_heading: "Maintenance is scheduled on {0}, {1} from {2} - {3} {4}.",
            maintenanceWindow_body: "The application may not be available during this time.",
            maintenanceError_overlap:
                "The dates you've chosen conflict with a scheduled maintenance period on {0}. Maintenance occurs every week from {1} to {2}. Please make sure the starting and expiration dates do not overlap with a maintenance window.",
            progressCheck_sectionTitle: "Section {0} of {1}",
            progressCheck_paceCheckTitle: "Session Pace Check",
            progressCheck_paceMessageOnPace: "Keep up the good work!",
            progressCheck_paceMessageAheadOfPace: "Try not to rush through the remaining material.",
            progressCheck_paceMessageBehindPace: "You'll need to move faster to complete the session on time.",
            progressCheck_navigatorChangeTitle: "Navigator Change",
            progressCheck_navigatorChangeMessage:
                "Thank you for leading, {0}. A new navigator will be assigned on the next page.",
            vdpIntro_title: "Introduction",
            vdpIntro_subtitle: "A few quick things before we get started...",
            vdpIntro_joinTheTeam: "Join the Team",
            vdpIntro_facilitatorRole: "Facilitator Role",
            vdpIntro_navigatorRole: "Navigator Role",
            vdpIntro_participantRole: "Participant Role",
            vdpIntro_iceBreaker: "Meet the Team",
            vdpIntro_exploreTheVisual: "The Visual",
            vdpIntro_skipShort: "Skip",
            vdpIntro_skipLong: "Skip Introduction",
            vdpIntro_facilitatorInstructionsOne:
                "Welcome! Today’s session is different from other courses or training you may have attended.",
            vdpIntro_facilitatorInstructionsTwo:
                "There is no teacher or trainer – you’ll learn from each other and the information on screen.",
            vdpIntro_facilitatorInstructionsThree:
                "My role as facilitator is to keep the conversation on track and on time.",
            vdpIntro_navigatorInstructionsOne:
                "For the next few screens, I’ll also serve as our navigator. The navigator’s role is to read the Introduction text on each screen, select the ***Next*** button to move us through the experience, and follow other directions as prompted.",
            vdpIntro_navigatorInstructionsTwo:
                "The navigator role will be assigned randomly and will change every few screens.",
            vdpIntro_participantInstructionsOne:
                "You are all participants! Your role is to actively participate by sharing your perspectives with the group. You’ll help the session run smoothly by taking turns talking, respecting others’ thoughts and opinions, and keeping the conversation on track.",
            vdpIntro_participantInstructionsTwo:
                "You can see who is in the session and who has each role using the ***Team*** button at the lower left.",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "Next, we’ll each get a chance to see what the navigator role is like as we introduce ourselves.",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "When you see the ***You’re the Navigator*** banner above the Introduction text, it’s your turn to read and follow the directions. Does anyone have any questions?",
            vdpIntro_iceBreakerInstructionsOne: "Hi, I’m {0}.",
            vdpIntro_iceBreakerInstructionsDefault: "One fact about me is _______.",
            vdpIntro_mapInstructionsOne: "Now that we’ve all met, let’s learn more about our session today.",
            vdpIntro_mapInstructionsTwo:
                "Our conversation will center on an image called a *Learning Map*® visual. It’s an illustrated metaphor representing aspects of our business that we’ll explore together. ",
            vdpIntro_iceBreakerIntroDirections: "Answer any questions.",
            vdpEnd_title: "Thank you.",
            vdpEnd_body:
                "We’ve completed our experience. Thanks for participating! We hope you found today’s discussion insightful.",
            vdpEnd_surveyRequest:
                "Please take a few minutes to share your thoughts and reactions to the information we discussed. Your feedback is important to our success.",
            vdpEnd_surveyThankYou: "Thank you for your response.",
            vdpEnd_sessionComplete: "The session is complete.",
            vdpEnd_closeWindow: "You may close this window.",
            privacyBanner_title: "Data Privacy",
            privacyBanner_message:
                "The protection of your personal data is very important to Accenture and any personal data you provide through this tool may be processed by Accenture. Accenture is committed to keeping your personal data secure and processing it, in accordance with applicable privacy laws and our internal policies. Personal data Accenture processes may be transferred or be accessible internationally throughout Accenture’s global business and between its entities and affiliates,and third parties. Before using this tool, Accenture invites you to carefully read its privacy statement [Privacy Statement]({1}) and [Cookies Policy]({0}), which includes important information on why and how Accenture is processing your personal data.",
            privacyBanner_dismiss: "Agree",
            privacyBanner_dismissDescription:
                "This action will dismiss the notice detailing the site's privacy and cookie policies.",
            adminMessage_unauthorized: "You are unauthorized to take the following action: {0}.",
            adminMessage_unauthorized_viewExp: "view all experiences",
            sessionReport_submitButton: "Generate Report",
            sessionReport_header_title: "{0} Session Reporting Tool",
            sessionReport_header_experience: "Experience Name: {0}",
            sessionReport_header_errorTitle: "Error Loading",
            sessionReport_header_errorSubtitle: "Could not retrieve experience information.",
            sessionReport_switch_unknown: "Unrecognized Report Result Type",
            sessionReport_results_downloadResults: "Download Results",
            sessionReport_results_noRecordsTitle: "No Records Found",
            sessionReport_results_noRecordsSubTitle: "There is no data that fits that criteria.",
            sessionReport_results_errorTitle: "Error Generating Report",
            sessionReport_basicInfo_startDate: "Start Date",
            sessionReport_basicInfo_endDate: "End Date",
            sessionReport_basicInfo_useSeedData: "Use Seed Data",
            sessionReport_basicInfo_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            sessionReport_switch_basicInfo: "Basic Session Info",
            activityReport_basicInfo_startDate: "Start Date",
            activityReport_basicInfo_endDate: "End Date",
            activityReport_basicInfo_useSeedData: "Use Seed Data",
            activityReport_basicInfo_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            activityReport_switch_basicInfo: "Basic Activity Info",
            activityReport_basicInfoAggregated_startDate: "Start Date",
            activityReport_basicInfoAggregated_endDate: "End Date",
            activityReport_basicInfoAggregated_useSeedData: "Use Seed Data",
            activityReport_basicInfoAggregated_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            activityReport_switch_basicInfoAggregated: "Basic Activity Info Aggregated",
            multipleChoiceReport_basicInfo_startDate: "Start Date",
            multipleChoiceReport_basicInfo_endDate: "End Date",
            multipleChoiceReport_basicInfo_useSeedData: "Use Seed Data",
            multipleChoiceReport_basicInfo_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            multipleChoiceReport_switch_basicInfo: "Basic Multiple Choice Info",
            multipleChoiceReport_basicInfo_activityId: "Enter Activity ID",
            multipleChoiceReport_basicInfoAggregated_startDate: "Start Date",
            multipleChoiceReport_basicInfoAggregated_endDate: "End Date",
            multipleChoiceReport_basicInfoAggregated_useSeedData: "Use Seed Data",
            multipleChoiceReport_basicInfoAggregated_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            multipleChoiceReport_switch_basicInfoAggregated: "Basic Multiple Choice Info Aggregated",
            multipleChoiceReport_basicInfoAggregated_activityId: "Enter Activity ID",
            dragAndDropReport_basicInfo_useSeedData: "Use Seed Data",
            dragAndDropReport_basicInfo_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            dragAndDropReport_basicInfo_startDate: "Start Date",
            dragAndDropReport_basicInfo_endDate: "End Date",
            dragAndDropReport_switch_basicInfo: "Basic Drag & Drop Info",
            dragAndDropReport_basicInfo_activityId: "Enter Activity ID",
            dragAndDropReport_basicInfoAggregated_useSeedData: "Use Seed Data",
            dragAndDropReport_basicInfoAggregated_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            dragAndDropReport_basicInfoAggregated_startDate: "Start Date",
            dragAndDropReport_basicInfoAggregated_endDate: "End Date",
            dragAndDropReport_switch_basicInfoAggregated: "Basic Drag & Drop Info Aggregated",
            dragAndDropReport_basicInfoAggregated_activityId: "Enter Activity ID",
            //Drag And Drop Basic Info Report Strings
            editableCardReport_basicInfo_useSeedData: "Use Seed Data",
            editableCardReport_basicInfo_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            editableCardReport_basicInfo_startDate: "Start Date",
            editableCardReport_basicInfo_endDate: "End Date",
            editableCardReport_switch_basicInfo: "Basic Editable Card Info",
            editableCardReport_basicInfo_activityId: "Enter Activity ID",
            // confirm dialog
            confirmDialog_confirmValuePrompt: "To confirm this action, enter the value __*{0}*__ in the input below.",
            confirmDialog_confirmButton: "Confirm",
            confirmDialog_closeButton: "Close",
            confirmDialog_error_checkBox: "You must check the box to successfully confirm this action.",
            confirmDialog_error_confirmValue:
                "You must enter the value __*{0}*__ in order to successfully confirm this action.",
            confirmDialog_scheduler_title: "Confirm Archive",
            confirmDialog_scheduler_body:
                "You are about to archive the experience named {0}. This will disable access to the experience, including any session links that may have been generated for future sessions. To confirm this action, check the box and enter the experience's name.",
            confirmDialog_scheduler_resurrectTitle: "Confirm Resurrect",
            confirmDialog_scheduler_resurrectBody:
                "You are about to resurrect the experience named {0}. This will enable access to the experience. Any generated links for future sessions will become valid. Please note that you may need to update the expiration date for the experience after this action completes. To confirm this action, check the box and enter the experience's name.",
            confirmDialog_scheduler_destroyTitle: "Confirm Destroy",
            confirmDialog_scheduler_destroyBody:
                "You are about to destroy the experience named {0}. This is an unrecoverable action that cannot be undone. Prior to executing this action you must confirm the following: \n1. All assets of the experience have been downloaded and archived in project land;\n2. A video of navigating through the experience has been taken and stored in project land;\n3. The project contacts (if any are listed from the Contentful field in our system) have been contacted to verify that deleting is acceptable;\n4. The session repository data - text entry activities and the general repository - have been downloaded and archived in project land.;\n\nTo confirm this action, check the box and enter the experience's name.",
            confirmDialog_dataCollectionDefault_title: "Confirm Change to Data Collection Default",
            confirmDialog_dataCollectionDefault_body:
                "You have chosen to {0} the data collection feature on the {1} experience. This will affect all sessions that are generated in the future for this experience. Are you sure you want to {0} data collection for this experience?",
            confirmDialog_dataCollectionDefault_body_enable: "enable",
            confirmDialog_dataCollectionDefault_body_disable: "disable",
            confirmDialog_dataCollection_off:
                "This action will disable data collection.  Please confirm that at least one of the following conditions is true:\n1. These links are for the purpose of previewing or conducting a demo of the experience.\n2. These links are for a scheduled experience: I am aware of the contractual data agreement and have authority to disable data collection for this experience.",
            confirmDialog_dataCollection_on:
                "This action will enable data collection. Please confirm the following is true:\n1. I am aware of the contractual data agreements and have authority to enable data collection for this experience.\n2. These links are intended for a scheduled experience (not a demo or preview)",
            confirmDialog_enable_NavigatorTitle: "Are you sure?",
            confirmDialog_enable_NavigatorBody:
                "By selecting '***Confirm***', you'll get the group started by reading the information on the next few pages aloud. Select '***Close***' if you prefer not to be the first navigator.",
            vdpNameEntry_NavigatorSelectionMessage:
                "We'll need a navigator to read the instructions aloud to get us started.",
            vdpNameEntry_NavigatorButtonMessage: "Select here to serve as the first navigator.",
            vdpActivity_New: "new activity",
        },
    },
    es: {
        name: "Spanish",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "No se puede encontrar la actividad {0}",
            vdpActivity_closeModal: "Cierre",
            vdpActivity_flipCard: "Dar vuelta",
            vdpActivity_correctExclamation: "¡Correcto!",
            vdpActivity_correct: "Correcto",
            vdpActivity_correctAnswerBadge: "Respuesta correcta",
            vdpActivity_partiallyCorrect: "¡Parcialmente correcto!",
            vdpActivity_incorrect: "Incorrecto",
            vdpActivity_notCorrect: "No es correcto",
            vdpActivity_continue: "Continuar",
            vdpActivity_checkAnswer: "Verificar respuesta",
            vdpActivity_submit: "Enviar",
            vdpActivity_ready: "Listo.",
            vdpActivity_cardsPlaced: "Se han colocado todas las tarjetas.",
            vdpActivity_cardsCorrectHeader: "¡Bien hecho!",
            vdpActivity_cardsCorrect: "Se han colocado todas las tarjetas con éxito.",
            vdpActivity_cardsCorrectContinue: "Continúe con la siguiente actividad.",
            vdpActivity_cardsPartiallyCorrectHeader: "No exactamente.",
            vdpActivity_cardsPartiallyCorrect: "Revise las respuestas incorrectas e intente moverlas a un nuevo lugar.",
            vdpActivity_cardsCheckAnswers: "Verifique sus respuestas",
            vdpActivity_textEntryPlaceholder: "Su respuesta",
            vdpActivity_textEntryNavigatorPrompt: "El navegador, {0}, ingresará el texto a continuación",
            vdpActivity_textEntryPrompt: "Ingresar texto a continuación:",
            vdpActivity_textEntrySubmit: "Enviar",
            vdpActivity_selectOne: "Seleccionar una",
            vdpActivity_selectOneOrMany: "Seleccionar una o muchas",
            vdpActivity_defaultCurrentParticipantName: "Yo",
            vdpActivity_hotspotTooltip: "Seleccione los puntos de interés para obtener más información.",
            vdpClipboard_copied: "¡Copiado al portapapeles!",
            vdpClipboard_copy: "Copiar al portapapeles",
            vdpClipboard_error: "No se puede copiar al portapapeles",
            vdpHud_hide: "Ocultar",
            vdpHud_show: "Mostrar",
            vdpHud_changeNavigatorTitle: "Cambie de navegador y elija una persona nueva.",
            vdpHud_changeNavigatorText1:
                "Seleccionó “Cambiar de navegador”. Esta acción escogerá aleatoriamente a otro navegador y deshabilitará brevemente todas las interacciones en esta página.",
            vdpHud_changeNavigatorText2: "¿Está seguro de que desea cambiar de navegador?",
            vdpHud_close: "Cierre",
            vdpHud_accept: "Cambiar de navegador",
            vdpHud_back: "Anterior",
            vdpHud_next: "Siguiente",
            vdpHud_endExperience: "Finalizar experiencia",
            vdpHud_defaultExitMsg:
                "Hemos completado nuestra experiencia. ¡Gracias por participar! Esperamos que la conversación de hoy le haya resultado esclarecedora. Puede cerrar esta ventana.",
            vdpHud_navigatorReminder: "Usted es el navegador. Lea lo siguiente:",
            vdpHud_timerNoTimeSpecified: "No hay tiempo especificado para la sección",
            vdpHud_timerRemaining: "restante(s) en la sección",
            vdpHud_timerLessThanOneMinute: "Queda menos de un minuto",
            vdpHud_timerOver: "over",
            vdpHud_roleNone: "Ninguno asignado",
            vdpHud_infoTableSection: "Sección",
            vdpHud_infoTableRecommended: "Recomendado",
            vdpHud_infoTableActual: "Real",
            vdpHud_infoTableTotal: "Total",
            vdpHud_infoTableRemaining: "Restante",
            vdpHud_infoTeamButton: "Equipo",
            vdpHud_infoTeamParticipantJoining: "Usuario uniéndose…",
            vdpHud_navigator: "Navegador",
            vdpHud_facilitator: "Facilitador",
            vdpHud_facilitatorMenuMakeNavigator: "Convertir en navegador",
            vdpHud_facilitatorMenuRemoveFacilitator: "Eliminar rol de facilitador",
            vdpHud_removeFacilitatorWarningConfirm: "Confirmar",
            vdpHud_removeFacilitatorWarningCancel: "Cancelar",
            vdpHud_removeFacilitatorWarningMessage:
                "Está a punto de abandonar su rol como facilitador. ¿Está seguro de que desea continuar?",
            vdpHud_progressOnPaceMessage: "A buen ritmo",
            vdpHud_progressAheadOfPaceMessage: "Adelantado",
            vdpHud_progressBehindPaceMessage: "Retrasado",
            vdpHud_progressTimeRemaining: "{0} restante(s) en la sección",
            vdpHud_progressStepsInSection: "Página {0} de {1}",
            vdpHud_zoomControlsTooltip: "Controles para acercar/alejar del material visual",
            sidebar_scheduleButton: "Programar",
            sidebar_teamAssignNavigator: "Asignar navegador",
            sidebar_teamChooseRandomly: "Escoger aleatoriamente",
            sidebar_teamChooseHelperText:
                "Escoge aleatoriamente a un participante elegible para que actúe como navegador. No se repetirá la selección de participantes hasta que todos hayan tenido un turno.",
            sidebar_editNameMenuItem: "Editar nombre",
            sidebar_editNameSave: "Guardar cambios",
            sidebar_nameEditPlaceholder: "Ingrese el nombre por el que quiera que lo llamen…",
            sidebar_manageRolesMenuLabel: "Gestionar roles",
            sidebar_navigatorDescription:
                "Lee preguntas, indicaciones e instrucciones en voz alta. Seleccione Siguiente y siga otras instrucciones que se indican en cada pantalla para hacer avanzar al grupo a lo largo de la experiencia.",
            sidebar_facilitatorDescription:
                "Actúa como guía durante toda la sesión para garantizar que el grupo tenga una conversación enriquecedora y que esta se mantenga encaminada.",
            sidebar_previousButton: "Anterior",
            sidebar_nextButton: "Siguiente",
            sidebar_lockTooltip: "Solo el navegador puede cambiar la página.",
            sidebar_navigatorBadge: "Usted es el navegador",
            sidebar_facilitatorBadge: "Usted es el facilitador",
            sidebar_introductionLabel: "Introducción",
            sidebar_introductionInstruction: "Lea en voz alta al grupo:",
            sidebar_directionsLabel: "Instrucciones",
            sidebar_nonNavigatorDirectionsLabel: "{0} hace lo siguiente:",
            sidebar_directionsInstruction: "Haga lo siguiente:",
            sidebar_facilitationNotesLabel: "Notas para la facilitación",
            genUrl_copyLink: "Copiar enlace",
            genUrl_sessionLinkDetails:
                "Estos enlaces a las sesiones desaparecerán cuando actualice la página. Asegúrese de descargar o copiar estos enlaces antes de abandonar la página.",
            genUrl_exportAsCsv: "Exportar como CSV",
            genUrl_copyTable: "Copiar tabla",
            genUrl_linkExpiration: "Vencimiento del enlace",
            genUrl_link: "Enlace",
            genUrl_title: "Generador de enlaces a las sesiones {0}",
            genUrl_experience: "Experiencia: {0}",
            genUrl_description:
                "Este portal le permite generar enlaces a las sesiones para esta experiencia. Para generar enlaces a las sesiones, escriba la cantidad de sesiones que desea generar, elija una fecha de vencimiento de las sesiones y seleccione Generar sesiones. Una vez generados estos enlaces, cópielos y péguelos en el editor de texto o programa de hoja de cálculo de su preferencia. Cuando actualice la página, todos los enlaces desaparecerán.",
            genUrl_numSessions: "Cantidad de sesiones:",
            genUrl_sessionsExpirationLabel: "Los enlaces a las sesiones vencen el:",
            genUrl_sessionsOpenAtLabel: "Los enlaces a las sesiones son válidos a partir del:",
            genUrl_numSessionsInputLabel: "Cantidad de sesiones",
            genUrl_sessionLanguageLabel: "Idioma de la sesión",
            genUrl_numSessionsInputTypeError: "Debe ser un número",
            genUrl_closesOnError: "Elija una fecha futura",
            genUrl_experienceClosesOnError: "Elija una fecha anterior al {0}",
            genUrl_notifSuccessSingle: "Generó con éxito {0} enlace",
            genUrl_notifSuccessMultiple: "Generó con éxito {0} enlaces",
            genUrl_notifErrorGenerating:
                "No fue posible generar las sesiones. Intente nuevamente o contacte al administrador.",
            genUrl_notifErrorProcessing: "No fue posible procesar. Contacte a un administrador.",
            genUrl_sessionsExpireToday: "Las sesiones vencen hoy",
            genUrl_sessionsExpireInOneDay: "Las sesiones vencerán en {0} día",
            genUrl_sessionsExpireInDays: "Las sesiones vencerán en {0} días",
            genUrl_sessionsOpenToday: "Las sesiones se abren hoy",
            genUrl_sessionsOpenOneDay: "Las sesiones se abrirán en {0} día",
            genUrl_sessionsOpenDays: "Las sesiones se abrirán en {0} días",
            genUrl_generate: "Generar sesiones",
            genUrl_schedulingLinkExpired: "El enlace de programación ha vencido.",
            genUrl_error: "Error",
            genUrl_dataCollectionLabel: "Recopilación de datos",
            genUrl_emailContact: "Contacto por correo electrónico",
            vdpLoading_waitForContent: "Espere mientras se carga su contenido…",
            vdpNotFound_title: "Plataforma de diálogo virtual",
            vdpNotFound_message: "No fue posible encontrar la página que busca.",
            vdpRenderer_refresh: "Actualizar",
            vdpRenderer_sessionDisconnectedHeader: "Se interrumpió su conexión.",
            vpdRenderer_inactiveDisconnectedBody:
                "Se interrumpió su conexión por inactividad. Seleccione el botón a continuación para intentar volver a conectarse.",
            vdpRenderer_inactiveHeader: "Está inactivo.",
            vdpRenderer_inactiveBody:
                "Estuvo inactivo durante demasiado tiempo. Seleccione el botón a continuación para permanecer activo o se lo retirará de la sesión cuando se agote el tiempo.",
            vdpRenderer_setSelfActiveButton: "Configurar su estado como activo",
            vdpRenderer_sessionDisconnected: "Intentando volver a conectar",
            vdpRenderer_sessionDisconnectedReload: "Volver a cargar",
            vdpRenderer_endOfSession: "Fin de la sesión",
            vdpRenderer_invalidMapUrl:
                "Se proporcionó una URL inválida al material visual. Comuníquese con Root para solicitar ayuda.",
            vdpRenderer_unexpectedError:
                "Ocurrió un error de sistema inesperado. A continuación, se brindan los detalles técnicos:",
            vdpRenderer_unexpectedErrorMsg: "Ocurrió un error inesperado: estado de sesión desconocido {0}",
            vdpRenderer_defaultEndingMessage: "Ahora puede cerrar la ventana y desconectar el audio.",
            vdpErrorModal_title: "No es posible cargar la página",
            vdpErrorModal_message: "Algo salió mal. Contáctenos para solicitar ayuda a: vmap.help@rootinc.com",
            vdpErrorModal_body:
                "El error podría resolverse si vuelve a cargar esta página o selecciona la opción a continuación:",
            vdpErrorModal_button: "Volver a cargar esta experiencia.",
            vdpNameEntry_welcomeText: "Bienvenido",
            vdpNameEntry_defaultWelcomeMessage:
                "Esta sesión de diálogo virtual será completamente interactiva. Prepárese para explorar información e intercambiar pensamientos y opiniones con sus pares.",
            vdpNameEntry_inputName: "¿Cómo quiere que lo llamen?",
            vdpNameEntry_inputNamePlaceholder: "Ingrese el nombre por el que quiera que lo llamen:",
            vdpNameEntry_inputDepartment: "¿A qué departamento pertenece?",
            vdpNameEntry_inputEmail: "¿Cuál es su dirección de correo electrónico?",
            vdpNameEntry_inputTeam: "¿A qué equipo pertenece?",
            vdpNameEntry_identifiersName: "Ingresar nombre",
            vdpNameEntry_identifiersDuplicateName:
                "Parece que alguien con el nombre {0} ya está conectado hoy. ¿Desea intentar ingresar un nombre diferente para que podamos diferenciarlo? Puede agregar la inicial de su apellido o escribir un apodo.",
            vdpNameEntry_identifiersDepartment: "Ingresar nombre del departamento",
            vdpNameEntry_identifiersEmail: "Ingresar correo electrónico",
            vdpNameEntry_identifiersInvalidEmailFormat: "Formato de correo electrónico inválido",
            vdpNameEntry_identifiersTeam: "Ingresar nombre del equipo",
            vdpNameEntry_welcomeHeader: "¡Mucho gusto, {0}!",
            vdpNameEntry_welcomeAdvance: "Conocer a su equipo",
            vdpNameEntry_claimFacilitatorTitle: "Reclamar rol de facilitador",
            vdpNameEntry_facilitatorClaimed: "Rol de facilitador reclamado",
            vdpNameEntry_claimFacilitatorButton: "Reclamar facilitador",
            vdpNameEntry_claimFacilitatorModalCancel: "Cancelar",
            vdpNameEntry_claimFacilitatorModalParagraphOne:
                "Si usted es el facilitador de esta sesión, seleccione Reclamar facilitador a continuación.",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "Como facilitador, tendrá el control de las primeras páginas para comenzar. Luego, el sistema seleccionará automáticamente un nuevo navegador.",
            vdpNameEntry_claimFacilitatorModalParagraphThree:
                "Si recibió una invitación a esta sesión como participante, seleccione Cancelar.",
            vdpNameEntry_numParticipantsInSession: "{0} participantes están en espera…",
            vdpNameEntry_oneParticipantsInSession: "1 participante está en espera…",
            vdpNameEntry_save: "Guardar",
            vdpNameEntry_continue: "Continuar",
            vdpNameEntry_thanks: "Gracias, {0}. ¡Comenzaremos pronto!",
            vdpNameEntry_intro: "Escriba su nombre y espere que los demás se unan. ¡Comenzaremos pronto!",
            vdpNameEntry_introStarted: "Escriba su nombre y únase a sus pares.",
            vdpNameEntry_navDirections: "Espere que el equipo se una.",
            vdpValidationErrorMinLength: "Use al menos {0} caracteres.",
            vdpValidationErrorMaxLength: "No ingrese más de {0} caracteres.",
            toolbar_stepOf: "Sección {0}, página {1} - {2} ({3})",
            toolbar_intro: "Paso {0} de la sección {1}",
            toolbar_welcome: "Secuencia de bienvenida",
            toolbar_exit: "Secuencia de salida",
            toolbar_reloadContent: "Volver a cargar el contenido",
            toolbar_commentCreationButton: "Comentario ({0})",
            toolbar_buildComments: "Comentarios de desarrollo",
            toolbar_commentListButton: "Ver comentarios ({0})",
            toolbar_commentCreationTitle: "Agregar comentario de desarrollo",
            toolbar_commentCreationDescription:
                "Los comentarios se guardarán y se enviarán al equipo de desarrollo. Incluya su nombre o sus iniciales con el comentario.",
            toolbar_commentCreationCancelButton: "Cancelar",
            toolbar_commentCreationSubmitButton: "Publicar comentario",
            toolbar_commentCreationSubmitButtonDisabled: "Publicando comentario…",
            toolbar_commentCreationPlaceholder: "Escribir comentario…",
            toolbar_previewMode: "Modo de vista previa:",
            toolbar_aboutPreviewMode: "Acerca del Modo de vista previa",
            toolbar_focusAreaSelector: "Selector de área de enfoque",
            toolbar_hotspotSelector: "Selector de punto de interés",
            toolbar_previewModeModalTitle: "Modo de vista previa",
            toolbar_previewModeModalFirstIntroduction:
                "Está viendo esta experiencia en Modo de vista previa. Esta vista se diseñó únicamente para la revisión interna de Root y proporciona acceso a Herramientas de creación.",
            toolbar_previewModeModalSecondIntroduction:
                "Estas opciones solo están disponibles en el Modo de vista previa y no serán visibles para el cliente ni los participantes.",
            toolbar_previewModeModalReloadContent: "Volver a cargar el contenido",
            toolbar_previewModeModalReloadContentDescription:
                "Use este botón para actualizar la experiencia y ver las actualizaciones más recientes de Contentful sin tener que volver a cargar la sesión.",
            toolbar_previewModeModalComments: "Comentarios",
            toolbar_previewModeModalCommentsDescription:
                "Agregue un comentario para dar retroalimentación o indicar cualquier cambio que deba realizarse. Los comentarios se registrarán en Contentful para el equipo de desarrollo.",
            toolbar_previewModeModalMapCoordinates: "Coordenadas del material visual",
            toolbar_previewModeModalMapCoordinatesDescription: "Copie las coordenadas del material visual.",
            toolbar_previewModeModalHotspotSelector: "Selector de punto de interés",
            toolbar_previewModeModalHotspotSelectorDescription:
                "Acceda a la vista previa de las ubicaciones de los puntos de interés y copie las coordenadas para usarlas en Contentful.",
            coordinatePreview_coordinatesSelectorTitle: "Selector de coordenadas",
            coordinatePreview_hotspotMode: "Modo de puntos de interés",
            coordinatePreview_hotspotModeCoordinatesCopiedToast:
                "Las coordenadas de los puntos de interés se copiaron en su portapapeles.",
            coordinatePreview_focusAreaMode: "Modo de área de enfoque",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast:
                "Las coordenadas del área de enfoque se copiaron en su portapapeles.",
            coordinatePreview_directionsTitle: "Instrucciones del selector de coordenadas",
            coordinatePreview_hotspotDirectionsLineOne:
                "Seleccione un punto en el material visual para crear un punto de interés.",
            coordinatePreview_hotspotDirectionsLineTwo:
                "Seleccione el botón Copiar de cada punto de interés para copiar las coordenadas en Contentful.",
            coordinatePreview_focusAreaDirectionsLineOne:
                "Seleccione y arrastre para dibujar un rectángulo en el material visual.",
            coordinatePreview_focusAreaDirectionsLineTwo:
                "Coloque el rectángulo sobre el área que será visible para un paso.",
            coordinatePreview_focusAreaDirectionsLineThree:
                "Seleccione el botón Copiar de cada área de enfoque para copiar las coordenadas en Contentful.",
            coordinatePreview_reset: "Restablecer",
            coordinatePreview_done: "Realizado",
            navigatorDefaultInstructions_selectNextToContinue: "Seleccione ***Siguiente*** para continuar.",
            navigatorDefaultInstructions_progressCheckLineOne: "Hemos alcanzado el final de la sesión {0}.",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace:
                "Vamos a buen ritmo. ¡Continuemos con el buen trabajo!",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "Nuestro progreso ha sido mejor de lo esperado. Intentemos no apresurarnos en el resto del material.",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "Estamos retrasados. Tendremos que avanzar más rápido para completar la sesión a tiempo.",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav:
                "En la página siguiente, presentaremos a un nuevo navegador para la sección {0}.",
            maintenanceWindow_heading: "El mantenimiento está programado para el {1} de {0} de {2} a {3} {4}.",
            maintenanceWindow_body: "La aplicación no estará disponible durante este tiempo.",
            maintenanceError_overlap:
                "Las fechas que eligió entran en conflicto con un período de mantenimiento programado el {0}. El mantenimiento se realiza todas las semanas de {1} a {2}. Asegúrese de que las fechas de inicio y vencimiento no se superpongan con un período de mantenimiento.",
            progressCheck_sectionTitle: "Sección {0} de {1}",
            progressCheck_paceCheckTitle: "Verificación de ritmo de la sesión",
            progressCheck_paceMessageOnPace: "¡Continúe con el buen trabajo!",
            progressCheck_paceMessageAheadOfPace: "Intente no apresurarse en el resto del material.",
            progressCheck_paceMessageBehindPace: "Tendrá que avanzar más rápido para completar la sesión a tiempo.",
            progressCheck_navigatorChangeTitle: "Cambio de navegador",
            progressCheck_navigatorChangeMessage:
                "Gracias por liderar, {0}. Se asignará un nuevo navegador en la página siguiente.",
            vdpIntro_title: "Introducción",
            vdpIntro_subtitle: "Algunas recomendaciones rápidas antes de comenzar…",
            vdpIntro_joinTheTeam: "Unirse al equipo",
            vdpIntro_facilitatorRole: "Rol del facilitador",
            vdpIntro_navigatorRole: "Rol del navegador",
            vdpIntro_participantRole: "Rol del participante",
            vdpIntro_iceBreaker: "Conocer al equipo",
            vdpIntro_exploreTheVisual: "El material visual",
            vdpIntro_skipShort: "Omitir",
            vdpIntro_skipLong: "Omitir introducción",
            vdpIntro_facilitatorInstructionsOne:
                "¡Bienvenido! La sesión de hoy es diferente de otros cursos u otras capacitaciones a los que tal vez hayan asistido.",
            vdpIntro_facilitatorInstructionsTwo:
                "No hay un maestro ni un capacitador: aprenderán unos de otros y de la información que se muestra en pantalla.",
            vdpIntro_facilitatorInstructionsThree:
                "Mi rol como facilitador es mantener la conversación encaminada y dentro de los plazos.",
            vdpIntro_navigatorInstructionsOne:
                "En las pantallas siguientes, también seré nuestro navegador. El rol del navegador es leer el texto de introducción en cada pantalla, seleccionar el botón ***Siguiente*** para avanzar en la experiencia y seguir otras instrucciones según se indiquen.",
            vdpIntro_navigatorInstructionsTwo:
                "El rol del navegador se asignará aleatoriamente y cambiará cada algunas pantallas.",
            vdpIntro_participantInstructionsOne:
                "¡Todos ustedes son participantes! Su rol es participar activamente mediante el intercambio de perspectivas con el grupo. Colaborarán para que la sesión transcurra sin problemas hablando por turnos, respetando los pensamientos y las opiniones de los demás, y manteniendo la conversación encaminada.",
            vdpIntro_participantInstructionsTwo:
                "Pueden ver quién participa en la sesión y quién tiene cada rol mediante el botón ***Equipo*** en la parte inferior izquierda.",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "A continuación, cada uno de nosotros tendrá la oportunidad de ver cómo es el rol del navegador mientras nos presentamos.",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "Cuando vea el cartel ***Usted es el navegador*** sobre el texto de introducción, será su turno de leer y seguir las instrucciones. ¿Alguien tiene alguna pregunta?",
            vdpIntro_iceBreakerInstructionsOne: "Hola, soy {0}.",
            vdpIntro_iceBreakerInstructionsDefault: "Un dato acerca de mí es que _______.",
            vdpIntro_mapInstructionsOne: "Ahora que ya nos conocemos, aprendamos más sobre nuestra sesión de hoy.",
            vdpIntro_mapInstructionsTwo:
                "Nuestra conversación se centrará en una imagen llamada material visual *Learning Map*®. Se trata de una metáfora ilustrada que representa aspectos de nuestra empresa que exploraremos juntos. ",
            vdpIntro_iceBreakerIntroDirections: "Responda todas las preguntas.",
            vdpEnd_title: "Gracias.",
            vdpEnd_body:
                "Hemos completado nuestra experiencia. ¡Gracias por participar! Esperamos que la conversación de hoy le haya resultado esclarecedora.",
            vdpEnd_surveyRequest:
                "Tómese algunos minutos para intercambiar ideas y reacciones respecto de la información sobre la que conversamos. Sus comentarios son importantes para nuestro éxito.",
            vdpEnd_surveyThankYou: "Gracias por su respuesta.",
            vdpEnd_sessionComplete: "La sesión ha finalizado.",
            vdpEnd_closeWindow: "Puede cerrar esta ventana.",
            privacyBanner_message:
                "La protección de sus datos personales es muy importante para Accenture y todo dato personal que usted proporcione a través de esta herramienta podrá ser procesado por Accenture. Accenture se compromete a mantener la seguridad de sus datos personales y procesarlos de conformidad con las leyes de privacidad pertinentes y nuestras políticas internas. Los datos personales que Accenture procesa pueden transferirse o estar disponibles internacionalmente en todas las actividades comerciales globales de Accenture y entre sus entidades, filiales y terceros. Antes de utilizar esta herramienta, Accenture lo invita a leer con atención su declaración de privacidad [Declaración de privacidad]({1}) y la [Política de cookies]({0}), que incluyen información importante sobre el motivo por el cual Accenture procesa sus datos personales y la manera en que lo hace.",
            privacyBanner_dismiss: "Aceptar",
            adminMessage_unauthorized: "No está autorizado a realizar la acción siguiente: {0}.",
            adminMessage_unauthorized_viewExp: "ver todas las experiencias",
            vdpActivity_New: "nueva actividad",
            vdpActivity_submitted: "Enviado",
            vdpActivity_editableCardWarning: "Complete todas las tarjetas vacías antes de enviar.",
            vdpActivity_matchingSuccess: "¡Bien hecho!",
            vdpActivity_matchingSuccessDescription: "Se han colocado todas las tarjetas correctamente.",
            vdpActivity_matchingFail: "No exactamente.",
            vdpActivity_matchingFailDescription:
                "Arrastre las tarjetas incorrectas nuevamente a la columna de la izquierda. Luego, intente combinarlas con un nuevo punto.",
            vdpActivity_matchingReady: "Listo.",
            vdpActivity_matchingReadyDescription: "Se han colocado todas las tarjetas.",
            vdpActivity_matchingCheckAnswers: "Verifique sus respuestas",
            vdpActivity_matchingContinue: "Continúe con la siguiente actividad.",
            vdpActivity_cardStackWithVisualAriaLabel: "Pila de tarjetas con materiales visuales",
            vdpActivity_cardStackWithVisualAriaDescription: "Coloque las tarjetas en el orden correcto",
            vdpActivity_revealCardAriaLabel: "Paso para revelar tarjeta",
            vdpActivity_revealCardAriaDescription: "Seleccione la tarjeta para revelarla",
            vdpActivity_dragAndDropAriaLabel: "Paso para arrastrar y soltar",
            vdpActivity_dragAndDropAriaDescription: "Arrastre y suelte las tarjetas en las columnas correctas",
            vdpActivity_matchingStepAriaLabel: "Paso para combinar tarjetas",
            vdpActivity_matchingStepAriaDescription: "Mueva la tarjeta al lugar correcto para combinarla",
            vdpActivity_recallStepAriaLabel: "Paso para recordar",
            vdpActivity_recallStepAriaDescription: "Este es un paso para recordar; siga las instrucciones",
            vdpActivity_multipleChoiceAriaLabel: "Paso de elección múltiple",
            vdpActivity_multipleChoiceAriaDescription: "Seleccione entre las opciones de elección múltiple.",
            vdpActivity_textEntryAriaLabel: "Actividad de ingreso de texto",
            vdpActivity_textEntryAriaDescription: "Envíe su respuesta en el campo de texto",
            vdpActivity_singleAssetStepAriaLabel: "Paso de activo único",
            vdpActivity_singleAssetStepAriaDescription: "Siga las instrucciones.",
            vdpActivity_comparisonStepAriaLabel: "Paso de comparación",
            vdpActivity_comparisonStepAriaDescription: "Siga las instrucciones.",
            vdpActivity_FirstTitlePosition: "Se encuentra en el primer elemento",
            vdpActivity_LastTitlePosition: "Se encuentra en el último elemento",
            vdpActivity_videoStepAriaLabel: "Paso de video",
            vdpActivity_videoStepAriaDescription: "En este paso, se mostrará un video a los participantes",
            vdpActivity_progressCheckAriaLabel: "Verificación de progreso",
            vdpActivity_progressCheckAriaDescription:
                "Su progreso ha sido mejor de lo esperado. Intente no apresurarse en el resto del material.",
            vdpActivity_exitStepAriaLabel: "Paso de salida",
            vdpActivity_exitStepAriaDescription:
                "Hemos completado nuestra experiencia. Ahora puede cerrar esta ventana",
            vdpHud_infoTeamButtonDescription:
                "Seleccione este elemento para ver una lista de los participantes de la sesión, turnar el rol de navegador a un participante aleatorio y cambiar su nombre de participante.",
            vdpHud_zoomControlsDescription: "Use estas acciones para acercar/alejar en el material visual.",
            vdpHud_zoomControlsZoomIn: "Acercar",
            vdpHud_zoomControlsZoomOut: "Alejar",
            sidebar_scheduleButtonDescription:
                "Vea un resumen del cálculo del tiempo que se espera que lleve cada sección y cuánto tiempo se dedicó a cada sección.",
            sidebar_previousButtonDescription:
                "Seleccione este botón para retroceder la sesión del grupo a la página anterior.",
            sidebar_nextButtonDescription:
                "Seleccione este botón para avanzar la sesión del grupo a la página siguiente.",
            sidebar_navigatorBadgeDescription:
                "El navegador es responsable de hacer avanzar al grupo a los pasos siguientes de la sesión. Use los botones “Siguiente” y “Anterior” para la navegación del grupo.",
            sidebar_skipButtonDescription:
                "Seleccione este botón para omitir la sección de bienvenida para toda la sesión del grupo.",
            genUrl_dataCollectionDefaultLabel: "Configuración por defecto de la recopilación de datos",
            genUrl_goToSessionRepoReport: "Herramienta de informe",
            genUrl_archiveButton: "Archivar",
            genUrl_resurrectButton: "Recuperar",
            genUrl_destroyButton: "Destruir",
            vdpRenderer_refreshDescription: "Debe actualizar la página para intentar volver a conectarse a la sesión.",
            vdpRenderer_setSelfActiveButtonDescription:
                "Seleccione este botón para permanecer como participante activo y conectado de la sesión actual.",
            vdpNameEntry_saveDescription: "Envíe cómo quiere que lo llamen y espere el inicio de la sesión.",
            vdpNameEntry_continueDescription: "Envíe cómo quiere que lo llamen y únase a la sesión en curso.",
            vdpNameEntry_smallScreenWarningTitle: "¡Nos alegra que esté aquí!",
            vdpNameEntry_smallScreenWarningBody:
                "… Para disfrutar de una experiencia óptima, inicie sesión desde una computadora portátil o de escritorio.",
            vdpNameEntry_smallScreenWarningDismiss: "Descartar",
            privacyBanner_title: "Privacidad de los datos",
            privacyBanner_dismissDescription:
                "Esta acción descartará el aviso que describe las políticas de privacidad y cookies del sitio.",
            sessionReport_submitButton: "Generar informe",
            sessionReport_header_title: "Herramienta de informe de la sesión {0}",
            sessionReport_header_experience: "Nombre de la experiencia: {0}",
            sessionReport_header_errorTitle: "Error al cargar",
            sessionReport_header_errorSubtitle: "No se pudo obtener la información de la experiencia.",
            sessionReport_switch_unknown: "Tipo de resultado de informe no reconocido",
            sessionReport_results_downloadResults: "Descargar resultados",
            sessionReport_results_noRecordsTitle: "No se encontraron registros",
            sessionReport_results_noRecordsSubTitle: "No hay datos que coincidan con esos criterios.",
            sessionReport_results_errorTitle: "Error al generar informe",
            sessionReport_basicInfo_startDate: "Fecha de inicio",
            sessionReport_basicInfo_endDate: "Fecha de finalización",
            sessionReport_basicInfo_useSeedData: "Usar datos iniciales",
            sessionReport_basicInfo_useSeedData_help:
                "Indica si el informe generado debería incluir los datos aleatorios de muestra que se crearon. Está opción se ofrece únicamente con fines de prueba.",
            sessionReport_switch_basicInfo: "Información básica de la sesión",
            activityReport_basicInfo_startDate: "Fecha de inicio",
            activityReport_basicInfo_endDate: "Fecha de finalización",
            activityReport_basicInfo_useSeedData: "Usar datos iniciales",
            activityReport_basicInfo_useSeedData_help:
                "Indica si el informe generado debería incluir los datos aleatorios de muestra que se crearon. Está opción se ofrece únicamente con fines de prueba.",
            activityReport_switch_basicInfo: "Información básica de la actividad",
            activityReport_basicInfoAggregated_startDate: "Fecha de inicio",
            activityReport_basicInfoAggregated_endDate: "Fecha de finalización",
            activityReport_basicInfoAggregated_useSeedData: "Usar datos iniciales",
            activityReport_basicInfoAggregated_useSeedData_help:
                "Indica si el informe generado debería incluir los datos aleatorios de muestra que se crearon. Está opción se ofrece únicamente con fines de prueba.",
            activityReport_switch_basicInfoAggregated: "Información básica adicional de la actividad",
            multipleChoiceReport_basicInfo_startDate: "Fecha de inicio",
            multipleChoiceReport_basicInfo_endDate: "Fecha de finalización",
            multipleChoiceReport_basicInfo_useSeedData: "Usar datos iniciales",
            multipleChoiceReport_basicInfo_useSeedData_help:
                "Indica si el informe generado debería incluir los datos aleatorios de muestra que se crearon. Está opción se ofrece únicamente con fines de prueba.",
            multipleChoiceReport_switch_basicInfo: "Información básica de la elección múltiple",
            multipleChoiceReport_basicInfo_activityId: "Ingresar ID de la actividad",
            multipleChoiceReport_basicInfoAggregated_startDate: "Fecha de inicio",
            multipleChoiceReport_basicInfoAggregated_endDate: "Fecha de finalización",
            multipleChoiceReport_basicInfoAggregated_useSeedData: "Usar datos iniciales",
            multipleChoiceReport_basicInfoAggregated_useSeedData_help:
                "Indica si el informe generado debería incluir los datos aleatorios de muestra que se crearon. Está opción se ofrece únicamente con fines de prueba.",
            multipleChoiceReport_switch_basicInfoAggregated: "Información básica adicional de la elección múltiple",
            multipleChoiceReport_basicInfoAggregated_activityId: "Ingresar ID de la actividad",
            dragAndDropReport_basicInfo_useSeedData: "Usar datos iniciales",
            dragAndDropReport_basicInfo_useSeedData_help:
                "Indica si el informe generado debería incluir los datos aleatorios de muestra que se crearon. Está opción se ofrece únicamente con fines de prueba.",
            dragAndDropReport_basicInfo_startDate: "Fecha de inicio",
            dragAndDropReport_basicInfo_endDate: "Fecha de finalización",
            dragAndDropReport_switch_basicInfo: "Información básica de arrastrar y soltar",
            dragAndDropReport_basicInfo_activityId: "Ingresar ID de la actividad",
            dragAndDropReport_basicInfoAggregated_useSeedData: "Usar datos iniciales",
            dragAndDropReport_basicInfoAggregated_useSeedData_help:
                "Indica si el informe generado debería incluir los datos aleatorios de muestra que se crearon. Está opción se ofrece únicamente con fines de prueba.",
            dragAndDropReport_basicInfoAggregated_startDate: "Fecha de inicio",
            dragAndDropReport_basicInfoAggregated_endDate: "Fecha de finalización",
            dragAndDropReport_switch_basicInfoAggregated: "Información básica adicional de arrastrar y soltar",
            dragAndDropReport_basicInfoAggregated_activityId: "Ingresar ID de la actividad",
            confirmDialog_confirmValuePrompt:
                "Para confirmar esta acción, ingrese el valor __*{0}*__ en el campo a continuación.",
            confirmDialog_confirmButton: "Confirmar",
            confirmDialog_closeButton: "Cierre",
            confirmDialog_error_checkBox: "Debe marcar la casilla para confirmar con éxito esta acción.",
            confirmDialog_error_confirmValue: "Debe ingresar el valor __*{0}*__ para confirmar con éxito esta acción.",
            confirmDialog_scheduler_title: "Confirmar archivado",
            confirmDialog_scheduler_body:
                "Está a punto de archivar la experiencia denominada {0}. Esto deshabilitará el acceso a la experiencia, incluidos los enlaces a las sesiones que se hayan generado para sesiones futuras. Para confirmar esta acción, marque la casilla y escriba el nombre de la experiencia.",
            confirmDialog_scheduler_resurrectTitle: "Confirmar recuperación",
            confirmDialog_scheduler_resurrectBody:
                "Está a punto de recuperar la experiencia denominada {0}. Esto habilitará el acceso a la experiencia. Todos los enlaces generados para sesiones futuras serán válidos. Tenga en cuenta que deberá actualizar la fecha de vencimiento de la experiencia tras completar esta acción. Para confirmar esta acción, marque la casilla y escriba el nombre de la experiencia.",
            confirmDialog_scheduler_destroyTitle: "Confirmar destrucción",
            confirmDialog_scheduler_destroyBody:
                "Está a punto de destruir la experiencia denominada {0}. Esta es una acción irrecuperable que no se puede deshacer. Antes de ejecutar esta acción, debe confirmar lo siguiente: \n1. Todos los activos de la experiencia se descargaron y archivaron en el área del proyecto.\n2. Se realizó un video de la navegación a lo largo de la experiencia y se lo almacenó en el área del proyecto.\n3. Se comunicó con los contactos del proyecto (si se enumera alguno del área de Contentful en nuestro sistema) a fin de verificar si la eliminación es aceptable.\n4. Las actividades de ingreso de datos y texto del depósito de la sesión y el depósito general se descargaron y archivaron en el área del proyecto.\n\nPara confirmar esta acción, marque la casilla y escriba el nombre de la experiencia.",
            confirmDialog_dataCollectionDefault_title:
                "Confirmar cambio a la configuración por defecto de la recopilación de datos",
            confirmDialog_dataCollectionDefault_body:
                "Ha elegido {0} la función de recopilación de datos de la experiencia {1}. Esto afectará todas las sesiones que se generen en el futuro para esta experiencia. ¿Está seguro de que desea {0} la recopilación de datos de esta experiencia?",
            confirmDialog_dataCollectionDefault_body_enable: "habilitar",
            confirmDialog_dataCollectionDefault_body_disable: "deshabilitar",
            confirmDialog_enable_NavigatorTitle: "¿Está seguro?",
            confirmDialog_enable_NavigatorBody:
                "Al seleccionar “Confirmar”, iniciará la actividad con el grupo con la lectura en voz alta de la información de las próximas páginas. Seleccione “Cerrar” si prefiere no ser el primer lector.",
            vdpNameEntry_NavigatorSelectionMessage:
                "Se deberá contar con un navegador para que lea en voz alta las instrucciones para comenzar.",
            vdpNameEntry_NavigatorButtonMessage: "Seleccione aquí para ser el primer lector.",
        },
    },
    "de-DE": {
        name: "German",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "Aktivität {0} kann nicht gefunden werden",
            vdpActivity_closeModal: "Schließen",
            vdpActivity_flipCard: "Umdrehen",
            vdpActivity_correctExclamation: "Richtig!",
            vdpActivity_correct: "Richtig",
            vdpActivity_correctAnswerBadge: "Korrekte Antwort",
            vdpActivity_partiallyCorrect: "Teilweise richtig!",
            vdpActivity_incorrect: "Falsch",
            vdpActivity_notCorrect: "Nicht korrekt",
            vdpActivity_continue: "Weiter",
            vdpActivity_checkAnswer: "Antwort überprüfen",
            vdpActivity_ready: "Bereit.",
            vdpActivity_cardsPlaced: "Alle Karten wurden positioniert.",
            vdpActivity_cardsCorrectHeader: "Erfolg!",
            vdpActivity_cardsCorrect: "Alle Karten wurden erfolgreich positioniert.",
            vdpActivity_cardsCorrectContinue: "Fahren Sie nun mit der nächsten Aktivität fort.",
            vdpActivity_cardsPartiallyCorrectHeader: "Nicht ganz.",
            vdpActivity_cardsPartiallyCorrect:
                "Überprüfen Sie die falschen Antworten und versuchen Sie, diese an eine andere Stelle zu verschieben.",
            vdpActivity_cardsCheckAnswers: "Überprüfen Sie Ihre Antworten",
            vdpActivity_textEntryPlaceholder: "Ihre Antwort",
            vdpActivity_textEntryNavigatorPrompt: "Der Navigator {0} gibt unten den Text ein",
            vdpActivity_textEntryPrompt: "Geben Sie unten den Text ein:",
            vdpActivity_textEntrySubmit: "Absenden",
            vdpActivity_selectOne: "Eine Option auswählen",
            vdpActivity_selectOneOrMany: "Wählen Sie eine oder mehrere aus",
            vdpActivity_defaultCurrentParticipantName: "Ich",
            vdpActivity_hotspotTooltip: "Klicken Sie auf die Hotspots, um mehr zu erfahren.",
            vdpClipboard_copied: "In die Zwischenablage kopiert!",
            vdpClipboard_copy: "In die Zwischenablage kopieren",
            vdpClipboard_error: "Kopieren in die Zwischenablage nicht möglich",
            vdpHud_hide: "Ausblenden",
            vdpHud_show: "Anzeigen",
            vdpHud_changeNavigatorTitle: "Übertragen Sie die Rolle des Navigators auf eine neue Person.",
            vdpHud_changeNavigatorText1:
                "Sie haben „Navigator ändern“ ausgewählt. Dadurch wird zufällig ein anderer Navigator ausgewählt und alle Interaktionen auf dieser Seite werden kurzzeitig deaktiviert.",
            vdpHud_changeNavigatorText2: "Sind Sie sicher, dass Sie den Navigator ändern möchten?",
            vdpHud_close: "Schließen",
            vdpHud_accept: "Den Navigator ändern",
            vdpHud_back: "Zurück",
            vdpHud_next: "Weiter",
            vdpHud_endExperience: "Lernerfahrung beenden",
            vdpHud_defaultExitMsg:
                "Wir haben unsere Lernerfahrung abgeschlossen. Danke fürs Ihre Teilnahme! Wir hoffen, dass Sie die heutige Diskussion aufschlussreich fanden. Sie können dieses Fenster schließen.",
            vdpHud_navigatorReminder: "Sie sind der Navigator. Bitte lesen Sie Folgendes:",
            vdpHud_timerNoTimeSpecified: "Für den Abschnitt wurde kein Zeitrahmen festgelegt",
            vdpHud_timerRemaining: "verbleiben in diesem Abschnitt",
            vdpHud_timerLessThanOneMinute: "Weniger als eine Minute übrig",
            vdpHud_timerOver: "mehr als",
            vdpHud_roleNone: "Keine Zuweisung",
            vdpHud_infoTableSection: "Abschnitt",
            vdpHud_infoTableRecommended: "Empfohlen",
            vdpHud_infoTableActual: "Tatsächlich",
            vdpHud_infoTableTotal: "Gesamt",
            vdpHud_infoTableRemaining: "Verbleibend",
            vdpHud_infoTeamButton: "Team",
            vdpHud_infoTeamParticipantJoining: "Benutzer tritt bei...",
            vdpHud_navigator: "Navigator",
            vdpHud_facilitator: "Moderator",
            vdpHud_facilitatorMenuMakeNavigator: "Zum Navigator machen",
            vdpHud_facilitatorMenuRemoveFacilitator: "Die Rolle des Moderators entfernen",
            vdpHud_removeFacilitatorWarningConfirm: "Bestätigen",
            vdpHud_removeFacilitatorWarningCancel: "Abbrechen",
            vdpHud_removeFacilitatorWarningMessage:
                "Sie sind dabei, Ihre Rolle als Moderator zu entfernen. Sind Sie sicher, dass Sie fortfahren möchten?",
            vdpHud_progressOnPaceMessage: "Sie liegen im Zeitplan",
            vdpHud_progressAheadOfPaceMessage: "Sie sind dem Zeitplan voraus",
            vdpHud_progressBehindPaceMessage: "Sie liegen hinter dem Zeitplan",
            vdpHud_progressTimeRemaining: "{0} verbleiben in diesem Abschnitt",
            vdpHud_progressStepsInSection: "Seite {0} von {1}",
            vdpHud_zoomControlsTooltip: "Zoom-Anzeigesteuerungen",
            sidebar_scheduleButton: "Zeitplan",
            sidebar_teamAssignNavigator: "Navigator zuweisen",
            sidebar_teamChooseRandomly: "Zufällig wählen",
            sidebar_teamChooseHelperText:
                "Wählt nach dem Zufallsprinzip einen berechtigten Teilnehmer aus, der als Navigator fungiert. Die Teilnehmer werden erst wieder ausgewählt, wenn alle an der Reihe waren.",
            sidebar_editNameMenuItem: "Namen bearbeiten",
            sidebar_editNameSave: "Änderungen speichern",
            sidebar_nameEditPlaceholder: "Geben Sie den Namen ein, mit dem Sie angesprochen werden möchten...",
            sidebar_manageRolesMenuLabel: "Rollen verwalten",
            sidebar_navigatorDescription:
                "Liest Fragen, Aufforderungen und Anweisungen laut vor. Führt die Gruppe während der Erfahrung weiter, indem „Weiter“ ausgewählt und anderen Anweisungen gefolgt wird, die auf jedem Bildschirm angegeben sind.",
            sidebar_facilitatorDescription:
                "Dient während der gesamten Sitzung als Begleiter, um sicherzustellen, dass die Gruppe eine intensive Diskussion führt und das Gespräch auf dem richtigen Weg bleibt.",
            sidebar_previousButton: "Vorherige",
            sidebar_nextButton: "Weiter",
            sidebar_lockTooltip: "Nur der Navigator kann die Seite wechseln.",
            sidebar_navigatorBadge: "Sie sind der Navigator",
            sidebar_facilitatorBadge: "Sie sind der Moderator",
            sidebar_introductionLabel: "Einleitung",
            sidebar_introductionInstruction: "Lesen Sie der Gruppe laut vor:",
            sidebar_directionsLabel: "Anweisungen",
            sidebar_nonNavigatorDirectionsLabel: "{0} führt Folgendes aus:",
            sidebar_directionsInstruction: "Führen Sie Folgendes aus:",
            sidebar_facilitationNotesLabel: "Anmerkungen für die Durchführung",
            genUrl_copyLink: "Link kopieren",
            genUrl_sessionLinkDetails:
                "Diese Sitzungslinks verschwinden, wenn Sie die Seite aktualisieren. Laden Sie diese Links unbedingt herunter oder kopieren Sie sie, bevor Sie diese Seite verlassen.",
            genUrl_exportAsCsv: "Als CSV exportieren",
            genUrl_copyTable: "Als Tabelle kopieren",
            genUrl_linkExpiration: "Linkablauf",
            genUrl_link: "Link",
            genUrl_title: "{0} Sitzungslinkgenerator",
            genUrl_experience: "Erfahrung: {0}",
            genUrl_description:
                "Über dieses Portal können Sie Sitzungslinks für diese Erfahrung generieren. Um Sitzungslinks zu generieren, geben Sie die Anzahl der Sitzungen ein, die Sie generieren möchten, wählen Sie ein Datum aus, an dem die Sitzungen ablaufen sollen, und wählen Sie Sitzungen generieren. Sobald diese Links generiert sind, kopieren Sie sie und fügen Sie sie in Ihren bevorzugten Texteditor oder Ihr Tabellenkalkulationsprogramm ein. Sobald Sie die Seite aktualisieren, verschwinden alle Links.",
            genUrl_numSessions: "Anzahl Sitzungen:",
            genUrl_sessionsExpirationLabel: "Sitzungslinks laufen ab am:",
            genUrl_sessionsOpenAtLabel: "Sitzungslinks sind gültig ab:",
            genUrl_numSessionsInputLabel: "Anzahl der Sitzungen",
            genUrl_sessionLanguageLabel: "Sitzungssprache",
            genUrl_numSessionsInputTypeError: "Muss eine Nummer sein",
            genUrl_closesOnError: "Wählen Sie ein Datum in der Zukunft",
            genUrl_experienceClosesOnError: "Wählen Sie ein Datum vor {0}",
            genUrl_notifSuccessSingle: "{0}-Link erfolgreich generiert",
            genUrl_notifSuccessMultiple: "{0}-Links erfolgreich generiert",
            genUrl_notifErrorGenerating:
                "Es können keine Sitzungen generiert werden. Bitte versuchen Sie es erneut oder wenden Sie sich an den Administrator.",
            genUrl_notifErrorProcessing: "Kann nicht verarbeitet werden. Kontaktieren Sie einen Administrator.",
            genUrl_sessionsExpireToday: "Die Sitzungen laufen heute ab",
            genUrl_sessionsExpireInOneDay: "Sitzungen laufen in {0} Tag ab",
            genUrl_sessionsExpireInDays: "Sitzungen laufen in {0} Tagen ab",
            genUrl_sessionsOpenToday: "Die Sitzungen werden heute eröffnet",
            genUrl_sessionsOpenOneDay: "Die Sitzungen werden in {0} Tag eröffnet",
            genUrl_sessionsOpenDays: "Die Sitzungen werden in {0} Tagen eröffnet",
            genUrl_generate: "Sitzungen generieren",
            genUrl_schedulingLinkExpired: "Der Link für den Zeitplan ist abgelaufen.",
            genUrl_error: "Fehler",
            genUrl_dataCollectionLabel: "Datensammlung",
            genUrl_emailContact: "E-Mail Kontakt",
            vdpLoading_waitForContent: "Bitte warten Sie, während Ihr Inhalt geladen wird…",
            vdpNotFound_title: "Virtuelle Dialogplattform",
            vdpNotFound_message: "Die gesuchte Seite konnte nicht gefunden werden.",
            vdpRenderer_refresh: "Aktualisieren",
            vdpRenderer_sessionDisconnectedHeader: "Ihre Verbindung wurde getrennt.",
            vpdRenderer_inactiveDisconnectedBody:
                "Ihre Verbindung wurde aufgrund von Inaktivität getrennt. Wählen Sie die Schaltfläche unten aus, um zu versuchen, die Verbindung wiederherzustellen.",
            vdpRenderer_inactiveHeader: "Sie sind inaktiv.",
            vdpRenderer_inactiveBody:
                "Sie waren zu lange inaktiv. Wählen Sie die Schaltfläche unten aus, um aktiv zu bleiben. Andernfalls werden Sie aus der Sitzung entfernt, wenn der Timer abgelaufen ist.",
            vdpRenderer_setSelfActiveButton: "Stellen Sie sich als aktiv ein",
            vdpRenderer_sessionDisconnected: "Wiederverbindung wird versucht",
            vdpRenderer_sessionDisconnectedReload: "Bitte neu laden",
            vdpRenderer_endOfSession: "Ende der Sitzung",
            vdpRenderer_invalidMapUrl:
                "Für das Bild wurde eine ungültige URL angegeben. Bitte wenden Sie sich an Root, um Unterstützung zu erhalten.",
            vdpRenderer_unexpectedError:
                "Es ist ein unerwarteter Systemfehler aufgetreten. Hier die technischen Details:",
            vdpRenderer_unexpectedErrorMsg: "Ein unerwarteter Fehler ist aufgetreten: unbekannter Sitzungsstatus {0}",
            vdpRenderer_defaultEndingMessage: "Sie können jetzt Ihr Fenster schließen und die Audioverbindung trennen.",
            vdpErrorModal_title: "Seite kann nicht geladen werden",
            vdpErrorModal_message:
                "Es ist etwas schief gegangen. Bitte kontaktieren Sie uns für Unterstützung unter: vmap.help@rootinc.com",
            vdpErrorModal_body: "Der Fehler kann behoben werden, indem Sie diese Seite neu laden oder unten auswählen:",
            vdpErrorModal_button: "Laden Sie diese Erfahrung neu.",
            vdpNameEntry_welcomeText: "Willkommen",
            vdpNameEntry_defaultWelcomeMessage:
                "Diese virtuelle Dialogsitzung wird vollständig interaktiv sein. Bereiten Sie sich darauf vor, Informationen zu erkunden und Ihre Gedanken und Meinungen mit Ihren Kollegen zu teilen.",
            vdpNameEntry_inputName: "Wie möchtest Sie genannt werden?",
            vdpNameEntry_inputNamePlaceholder: "Geben Sie den Namen ein, mit dem Sie angesprochen werden möchten:",
            vdpNameEntry_inputDepartment: "In welcher Abteilung sind Sie tätig?",
            vdpNameEntry_inputEmail: "Wie lautet Ihre E-Mail-Adresse?",
            vdpNameEntry_inputTeam: "In welchem Team sind Sie?",
            vdpNameEntry_identifiersName: "Bitte Namen eingeben",
            vdpNameEntry_identifiersDuplicateName:
                "Es sieht so aus, als ob heute bereits ein {0} verbunden ist. Möchten Sie versuchen, einen anderen Namen einzugeben, damit wir Sie unterscheiden können? Erwägen Sie, Ihrem Nachnamen einen Anfangsbuchstaben hinzuzufügen oder einen Spitznamen einzugeben.",
            vdpNameEntry_identifiersDepartment: "Bitte geben Sie den Abteilungsnamen ein",
            vdpNameEntry_identifiersEmail: "Bitte geben Sie Ihre E-Mail-Adresse ein",
            vdpNameEntry_identifiersInvalidEmailFormat: "Ungültiges E-Mail-Format",
            vdpNameEntry_identifiersTeam: "Bitte geben Sie den Team-Namen ein",
            vdpNameEntry_welcomeHeader: "Schön, Sie kennenzulernen, {0}!",
            vdpNameEntry_welcomeAdvance: "Lernen Sie Ihr Team kennen",
            vdpNameEntry_claimFacilitatorTitle: "Die Rolle des Moderators beanspruchen",
            vdpNameEntry_facilitatorClaimed: "Die Rolle des Moderators beansprucht",
            vdpNameEntry_claimFacilitatorButton: "Die Moderator-Rolle beanspruchen",
            vdpNameEntry_claimFacilitatorModalCancel: "Abbrechen",
            vdpNameEntry_claimFacilitatorModalParagraphOne:
                "Wenn Sie der Moderator dieser Sitzung sind, wählen Sie unten „Die Rolle des Moderators beanspruchen“ aus.",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "Als Moderator haben Sie die Kontrolle über die ersten paar Seiten, um uns den Einstieg zu erleichtern. Anschließend wählt das System automatisch einen neuen Navigator aus.",
            vdpNameEntry_claimFacilitatorModalParagraphThree:
                "Wenn Sie als Teilnehmer zu dieser Sitzung eingeladen wurden, wählen Sie bitte Abbrechen.",
            vdpNameEntry_numParticipantsInSession: "{0} Teilnehmer warten...",
            vdpNameEntry_oneParticipantsInSession: "1 Teilnehmer wartet...",
            vdpNameEntry_save: "Speichern",
            vdpNameEntry_continue: "Weiter",
            vdpNameEntry_thanks: "Danke, {0}. Bald geht es los!",
            vdpNameEntry_intro: "Geben Sie Ihren Namen ein und warten Sie, bis andere beitreten. Bald geht es los!",
            vdpNameEntry_introStarted: "Geben Sie Ihren Namen ein und schließen Sie sich den anderen Teilnehmern an.",
            vdpNameEntry_navDirections: "Warten Sie, bis das Team beitritt.",
            vdpValidationErrorMinLength: "Bitte geben Sie mindestens {0} Zeichen ein.",
            vdpValidationErrorMaxLength: "Bitte geben Sie nicht mehr als {0} Zeichen ein.",
            toolbar_stepOf: "Abschnitt {0}, Seite {1} - {2} ({3})",
            toolbar_intro: "Schritt {0} von Abschnitt {1}",
            toolbar_welcome: "Willkommenssequenz",
            toolbar_exit: "Schlusssequenz",
            toolbar_reloadContent: "Inhalt neu laden",
            toolbar_commentCreationButton: "Kommentar ({0})",
            toolbar_buildComments: "Build-Kommentare",
            toolbar_commentListButton: "Kommentare anzeigen ({0})",
            toolbar_commentCreationTitle: "Build-Kommentar hinzufügen",
            toolbar_commentCreationDescription:
                "Kommentare werden gespeichert und an das Build-Team gesendet. Bitte geben Sie in Ihrem Kommentar Ihren Namen oder Ihre Initialen an.",
            toolbar_commentCreationCancelButton: "Abbrechen",
            toolbar_commentCreationSubmitButton: "Kommentar veröffentlichen",
            toolbar_commentCreationSubmitButtonDisabled: "Kommentar wird veröffentlicht...",
            toolbar_commentCreationPlaceholder: "Kommentar eingeben...",
            toolbar_previewMode: "Vorschau-Modus:",
            toolbar_aboutPreviewMode: "Über den Vorschaumodus",
            toolbar_focusAreaSelector: "Fokusbereich-Auswahl",
            toolbar_hotspotSelector: "Hotspot-Auswahl",
            toolbar_previewModeModalTitle: "Vorschaumodus",
            toolbar_previewModeModalFirstIntroduction:
                "Sie sehen dieses Erlebnis im Vorschaumodus. Diese Ansicht ist nur für die interne Root-Überprüfung gedacht und bietet Zugriff auf Authoring Tools.",
            toolbar_previewModeModalSecondIntroduction:
                "Diese Optionen sind nur im Vorschaumodus verfügbar und für den Kunden oder die Teilnehmer nicht sichtbar.",
            toolbar_previewModeModalReloadContent: "Inhalt neu laden",
            toolbar_previewModeModalReloadContentDescription:
                "Verwenden Sie diese Schaltfläche, um die Lernerfahrung mit den neuesten Updates von Contentful zu aktualisieren, ohne die Sitzung neu laden zu müssen.",
            toolbar_previewModeModalComments: "Kommentare",
            toolbar_previewModeModalCommentsDescription:
                "Fügen Sie einen Kommentar hinzu, um Feedback zu geben oder etwaige Änderungen zu notieren, die vorgenommen werden müssen. Kommentare werden in Contentful für das Build-Team erfasst.",
            toolbar_previewModeModalMapCoordinates: "Koordinaten des Bildmaterials",
            toolbar_previewModeModalMapCoordinatesDescription: "Kopieren Sie die Koordinaten des Bildmaterials.",
            toolbar_previewModeModalHotspotSelector: "Hotspot-Auswahl",
            toolbar_previewModeModalHotspotSelectorDescription:
                "Sehen Sie sich Hotspot-Standorte in der Vorschau an und kopieren Sie Koordinaten zur Verwendung in Contentful.",
            coordinatePreview_coordinatesSelectorTitle: "Koordinaten-Auswahl",
            coordinatePreview_hotspotMode: "Hotspot-Modus",
            coordinatePreview_hotspotModeCoordinatesCopiedToast:
                "Die Hotspot-Koordinaten wurden in Ihre Zwischenablage kopiert.",
            coordinatePreview_focusAreaMode: "Fokusbereich-Modus",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast:
                "Die Koordinaten des Fokusbereichs wurden in Ihre Zwischenablage kopiert.",
            coordinatePreview_directionsTitle: "Anweisungen zur Koordinatenauswahl",
            coordinatePreview_hotspotDirectionsLineOne:
                "Wählen Sie einen Punkt im Bild aus, um einen Hotspot zu erstellen.",
            coordinatePreview_hotspotDirectionsLineTwo:
                "Wählen Sie an jedem Hotspot die Schaltfläche „Kopieren“, um die Koordinaten nach Contentful zu kopieren.",
            coordinatePreview_focusAreaDirectionsLineOne:
                "Verwenden Sie die Auwahl- und Ziehfunktion, um ein Rechteck auf dem Bild zu zeichnen.",
            coordinatePreview_focusAreaDirectionsLineTwo:
                "Platzieren Sie das Rechteck über dem Bereich, der für eine Stufe sichtbar sein soll.",
            coordinatePreview_focusAreaDirectionsLineThree:
                "Wählen Sie in jedem Fokusbereich die Schaltfläche „Kopieren“, um die Koordinaten nach Contentful zu kopieren.",
            coordinatePreview_reset: "Zurücksetzen",
            coordinatePreview_done: "Fertig",
            navigatorDefaultInstructions_selectNextToContinue: "Wählen Sie **_Weiter_**, um fortzufahren.",
            navigatorDefaultInstructions_progressCheckLineOne: "Wir haben das Ende von Abschnitt {0} erreicht.",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace: "Wir liegen im Zeitplan. Weiter so!",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "Wir sind dem Zeitplan voraus. Versuchen wir, nicht durch das verbleibende Material zu hasten.",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "Sie liegen hinter dem Zeitplan. Wir müssen schneller vorgehen, um die Sitzung pünktlich abzuschließen.",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav:
                "Auf der nächsten Seite stellen wir einen neuen Navigator für Abschnitt {0} vor.",
            maintenanceWindow_heading: "Die Wartung ist für {0}, {1} von {2} - {3} {4} geplant.",
            maintenanceWindow_body: "Die Anwendung ist während dieser Zeit möglicherweise nicht verfügbar.",
            maintenanceError_overlap:
                "Die von Ihnen ausgewählten Daten stehen im Widerspruch zu einem geplanten Wartungszeitraum am {0}. Die Wartung erfolgt jede Woche von {1} bis {2}. Bitte stellen Sie sicher, dass sich das von Ihnen gewählte Start- und Ablaufdatum nicht mit dem Wartungsfenster überschneidet.",
            progressCheck_sectionTitle: "Abschnitt {0} von {1}",
            progressCheck_paceCheckTitle: "Überprüfung des Sitzungs-Fortschritts",
            progressCheck_paceMessageOnPace: "Weiter so!",
            progressCheck_paceMessageAheadOfPace: "Versuchen Sie, nicht durch das verbleibende Material zu hasten.",
            progressCheck_paceMessageBehindPace:
                "Sie müssen schneller vorgehen, um die Sitzung pünktlich abzuschließen.",
            progressCheck_navigatorChangeTitle: "Wechsel des Navigators",
            progressCheck_navigatorChangeMessage:
                "Danke für deine Unterstützung, {0}. Auf der nächsten Seite wird ein neuer Navigator zugewiesen.",
            vdpIntro_title: "Einleitung",
            vdpIntro_subtitle: "Vor dem Start noch schnell ein paar Dinge...",
            vdpIntro_joinTheTeam: "Werden Sie Teil unseres Teams",
            vdpIntro_facilitatorRole: "Moderator-Rolle",
            vdpIntro_navigatorRole: "Navigator-Rolle",
            vdpIntro_participantRole: "Teilnehmer-Rolle",
            vdpIntro_iceBreaker: "Lernen Sie das Team kennen",
            vdpIntro_exploreTheVisual: "Das Bildmaterial",
            vdpIntro_skipShort: "Überspringen",
            vdpIntro_skipLong: "Einleitung überspringen",
            vdpIntro_facilitatorInstructionsOne:
                "Herzlich willkommen! Die heutige Sitzung unterscheidet sich von anderen Kursen oder Schulungen, die Sie möglicherweise besucht haben.",
            vdpIntro_facilitatorInstructionsTwo:
                "Es gibt keinen Lehrer oder Trainer – Sie lernen voneinander und durch die Informationen auf dem Bildschirm.",
            vdpIntro_facilitatorInstructionsThree:
                "Meine Rolle als Moderator besteht darin, das Gespräch in die richtige Richtung zu lenken und die Zeitlinie einzuhalten.",
            vdpIntro_navigatorInstructionsOne:
                "Auf den nächsten Bildschirmen werde ich auch als unser Navigator fungieren. Die Rolle des Navigators besteht darin, den Einleitungstext auf jedem Bildschirm zu lesen, die Schaltfläche **_Weiter_** auszuwählen, um uns durch die Erfahrung zu führen, und bei Aufforderung anderen Anweisungen zu folgen.",
            vdpIntro_navigatorInstructionsTwo:
                "Die Rolle des Navigators wird zufällig zugewiesen und ändert sich alle paar Bildschirme.",
            vdpIntro_participantInstructionsOne:
                "Ihr seid alle Teilnehmer! Ihre Rolle besteht darin, sich aktiv zu beteiligen, indem Sie Ihre Perspektiven mit der Gruppe teilen. Sie tragen dazu bei, dass die Sitzung reibungslos verläuft, indem Sie abwechselnd reden, die Gedanken und Meinungen anderer respektieren und nicht vom Gesprächsthema abzukommen.",
            vdpIntro_participantInstructionsTwo:
                "Über die Schaltfläche **_Team_** unten links können Sie sehen, wer an der Sitzung teilnimmt und wer welche Rolle innehat.",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "Als nächstes bekommt jeder von uns die Möglichkeit, die Rolle des Navigators kennen zu lernen, während wir uns vorstellen.",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "Wenn Sie das Banner **_Sie sind der Navigator_** über dem Einleitungstext sehen, sind Sie an der Reihe, die Anweisungen zu lesen und zu befolgen. Hat jemand Fragen?",
            vdpIntro_iceBreakerInstructionsOne: "Hallo, ich bin {0}.",
            vdpIntro_iceBreakerInstructionsDefault: "Eine Tatsache über mich ist **___**.",
            vdpIntro_mapInstructionsOne:
                "Nachdem wir uns nun alle kennengelernt haben, erfahren wir mehr über unsere heutige Sitzung.",
            vdpIntro_mapInstructionsTwo:
                "Unser Gespräch wird sich auf ein Bild konzentrieren, das als *Learning Map*® bezeichnet wird. Es handelt sich um eine illustrierte Metapher, die Aspekte unseres Geschäfts darstellt, die wir gemeinsam erkunden werden. ",
            vdpIntro_iceBreakerIntroDirections: "Beantworten Sie alle Fragen.",
            vdpEnd_title: "Danke.",
            vdpEnd_body:
                "Wir haben unsere Lernerfahrung abgeschlossen. Danke fürs Ihre Teilnahme! Wir hoffen, dass Sie die heutige Diskussion aufschlussreich fanden.",
            vdpEnd_surveyRequest:
                "Bitte nehmen Sie sich ein paar Minuten Zeit, um Ihre Gedanken und Reaktionen auf die von uns besprochenen Informationen mitzuteilen. Ihr Feedback ist wichtig für unseren Erfolg.",
            vdpEnd_surveyThankYou: "Vielen Dank für Ihre Antwort.",
            vdpEnd_sessionComplete: "Die Sitzung ist abgeschlossen.",
            vdpEnd_closeWindow: "Sie können dieses Fenster schließen.",
            privacyBanner_message:
                "Wir verwenden Cookies, um einige Website-Funktionen zu ermöglichen und Ihnen eine bessere Lernerfahrung zu bieten. Weitere Informationen finden Sie in unserer [Cookie-Richtlinie]({0}) und unserer [Datenschutzerklärung]({1}).",
            privacyBanner_dismiss: "Schließen",
            adminMessage_unauthorized: "Es ist Ihnen nicht gestattet, folgende Maßnahmen zu ergreifen: {0}.",
            adminMessage_unauthorized_viewExp: "alle Lernerfahrungen anzeigen",
        },
    },
    fr: {
        name: "French",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "Impossible de trouver l’activité {0}",
            vdpActivity_closeModal: "Fermer",
            vdpActivity_correctExclamation: "Correct !",
            vdpActivity_correct: "Correct",
            vdpActivity_correctAnswerBadge: "Bonne réponse",
            vdpActivity_partiallyCorrect: "Partiellement correct !",
            vdpActivity_incorrect: "Incorrect",
            vdpActivity_notCorrect: "Pas correct",
            vdpActivity_continue: "Continuer",
            vdpActivity_checkAnswer: "Vérifier la réponse",
            vdpActivity_ready: "Prêt.",
            vdpActivity_cardsPlaced: "Toutes les cartes ont été placées.",
            vdpActivity_cardsCorrectHeader: "Réussis !",
            vdpActivity_cardsCorrect: "Toutes les cartes ont été placées avec succès.",
            vdpActivity_cardsCorrectContinue: "Passer à l’activité suivante.",
            vdpActivity_cardsPartiallyCorrectHeader: "Pas tout à fait.",
            vdpActivity_cardsPartiallyCorrect:
                "Passer en revue les mauvaises réponses et essayer de les déplacer vers un nouvel emplacement.",
            vdpActivity_cardsCheckAnswers: "Vérifiez vos réponses",
            vdpActivity_textEntryPlaceholder: "Votre réponse",
            vdpActivity_textEntryNavigatorPrompt: "L’assistant, {0}, saisira le texte ci-dessous",
            vdpActivity_textEntryPrompt: "Saisissez le texte ci-dessous :",
            vdpActivity_textEntrySubmit: "Envoyer",
            vdpActivity_selectOne: "Sélectionnez-en une",
            vdpActivity_selectOneOrMany: "Sélectionnez-en une ou plusieurs",
            vdpActivity_defaultCurrentParticipantName: "Moi",
            vdpActivity_hotspotTooltip: "Cliquez sur les zones actives pour en savoir plus.",
            vdpClipboard_copied: "Copié dans le presse-papiers !",
            vdpClipboard_copy: "Copier dans le presse-papiers",
            vdpClipboard_error: "Impossible de copier dans le presse-papiers",
            vdpHud_hide: "Masquer",
            vdpHud_show: "Afficher",
            vdpHud_changeNavigatorTitle: "Nommer une nouvelle personne comme l’assistant.",
            vdpHud_changeNavigatorText1:
                'Vous avez cliqué sur "Changer d’assistant". Cela sélectionnera au hasard une autre personne faisant office d’assistant et désactivera temporairement toutes les interactions sur cette page.',
            vdpHud_changeNavigatorText2: "Voulez-vous vraiment changer d’assistant ?",
            vdpHud_close: "Fermer",
            vdpHud_accept: "Changer d’assistant ",
            vdpHud_back: "Retour",
            vdpHud_next: "Suivant",
            vdpHud_endExperience: "Terminer l’expérience",
            vdpHud_defaultExitMsg:
                "Vous avez terminé notre expérience. Merci d’avoir participé ! Nous espérons que vous avez trouvé la discussion d’aujourd’hui intéressante. Vous pouvez fermer cette fenêtre.",
            vdpHud_navigatorReminder: "Vous êtes l’assistant(e). Veuillez lire ce qui suit :",
            vdpHud_timerNoTimeSpecified: "Aucune heure spécifiée pour la section",
            vdpHud_timerRemaining: "restant dans la section",
            vdpHud_timerLessThanOneMinute: "Moins d’une minute restante",
            vdpHud_timerOver: "plus de",
            vdpHud_roleNone: "Aucun attribué",
            vdpHud_infoTableSection: "Section",
            vdpHud_infoTableRecommended: "Recommandé",
            vdpHud_infoTableActual: "Réel",
            vdpHud_infoTableTotal: "Total",
            vdpHud_infoTableRemaining: "Restant",
            vdpHud_infoTeamButton: "Équipe",
            vdpHud_infoTeamParticipantJoining: "Utilisateur rejoignant...",
            vdpHud_navigator: "Assistant(e)",
            vdpHud_facilitator: "Animateur",
            vdpHud_facilitatorMenuMakeNavigator: "Nommer dans le rôle d’assistant",
            vdpHud_facilitatorMenuRemoveFacilitator: "Supprimer le rôle de l’animateur",
            vdpHud_removeFacilitatorWarningConfirm: "Confirmer",
            vdpHud_removeFacilitatorWarningCancel: "Annuler",
            vdpHud_removeFacilitatorWarningMessage:
                "Vous êtes sur le point de quitter votre rôle d’animateur. Voulez-vous vraiment continuer ?",
            vdpHud_progressOnPaceMessage: "À l’heure",
            vdpHud_progressAheadOfPaceMessage: "En avance",
            vdpHud_progressBehindPaceMessage: "En retard",
            vdpHud_progressTimeRemaining: "{0} restant dans la section",
            vdpHud_progressStepsInSection: "Page {0} sur {1}",
            vdpHud_zoomControlsTooltip: "Commandes de zoom de carte",
            sidebar_scheduleButton: "Calendrier",
            sidebar_teamAssignNavigator: "Attribuer le rôle d’assistant",
            sidebar_teamChooseRandomly: "Choisir de façon aléatoire",
            sidebar_teamChooseHelperText:
                "Choisit de façon aléatoire un participant admissible pour servir de navigateur. Les participants ne seront pas choisis à nouveau tant que tout le monde n’aura pas eu son tour.",
            sidebar_editNameMenuItem: "Modifier le nom",
            sidebar_editNameSave: "Enregistrer les modifications",
            sidebar_nameEditPlaceholder: "Saisissez le nom par lequel vous souhaitez être appelé...",
            sidebar_manageRolesMenuLabel: "Gérer les rôles",
            sidebar_navigatorDescription:
                "Lit les questions, les invites et les instructions à voix haute. Fait progresser le groupe tout au long de l’expérience en sélectionnant Suivant et en suivant les autres instructions indiquées sur chaque écran.",
            sidebar_facilitatorDescription:
                "Sert de guide tout au long de la session pour s’assurer que le groupe a une discussion riche et que la conversation reste sur la bonne voie.",
            sidebar_previousButton: "Précédent",
            sidebar_nextButton: "Suivant",
            sidebar_lockTooltip: "Seul l’assistant(e) peut modifier la page.",
            sidebar_navigatorBadge: "Vous avez le rôle d’assistant(e)",
            sidebar_facilitatorBadge: "Vous avez le rôle d’animateur(trice)",
            sidebar_introductionLabel: "Introduction",
            sidebar_introductionInstruction: "Lisez à voix haute au groupe :",
            sidebar_directionsLabel: "Indications",
            sidebar_nonNavigatorDirectionsLabel: "{0} procède comme suit :",
            sidebar_directionsInstruction: "Procédez comme suit :",
            sidebar_facilitationNotesLabel: "Notes de l’animateur(trice)",
            genUrl_copyLink: "Copier le lien",
            genUrl_sessionLinkDetails:
                "Ces liens de sessions disparaîtront lorsque vous actualiserez la page. Assurez-vous de télécharger ou de copier ces liens avant de quitter cette page.",
            genUrl_exportAsCsv: "Exporter au format CSV",
            genUrl_copyTable: "Copier le tableau",
            genUrl_linkExpiration: "Expiration du lien",
            genUrl_link: "Lien",
            genUrl_title: "{0} Générateur de lien de session",
            genUrl_experience: "Expérience : {0}",
            genUrl_description:
                "Ce portail vous permet de générer des liens de session pour cette expérience. Pour générer des liens de session, saisissez le nombre de sessions que vous souhaitez générer, choisissez une date à laquelle les sessions doivent expirer et cliquez sur Générer. Une fois ces sessions générées, copiez-les et collez-les dans l’éditeur de texte ou le programme de feuille de calcul de votre choix. Une fois la page actualisée, toutes les sessions disparaîtront.",
            genUrl_numSessions: "Nombre de sessions :",
            genUrl_sessionsExpirationLabel: "Les liens de session expirent le :",
            genUrl_sessionsOpenAtLabel: "Les liens de session sont valides à partir du :",
            genUrl_numSessionsInputLabel: "Nombre de sessions",
            genUrl_sessionLanguageLabel: "Langue de la session",
            genUrl_numSessionsInputTypeError: "Doit être un nombre",
            genUrl_closesOnError: "Choisir une date dans le futur",
            genUrl_experienceClosesOnError: "Choisir une date antérieure à {0}",
            genUrl_notifSuccessSingle: "{0} lien généré avec succès",
            genUrl_notifSuccessMultiple: "{0} liens générés avec succès",
            genUrl_notifErrorGenerating:
                "Impossible de générer des sessions. Veuillez réessayer ou contacter l’administrateur.",
            genUrl_notifErrorProcessing: "Impossible à traiter. Contactez un administrateur.",
            genUrl_sessionsExpireToday: "Les sessions expireront aujourd’hui",
            genUrl_sessionsExpireInOneDay: "Les sessions expireront dans {0} jour",
            genUrl_sessionsExpireInDays: "Les sessions expireront dans {0} jours",
            genUrl_sessionsOpenToday: "Les sessions s’ouvriront aujourd’hui",
            genUrl_sessionsOpenOneDay: "Les sessions s’ouvriront dans {0} jour",
            genUrl_sessionsOpenDays: "Les sessions s’ouvriront dans {0} jours",
            genUrl_generate: "Générer des sessions",
            genUrl_schedulingLinkExpired: "Le lien de planification a expiré.",
            genUrl_error: "Erreur",
            genUrl_dataCollectionLabel: "Collecte de données",
            genUrl_emailContact: "Contact e-mail",
            vdpLoading_waitForContent: "Veuillez patienter pendant le chargement de votre contenu...",
            vdpNotFound_title: "Plateforme de dialogue virtuel",
            vdpNotFound_message: "La page que vous recherchez est introuvable.",
            vdpRenderer_refresh: "Actualiser",
            vdpRenderer_sessionDisconnectedHeader: "Vous avez été déconnecté.",
            vpdRenderer_inactiveDisconnectedBody:
                "Vous avez été déconnecté en raison de votre inactivité. Cliquez sur le bouton ci-dessous pour essayer de vous reconnecter.",
            vdpRenderer_inactiveHeader: "Vous êtes inactif.",
            vdpRenderer_inactiveBody:
                "Vous êtes inactif depuis trop longtemps. Cliquez sur le bouton ci-dessous pour vous marquer comme actif, sinon vous serez retiré de la session lorsque le minuteur sera épuisé.",
            vdpRenderer_setSelfActiveButton: "Définissez votre statut sur actif",
            vdpRenderer_sessionDisconnected: "Tentative de reconnexion",
            vdpRenderer_sessionDisconnectedReload: "Veuillez recharger",
            vdpRenderer_endOfSession: "Fin de la session",
            vdpRenderer_invalidMapUrl:
                "Une URL de carte non valide a été fournie. Veuillez contacter Root pour obtenir de l’aide.",
            vdpRenderer_unexpectedError: "Une erreur système inattendue s’est produite. Voici les détails techniques :",
            vdpRenderer_unexpectedErrorMsg: "Une erreur inattendue s’est produite dans un état de session inconnu {0}",
            vdpRenderer_defaultEndingMessage:
                "Vous pouvez maintenant fermer votre fenêtre et vous déconnecter de l’audio.",
            vdpErrorModal_title: "Impossible de charger la page",
            vdpErrorModal_message:
                "Une erreur s’est produite ! Veuillez nous contacter pour obtenir de l’aide à l’adresse : vmap.help@rootinc.com",
            vdpErrorModal_body: "L’erreur peut être résolue en rechargeant cette page ou en cliquant ci-dessous :",
            vdpErrorModal_button: "Recharger cette expérience.",
            vdpNameEntry_welcomeText: "Bienvenue",
            vdpNameEntry_defaultWelcomeMessage:
                "Cette session de dialogue virtuel sera entièrement interactive. Préparez-vous à explorer les informations et à partager vos réflexions et opinions avec vos pairs.",
            vdpNameEntry_inputName: "Comment voulez-vous être appelé ?",
            vdpNameEntry_inputNamePlaceholder: "Saisissez le nom par lequel vous souhaitez être appelé...",
            vdpNameEntry_inputDepartment: "Dans quel service travaillez-vous ?",
            vdpNameEntry_inputEmail: "Quelle est votre adresse e-mail ?",
            vdpNameEntry_inputTeam: "De quelle équipe faites-vous partie ?",
            vdpNameEntry_identifiersName: "Veuillez saisir un nom",
            vdpNameEntry_identifiersDuplicateName:
                "Il semble qu’il y ait déjà un(e) {0} connecté(e) aujourd’hui. Souhaitez-vous essayer de saisir un autre nom afin que nous puissions vous distinguer ? Envisagez d’ajouter une initiale pour le nom de famille ou de saisir un surnom.",
            vdpNameEntry_identifiersDepartment: "Veuillez saisir le nom du service",
            vdpNameEntry_identifiersEmail: "Veuillez saisir l’adresse e-mail",
            vdpNameEntry_identifiersInvalidEmailFormat: "Format d’e-mail non valide",
            vdpNameEntry_identifiersTeam: "Veuillez saisir un nom d’équipe",
            vdpNameEntry_welcomeHeader: "C’est un plaisir de vous rencontrer, {0} !",
            vdpNameEntry_welcomeAdvance: "Rencontrez votre équipe",
            vdpNameEntry_claimFacilitatorTitle: "Revendiquer le rôle d’animateur",
            vdpNameEntry_facilitatorClaimed: "Rôle d’animateur revendiqué",
            vdpNameEntry_claimFacilitatorButton: "Se proposer comme animateur",
            vdpNameEntry_claimFacilitatorModalCancel: "Annuler",
            vdpNameEntry_claimFacilitatorModalParagraphOne:
                "Si vous êtes l’animateur de cette session, sélectionnez Revendiquer le rôle d’animateur ci-dessous.",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "En tant qu’animateur, vous aurez le contrôle des premières pages pour nous aider à démarrer. Ensuite, le système sélectionnera automatiquement un nouveau navigateur.",
            vdpNameEntry_claimFacilitatorModalParagraphThree:
                "Si vous avez été invité à cette session en tant que participant, veuillez sélectionner Annuler.",
            vdpNameEntry_numParticipantsInSession: "{0} participants attendent...",
            vdpNameEntry_oneParticipantsInSession: "1 participant attend...",
            vdpNameEntry_save: "Enregistrer",
            vdpNameEntry_continue: "Continuer",
            vdpNameEntry_thanks: "Merci, {0}. Nous allons bientôt commencer !",
            vdpNameEntry_intro:
                "Saisissez votre nom et attendez les autres participants. Nous allons bientôt commencer !",
            vdpNameEntry_introStarted: "Saisissez votre nom et rejoignez ceux qui sont déjà connectés.",
            vdpNameEntry_navDirections: "Attendez que l’équipe se connecte.",
            vdpValidationErrorMinLength: "Veuillez saisir au moins {0} caractères.",
            vdpValidationErrorMaxLength: "Veuillez ne pas saisir plus de {0} caractères.",
            toolbar_stepOf: "Section {0}, Page {1} -{2} ({3})",
            toolbar_intro: "Étape {0} de la section {1} :",
            toolbar_welcome: "Séquence de bienvenue",
            toolbar_exit: "Quitter la séquence",
            toolbar_reloadContent: "Charger de nouveau le contenu",
            toolbar_commentCreationButton: "Commentaire ({0})",
            toolbar_buildComments: "Créer des commentaires",
            toolbar_commentListButton: "Afficher les commentaires ({0})",
            toolbar_commentCreationTitle: "Ajouter un commentaire de création",
            toolbar_commentCreationDescription:
                "Les commentaires seront enregistrés et envoyés à Experience Builder.  Veuillez saisir votre nom avec le commentaire.",
            toolbar_commentCreationCancelButton: "Annuler",
            toolbar_commentCreationSubmitButton: "Publier un commentaire",
            toolbar_commentCreationSubmitButtonDisabled: "Publication du commentaire...",
            toolbar_commentCreationPlaceholder: "Saisir un commentaire...",
            toolbar_previewMode: "Mode Aperçu :",
            toolbar_aboutPreviewMode: "À propos du mode Aperçu",
            toolbar_focusAreaSelector: "Sélecteur de domaine d’intervention",
            toolbar_hotspotSelector: "Sélecteur de point d’accès",
            toolbar_previewModeModalTitle: "Mode Aperçu",
            toolbar_previewModeModalFirstIntroduction:
                "Vous visualisez cette expérience en mode Aperçu. Cette vue est conçue pour une révision interne Root uniquement et donne accès aux outils de création.",
            toolbar_previewModeModalSecondIntroduction:
                "Ces options sont uniquement disponibles en mode Aperçu et ne seront visibles ni le client, ni par les participants.",
            toolbar_previewModeModalReloadContent: "Charger de nouveau le contenu",
            toolbar_previewModeModalReloadContentDescription:
                "Utilisez ce bouton pour actualiser l’expérience en incorporant les dernières mises à jour de Contentful sans avoir à recharger la session.",
            toolbar_previewModeModalComments: "Commentaires",
            toolbar_previewModeModalCommentsDescription:
                "Ajoutez un commentaire à titre d’appréciation ou notez les changements à apporter. Les commentaires seront saisis dans Contentful pour l’équipe d’élaboration.",
            toolbar_previewModeModalMapCoordinates: "Coordonnées de carte",
            toolbar_previewModeModalMapCoordinatesDescription: "Copiez les coordonnées de la carte.",
            toolbar_previewModeModalHotspotSelector: "Sélecteur de point d’accès",
            toolbar_previewModeModalHotspotSelectorDescription:
                "Visualisez les coordonnées des points d’accès, que vous copierez pour les utiliser dans Contentful.",
            coordinatePreview_coordinatesSelectorTitle: "Sélecteur de coordonnées",
            coordinatePreview_hotspotMode: "Mode point d’accès",
            coordinatePreview_hotspotModeCoordinatesCopiedToast:
                "Les coordonnées de point d’accès ont été copiées dans votre presse-papiers.",
            coordinatePreview_focusAreaMode: "Mode Domaine d’intervention",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast:
                "Les coordonnées du domaine d’intervention ont été copiées dans votre presse-papiers.",
            coordinatePreview_directionsTitle: "Indications pour la sélection des coordonnées",
            coordinatePreview_hotspotDirectionsLineOne: "Cliquez sur la carte pour créer des points d’accès.",
            coordinatePreview_hotspotDirectionsLineTwo:
                "Cliquez sur le bouton Copier pour chaque point d’accès afin de copier les coordonnées dans Contentful.",
            coordinatePreview_focusAreaDirectionsLineOne:
                "Cliquez et déplacez le curseur pour tracer un rectangle sur la carte.",
            coordinatePreview_focusAreaDirectionsLineTwo: "Placez le rectangle sur la zone à afficher pour une étape.",
            coordinatePreview_focusAreaDirectionsLineThree:
                "Cliquez sur le bouton Copier pour chaque point d’accès afin de copier les coordonnées dans Contentful.",
            coordinatePreview_reset: "Réinitialiser",
            coordinatePreview_done: "Terminé",
            navigatorDefaultInstructions_selectNextToContinue: "Cliquez sur ***Suivant*** pour continuer.",
            navigatorDefaultInstructions_progressCheckLineOne: "Nous sommes arrivés à la fin de la section {0}.",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace:
                "Vous progressez à bonne allure. Du bon travail jusqu’à présent !",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "Vous êtes en avance sur le programme. Inutile de se précipiter sur le reste du module.",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "Vous êtes en retard sur le programme. Mieux vaut accélerer un peu pour terminer la session à temps.",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav:
                "Sur la page suivante, nous présenterons un nouveau navigateur pour la section {0}.",
            maintenanceWindow_heading: "La maintenance est programmée le {0}, {1} de {2} à {3} {4}.",
            maintenanceWindow_body: "Il se peut que l’application ne soit pas disponible pendant ce laps de temps.",
            maintenanceError_overlap:
                "Les dates que vous avez choisies sont en conflit avec une période de maintenance planifiée le {0}. La maintenance a lieu chaque semaine de {1} à {2}. Veuillez vous assurer que les dates de début et d’expiration ne chevauchent pas une fenêtre de maintenance.",
            progressCheck_sectionTitle: "Section {0} sur {1}",
            progressCheck_paceCheckTitle: "Vérification du rythme de la session",
            progressCheck_paceMessageOnPace: "Du bon travail jusqu’à présent !",
            progressCheck_paceMessageAheadOfPace: "Inutile de se précipiter sur le reste du module.",
            progressCheck_paceMessageBehindPace: "Mieux vaut accélerer un peu pour terminer la session à temps.",
            progressCheck_navigatorChangeTitle: "Changement de navigateur",
            progressCheck_navigatorChangeMessage:
                "Merci d’avoir animé cette session, {0}. Un nouveau navigateur sera affecté à la page suivante.",
            vdpIntro_title: "Introduction",
            vdpIntro_subtitle: "Quelques remarques avant de commencer...",
            vdpIntro_joinTheTeam: "Rejoignez l’équipe",
            vdpIntro_facilitatorRole: "Rôle de l’animateur",
            vdpIntro_navigatorRole: "Rôle du navigateur",
            vdpIntro_participantRole: "Rôle du participant",
            vdpIntro_iceBreaker: "Rencontrer l’équipe",
            vdpIntro_exploreTheVisual: "Le visuel",
            vdpIntro_skipShort: "Passer",
            vdpIntro_skipLong: "Passer l’introduction",
            vdpIntro_facilitatorInstructionsOne:
                "Bienvenue ! La session d’aujourd’hui est différente des autres cours ou formations auxquels vous avez pu assister.",
            vdpIntro_facilitatorInstructionsTwo:
                "Il n’y a pas ni enseignant, ni formateur – vous apprendrez les uns des autres et d’après le contenu que vous verrez à l’écran.",
            vdpIntro_facilitatorInstructionsThree:
                "Mon rôle en tant qu’animateur(trice) est de maintenir l’orientation de la conversation et de tenir le rythme du cours.",
            vdpIntro_navigatorInstructionsOne:
                "Pour les prochains écrans, je servirai également de navigateur(trice). Le rôle des navigateurs est de lire le texte de l’introduction sur chaque écran, de sélectionner le bouton ***Suivant*** pour continuer à avancer dans l’expérience et de suivre d’autres instructions à l’invite.",
            vdpIntro_navigatorInstructionsTwo:
                "Le rôle de navigateur sera attribué de manière aléatoire et changera tous les écrans.",
            vdpIntro_participantInstructionsOne:
                "Vous êtes tous des participants ! Votre rôle est de participer activement en partageant vos points de vue avec le groupe. Vous aiderez la session à se dérouler sans heurts en parlant à tour de rôle, en respectant les pensées et les opinions des autres et en maintenant la conversation sur la bonne voie.",
            vdpIntro_participantInstructionsTwo:
                "Vous pouvez voir qui est dans la session et qui a chaque rôle à l’aide du bouton ***Équipe*** en bas à gauche.",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "Ensuite, nous aurons chacun l’occasion de voir à quoi ressemble le rôle de navigateur lorsque nous nous présenterons.",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "Lorsque vous voyez la bannière ***Vous êtes le navigateur*** au-dessus du texte d’introduction, c’est à votre tour de lire et de suivre les instructions. Quelqu’un a-t-il des questions ?",
            vdpIntro_iceBreakerInstructionsOne: "Bonjour, je suis {0}.",
            vdpIntro_iceBreakerInstructionsDefault: "L’un de mes traits caractéristiques est _______.",
            vdpIntro_mapInstructionsOne:
                "Maintenant que nous nous sommes tous rencontrés, apprenons-en plus sur notre session d’aujourd’hui.",
            vdpIntro_mapInstructionsTwo:
                "Notre conversation se concentrera sur une image appelée *Carte d’apprentissage*®. Il s’agit d’une métaphore illustrée représentant des aspects de notre activité que nous explorerons ensemble. ",
            vdpIntro_iceBreakerIntroDirections: "Répondez à toutes les questions.",
            vdpEnd_title: "Merci.",
            vdpEnd_body:
                "Vous avez terminé notre expérience. Merci d’avoir participé ! Nous espérons que vous avez trouvé la discussion d’aujourd’hui intéressante.",
            vdpEnd_surveyRequest:
                "Veuillez prendre quelques minutes pour nous faire part de vos réflexions et réactions concernant les informations dont nous avons discuté. Votre feedback est essentiel à notre succès.",
            vdpEnd_surveyThankYou: "Merci pour votre réponse.",
            vdpEnd_sessionComplete: "La session est terminée.",
            vdpEnd_closeWindow: "Vous pouvez fermer cette fenêtre.",
            privacyBanner_message:
                "Nous utilisons des cookies pour activer certaines fonctionnalités du site Web afin de vous offrir une expérience plus pertinente. Pour plus d’informations, veuillez consulter notre [Politique relative aux cookies]({0}) et notre [Déclaration de confidentialité]({1}).",
            privacyBanner_dismiss: "Rejeter",
        },
    },
    ja: {
        name: "Japanese",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "アクティビティが見つかりません {0}",
            vdpActivity_closeModal: "閉じる",
            vdpActivity_correctExclamation: "正解です！",
            vdpActivity_correct: "正解です",
            vdpActivity_correctAnswerBadge: "正解",
            vdpActivity_partiallyCorrect: "一部正解です！",
            vdpActivity_incorrect: "不正解",
            vdpActivity_notCorrect: "不正解です",
            vdpActivity_continue: "続行",
            vdpActivity_checkAnswer: "回答を確認する",
            vdpActivity_ready: "準備完了です。",
            vdpActivity_cardsPlaced: "すべてのカードが配置されました。",
            vdpActivity_cardsCorrectHeader: "成功です！",
            vdpActivity_cardsCorrect: "すべてのカードが正常に配置されました。",
            vdpActivity_cardsCorrectContinue: "次のアクティビティに進みます。",
            vdpActivity_cardsPartiallyCorrectHeader: "そうではありません。",
            vdpActivity_cardsPartiallyCorrect: "不正解の回答を確認して、新しい場所に移動してみてください。",
            vdpActivity_cardsCheckAnswers: "回答を確認する",
            vdpActivity_textEntryPlaceholder: "あなたの回答",
            vdpActivity_textEntryNavigatorPrompt: "ナビゲーター {0} が以下にテキストを入力します",
            vdpActivity_textEntryPrompt: "以下にテキストを入力：",
            vdpActivity_textEntrySubmit: "送信",
            vdpActivity_selectOne: "1つ選択する",
            vdpActivity_selectOneOrMany: "1つ以上を選択する",
            vdpActivity_defaultCurrentParticipantName: "私",
            vdpActivity_hotspotTooltip: "ホットスポットを選択して、もっと学んでください。",
            vdpClipboard_copied: "クリップボードにコピーしました！",
            vdpClipboard_copy: "クリップボードにコピーします",
            vdpClipboard_error: "クリップボードにコピーできません",
            vdpHud_hide: "非表示にする",
            vdpHud_show: "表示する",
            vdpHud_changeNavigatorTitle: "ナビゲーターを、新しい人に変更します。",
            vdpHud_changeNavigatorText1:
                "「ナビゲーターを変更する」を選択しました。これにより、別のナビゲーターがランダムに選択され、このページのすべての操作が一時的に無効になります。",
            vdpHud_changeNavigatorText2: "ナビゲーターを変更してもよろしいですか？",
            vdpHud_close: "閉じる",
            vdpHud_accept: "ナビゲーターを変更する",
            vdpHud_back: "戻る",
            vdpHud_next: "次へ",
            vdpHud_endExperience: "エクスペリエンスを終了する",
            vdpHud_defaultExitMsg:
                "エクスペリエンスを完了しました。ご参加ありがとうございました！本日のディスカッションが、あなたにとって有意義なものとなったことを願っています。このウィンドウを閉じることができます。",
            vdpHud_navigatorReminder: "あなたがナビゲーターです。次を読んでください。",
            vdpHud_timerNoTimeSpecified: "このセクションには、時間が指定されていません",
            vdpHud_timerRemaining: "セクションの残り",
            vdpHud_timerLessThanOneMinute: "残りは1分未満です",
            vdpHud_timerOver: "over",
            vdpHud_roleNone: "割り当てなし",
            vdpHud_infoTableSection: "セクション",
            vdpHud_infoTableRecommended: "推奨",
            vdpHud_infoTableActual: "実際",
            vdpHud_infoTableTotal: "合計",
            vdpHud_infoTableRemaining: "残り",
            vdpHud_infoTeamButton: "チーム",
            vdpHud_infoTeamParticipantJoining: "ユーザーが参加しています......",
            vdpHud_navigator: "ナビゲーター",
            vdpHud_facilitator: "ファシリテーター",
            vdpHud_facilitatorMenuMakeNavigator: "ナビゲーターを作成する",
            vdpHud_facilitatorMenuRemoveFacilitator: "ファシリテーターの役割を削除する",
            vdpHud_removeFacilitatorWarningConfirm: "確認",
            vdpHud_removeFacilitatorWarningCancel: "取消",
            vdpHud_removeFacilitatorWarningMessage:
                "あなたはファシリテーターとしての役割から離れようとしています。続行してもよろしいですか？",
            vdpHud_progressOnPaceMessage: "予定通り",
            vdpHud_progressAheadOfPaceMessage: "予定より進んでいる",
            vdpHud_progressBehindPaceMessage: "予定より遅れている",
            vdpHud_progressTimeRemaining: "セクションの残り{0}",
            vdpHud_progressStepsInSection: "{0}/ {1}ページ",
            vdpHud_zoomControlsTooltip: "ビジュアルズームコントロール",
            sidebar_scheduleButton: "スケジュール",
            sidebar_teamAssignNavigator: "ナビゲーターを割り当てる",
            sidebar_teamChooseRandomly: "ランダムに選択する",
            sidebar_teamChooseHelperText:
                "資格のある参加者を、ナビゲーターとしてランダムに選択します。全員が割り当てられるまで、同じ参加者が再度選択されることはありません。",
            sidebar_editNameMenuItem: "名前の編集",
            sidebar_editNameSave: "変更を保存する",
            sidebar_nameEditPlaceholder: "希望する表示名を入力......",
            sidebar_manageRolesMenuLabel: "役割の管理",
            sidebar_navigatorDescription:
                "質問、プロンプト、指示を、声に出して読みます。[次へ] を選択し、各画面に表示されているその他の指示に従って、グループのエクスペリエンスを進めます。",
            sidebar_facilitatorDescription:
                "セッション全体を通してガイドとして務め、グループが充実したディスカッションを行い、会話が軌道に乗っていることを確認します。",
            sidebar_previousButton: "前へ",
            sidebar_nextButton: "次へ",
            sidebar_lockTooltip: "ページを変更できるのは、ナビゲーターのみです。",
            sidebar_navigatorBadge: "あなたがナビゲーターです",
            sidebar_facilitatorBadge: "あなたはファシリテーターです",
            sidebar_introductionLabel: "はじめに",
            sidebar_introductionInstruction: "グループに声を出して読みあげます。",
            sidebar_directionsLabel: "指示",
            sidebar_nonNavigatorDirectionsLabel: "{0} は、次のことをします。",
            sidebar_directionsInstruction: "次のことを行う：",
            sidebar_facilitationNotesLabel: "ファシリテーションノート",
            genUrl_copyLink: "リンクをコピーする",
            genUrl_sessionLinkDetails:
                "ページを更新すると、これらのセッションリンクが消えます。このページを離れる際には、必ず事前にこれらのリンクをダウンロードまたはコピーしておいてください。",
            genUrl_exportAsCsv: "CSV形式でエクスポートする",
            genUrl_copyTable: "表をコピーする",
            genUrl_linkExpiration: "リンクの有効期限",
            genUrl_link: "リンク",
            genUrl_title: "{0} セッションリンク生成",
            genUrl_experience: "エクスペリエンス：{0}",
            genUrl_description:
                "このポータルでは、このエクスペリエンスのセッションリンクを生成できます。セッションリンクを生成するには、生成するセッションの番号を入力し、セッションの有効期限が切れる日付を選択して、「セッションの生成」を選択します。リンクが生成されたらコピーして、お気に入りのテキストエディターまたはスプレッドシートプログラムに貼り付けます。ページを更新すると、すべてのリンクが消えます。",
            genUrl_numSessions: "セッション数：",
            genUrl_sessionsExpirationLabel: "セッションリンク有効期限：",
            genUrl_sessionsOpenAtLabel: "セッションリンクの発効日：",
            genUrl_numSessionsInputLabel: "セッション数",
            genUrl_sessionLanguageLabel: "セッション言語",
            genUrl_numSessionsInputTypeError: "数字でなければなりません",
            genUrl_closesOnError: "将来の日付を選択してください",
            genUrl_experienceClosesOnError: "{0} より前の日付を選択する",
            genUrl_notifSuccessSingle: "{0} リンクが無事生成されました",
            genUrl_notifSuccessMultiple: "{0} リンクが無事生成されました",
            genUrl_notifErrorGenerating: "セッションを生成できません。もう一度試すか、管理者に問い合わせてください。",
            genUrl_notifErrorProcessing: "処理できません。管理者に問い合わせてください。",
            genUrl_sessionsExpireToday: "セッションは今日有効期限が切れます",
            genUrl_sessionsExpireInOneDay: "セッションは {0} 日で有効期限が切れます",
            genUrl_sessionsExpireInDays: "セッションは {0} 日で有効期限が切れます",
            genUrl_sessionsOpenToday: "セッションは今日開始します",
            genUrl_sessionsOpenOneDay: "セッションは {0} 日後に開始します",
            genUrl_sessionsOpenDays: "セッションは {0} 日後に開始します",
            genUrl_generate: "セッションを生成します",
            genUrl_schedulingLinkExpired: "スケジュールリンクの有効期限が切れました。",
            genUrl_error: "エラー",
            genUrl_dataCollectionLabel: "データ収集",
            genUrl_emailContact: "メール問合せ先",
            vdpLoading_waitForContent: "コンテンツが読み込まれるまでお待ちください……",
            vdpNotFound_title: "バーチャルダイアログプラットフォーム",
            vdpNotFound_message: "お探しのページは見つかりませんでした。",
            vdpRenderer_refresh: "更新",
            vdpRenderer_sessionDisconnectedHeader: "接続が切断されました。",
            vpdRenderer_inactiveDisconnectedBody:
                "アイドル時間が長くなったため、切断されました。再接続を試行するには、下のボタンを選択します。",
            vdpRenderer_inactiveHeader: "アイドル中です。",
            vdpRenderer_inactiveBody:
                "アイドル時間が長すぎました。有効な状態を維持するには下のボタンを選択し、それ以外の場合はタイマーのタイムアウトでセッションから除去されてしまいます。",
            vdpRenderer_setSelfActiveButton: "有効状態に設定します",
            vdpRenderer_sessionDisconnected: "再接続を試行しています",
            vdpRenderer_sessionDisconnectedReload: "再読み込みしてください",
            vdpRenderer_endOfSession: "セッション終了",
            vdpRenderer_invalidMapUrl:
                "無効な URL がビジュアルに指定されました。Root に問い合わせて、サポートを依頼してください。",
            vdpRenderer_unexpectedError: "予期しないシステムエラーが発生しました。技術的な詳細は次のとおりです。",
            vdpRenderer_unexpectedErrorMsg: "予期しないエラーが発生しました：不明なセッション状態 {0}",
            vdpRenderer_defaultEndingMessage: "ウィンドウを閉じて、オーディオを切断できます。",
            vdpErrorModal_title: "ページを読み込めません",
            vdpErrorModal_message:
                "問題が発生しました！サポートが必要な場合は、vmap.help@rootinc.com までお問い合わせください",
            vdpErrorModal_body:
                "このページを再読み込みするか、以下を選択することでエラーが解決される可能性があります。",
            vdpErrorModal_button: "このエクスペリエンスを再読み込みする。",
            vdpNameEntry_welcomeText: "ようこそ",
            vdpNameEntry_defaultWelcomeMessage:
                "このバーチャルダイアログセッションは、完全にインタラクティブになります。情報を調べて、自分の考えや意見を同僚と共有する準備をしましょう。",
            vdpNameEntry_inputName: "希望する表示名は何ですか？",
            vdpNameEntry_inputNamePlaceholder: "希望する表示名を入力：",
            vdpNameEntry_inputDepartment: "あなたはどの部署に所属していますか？",
            vdpNameEntry_inputEmail: "あなたのメールアドレスは何ですか？",
            vdpNameEntry_inputTeam: "あなたは何のチームに所属していますか？",
            vdpNameEntry_identifiersName: "名前を入力してください",
            vdpNameEntry_identifiersDuplicateName:
                "今日はすでに {0} が接続されているようです。区別できるように、別の名前を入力してみますか？苗字の頭文字を追加するか、ニックネームを入力することを検討してみてください。",
            vdpNameEntry_identifiersDepartment: "部署名を入力してください",
            vdpNameEntry_identifiersEmail: "メールアドレスを入力してください",
            vdpNameEntry_identifiersInvalidEmailFormat: "メールアドレスが無効です",
            vdpNameEntry_identifiersTeam: "チーム名を入力してください",
            vdpNameEntry_welcomeHeader: "はじめまして、{0} さん！",
            vdpNameEntry_welcomeAdvance: "チームを紹介する",
            vdpNameEntry_claimFacilitatorTitle: "ファシリテーターの役割を申請",
            vdpNameEntry_facilitatorClaimed: "ファシリテーターの役割が割り当てられました",
            vdpNameEntry_claimFacilitatorButton: "ファシリテーターを担当する",
            vdpNameEntry_claimFacilitatorModalCancel: "取消",
            vdpNameEntry_claimFacilitatorModalParagraphOne:
                "このセッションのファシリテーターである場合は、以下の[ファシリテーター申請]を選択します。",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "ファシリテーターとして、最初の数ページを制御して、作業を開始します。その後、システムが自動的に新しいナビゲーターを選択します。",
            vdpNameEntry_claimFacilitatorModalParagraphThree:
                "このセッションに参加者として招待されている場合は、[キャンセル] を選択してください。",
            vdpNameEntry_numParticipantsInSession: "{0} 人の参加者が待機しています......",
            vdpNameEntry_oneParticipantsInSession: "1人の参加者が待機しています......",
            vdpNameEntry_save: "保存",
            vdpNameEntry_continue: "続行",
            vdpNameEntry_thanks: "{0} さん、ありがとうございます。もうすぐ開始します！",
            vdpNameEntry_intro: "名前を入力して、他の人が参加するのを待ちます。もうすぐ開始します！",
            vdpNameEntry_introStarted: "名前を入力して、同僚に加わりましょう。",
            vdpNameEntry_navDirections: "チームが参加するまで待ちます。",
            vdpValidationErrorMinLength: "{0} 文字以上で入力してください。",
            vdpValidationErrorMaxLength: "{0} 文字以内で入力してください。",
            toolbar_stepOf: "セクション {0}、{1}～{2} ({3}) ページ",
            toolbar_intro: "セクション {1} のステップ {0}",
            toolbar_welcome: "ウェルカムシーケンス",
            toolbar_exit: "終了シーケンス",
            toolbar_reloadContent: "コンテンツを再読み込みする",
            toolbar_commentCreationButton: "コメント（{0}）",
            toolbar_buildComments: "コメントの作成",
            toolbar_commentListButton: "コメントを表示（{0}）",
            toolbar_commentCreationTitle: "コメントの作成を追加",
            toolbar_commentCreationDescription:
                "コメントは保存され、作成チームに送信されます。コメントには名前またはイニシャルを添えてください。",
            toolbar_commentCreationCancelButton: "取消",
            toolbar_commentCreationSubmitButton: "コメントを投稿する",
            toolbar_commentCreationSubmitButtonDisabled: "コメントを投稿しています......",
            toolbar_commentCreationPlaceholder: "コメントを入力する......",
            toolbar_previewMode: "プレビューモード：",
            toolbar_aboutPreviewMode: "プレビューモードについて",
            toolbar_focusAreaSelector: "重点分野セレクター",
            toolbar_hotspotSelector: "ホットスポットセレクター",
            toolbar_previewModeModalTitle: "プレビューモード",
            toolbar_previewModeModalFirstIntroduction:
                "あなたはこのエクスペリエンスをプレビューモードで閲覧しています。このビューは、Root 社内ビュー専用に設計されており、オーサリングツールへのアクセスを提供します。",
            toolbar_previewModeModalSecondIntroduction:
                "これらのオプションはプレビューモードでのみ提供されているもので、クライアントや参加者には表示されません。",
            toolbar_previewModeModalReloadContent: "コンテンツを再読み込みする",
            toolbar_previewModeModalReloadContentDescription:
                "セッションを再読み込みせずに、Contentful からの最新の更新でエクスペリエンスを更新するには、このボタンを使用します。",
            toolbar_previewModeModalComments: "コメント",
            toolbar_previewModeModalCommentsDescription:
                "コメントを追加してフィードバックを提供したり、必要な変更をメモしたりします。コメントはビルドチーム用に、Contentful に記録されます。",
            toolbar_previewModeModalMapCoordinates: "ビジュアル座標",
            toolbar_previewModeModalMapCoordinatesDescription: "ビジュアルの座標をコピーします。",
            toolbar_previewModeModalHotspotSelector: "ホットスポットセレクター",
            toolbar_previewModeModalHotspotSelectorDescription:
                "ホットスポットの場所をプレビューし、Contentful で使用するために座標をコピーします。",
            coordinatePreview_coordinatesSelectorTitle: "座標セレクター",
            coordinatePreview_hotspotMode: "ホットスポットモード",
            coordinatePreview_hotspotModeCoordinatesCopiedToast:
                "ホットスポットの座標が、クリップボードにコピーされました。",
            coordinatePreview_focusAreaMode: "フォーカスエリアモード",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast:
                "フォーカスエリアの座標が、クリップボードにコピーされました。",
            coordinatePreview_directionsTitle: "座標セレクター指示",
            coordinatePreview_hotspotDirectionsLineOne: "ビジュアル上で、ホットスポットを作成する位置を選択します。",
            coordinatePreview_hotspotDirectionsLineTwo:
                "各ホットスポットの[コピー] ボタンを選択して、座標を Contentful にコピーします。",
            coordinatePreview_focusAreaDirectionsLineOne: "選択してドラッグし、ビジュアル上に四角形を描きます。",
            coordinatePreview_focusAreaDirectionsLineTwo: "ステップで表示される領域上に、長方形を配置します。",
            coordinatePreview_focusAreaDirectionsLineThree:
                "各フォーカスの [コピー] ボタンを選択して、座標を Contentful にコピーします。",
            coordinatePreview_reset: "リセット",
            coordinatePreview_done: "終了",
            navigatorDefaultInstructions_selectNextToContinue: "続行するには、[***次へ***] を選択します。",
            navigatorDefaultInstructions_progressCheckLineOne: "セクション {0} の最後に到達しました。",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace: "予定通り進んでいます。これからも頑張りましょう！",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "予定より進んでいます。残りの資料は、焦って読まないようにしましょう。",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "予定より遅れています。セッションを時間通りに完了するためには、もっと速く進める必要があります。",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav:
                "次のページでは、セクション{0}の新しいナビゲーターを紹介します。",
            maintenanceWindow_heading: "メンテナンスは {0}、{1}の{2}～{3} {4}に予定されています。",
            maintenanceWindow_body: "その間、アプリケーションが利用できない可能性があります。",
            maintenanceError_overlap:
                "選択されている日付は、{0} に予定されているメンテナンス期間と重なります。メンテナンスは毎週、{1}～{2} に行われます。開始日や有効期限がメンテナンス期間と重ならないようにしてください。",
            progressCheck_sectionTitle: "{1} のセクション {0}",
            progressCheck_paceCheckTitle: "セッションペースチェック",
            progressCheck_paceMessageOnPace: "これからも頑張ってください！",
            progressCheck_paceMessageAheadOfPace: "残りの資料は、焦って読まないようにしてください。",
            progressCheck_paceMessageBehindPace:
                "セッションを時間通りに完了するためには、もっと速く進める必要があります。",
            progressCheck_navigatorChangeTitle: "ナビゲーターの変更",
            progressCheck_navigatorChangeMessage:
                "{0} さん、先導ありがとうございました。新しいナビゲーターは、次のページで割り当てられます。",
            vdpIntro_title: "はじめに",
            vdpIntro_subtitle: "始める前に、いくつか注意点があります......",
            vdpIntro_joinTheTeam: "チームに参加する",
            vdpIntro_facilitatorRole: "ファシリテーターの役割",
            vdpIntro_navigatorRole: "ナビゲーターの役割",
            vdpIntro_participantRole: "参加者の役割",
            vdpIntro_iceBreaker: "チームの紹介",
            vdpIntro_exploreTheVisual: "ビジュアル",
            vdpIntro_skipShort: "スキップする",
            vdpIntro_skipLong: "「はじめに」をスキップする",
            vdpIntro_facilitatorInstructionsOne:
                "ようこそ！これまで他のコースやトレーニングを受講されているかもしれませんが、今日のセッションはそれらとは異なります。",
            vdpIntro_facilitatorInstructionsTwo:
                "講師やトレーナーはおらず、参加者はお互いから、そして画面上の情報から学びます。",
            vdpIntro_facilitatorInstructionsThree:
                "ファシリテーターとしての私の役割は、会話を予定通りに進めることです。",
            vdpIntro_navigatorInstructionsOne:
                "次のいくつかの画面では、私がナビゲーターとしても登場します。ナビゲーターの役割は、各画面の導入テキストを読み、[次へ] ボタンを選択してエクスペリエンスを進め、プロンプトに従ってその他の指示に従うことです。",
            vdpIntro_navigatorInstructionsTwo: "ナビゲーターの役割はランダムに割り当てられ、数画面ごとに交換されます。",
            vdpIntro_participantInstructionsOne:
                "皆さんは、全員が参加者です！あなたの役割は、自分の視点をグループと共有することで、積極的に参加することです。それぞれ順番に話して、他の人の考えや意見を尊重するようにし、会話を軌道に乗せることで、セッションをスムーズに進めることができます。",
            vdpIntro_participantInstructionsTwo:
                "左下にある [***チーム***] ボタンを使用すると、誰がセッションに参加しているのか、そして誰がそれぞれの役割を担っているのかを確認できます。",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "次に、それぞれ自己紹介をしながら、ナビゲーターの役割について学びます。",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "紹介テキストの上に「***あなたはナビゲーターです***」バナーが表示されたら、指示を読み、それに従う番です。何か質問のある人はいますか？",
            vdpIntro_iceBreakerInstructionsOne: "こんにちは、私は {0} です。",
            vdpIntro_iceBreakerInstructionsDefault: "私に関する事実のひとつは、_______です。",
            vdpIntro_mapInstructionsOne: "全員が揃いましたので、今日のセッションについて詳しく学びましょう。",
            vdpIntro_mapInstructionsTwo:
                "私たちの会話は、*Learning Map*® ビジュアルと呼ばれる画像を中心に進行します。これは、私たちが一緒に探求するビジネスの側面を表すイラスト化されたメタファー（隠喩）です。",
            vdpIntro_iceBreakerIntroDirections: "質問があれば、お答えします。",
            vdpEnd_title: "ありがとうございます。",
            vdpEnd_body:
                "エクスペリエンスを完了しました。ご参加ありがとうございました！本日のディスカッションが、あなたにとって有意義なものとなったことを願っています。",
            vdpEnd_surveyRequest:
                "私たちが話し合った情報に対するあなたの考えや反応を共有するために、少しお時間をください。皆様のフィードバックは、当社の成功に重要なものです。",
            vdpEnd_surveyThankYou: "ご回答ありがとうございます。",
            vdpEnd_sessionComplete: "セッションが完了しました。",
            vdpEnd_closeWindow: "このウィンドウを閉じることができます。",
            privacyBanner_message:
                "皆さんの個人データを保護することは、Accenture にとって非常に重要であり、このツールを通じて提供される個人データは Accenture によって処理される可能性があります。Accenture では、適用されるプライバシー法および社内ポリシーに従って、皆さんの個人データを安全に保ち、処理することに尽力しています。Accenture が処理する個人データは、Accenture のグローバル事業全体、およびその事業体と関連会社、および第三者の間で国際的に転送されたり、アクセス可能になったりする場合があります。このツールを使用する前に、Accenture のプライバシーポリシー[プライバシーステートメント]（{1}）（Privacy Statement）と[クッキーポリシー]（{0}）（Cookies Policy）をよく読んでください。これらのポリシーには、Accentureが皆さんの個人データを処理する理由と方法に関する重要な情報が含まれています。",
            privacyBanner_dismiss: "同意する",
            vdpActivity_New: "新しいアクティビティ",
            vdpActivity_submit: "送信",
            vdpActivity_submitted: "送信されました",
            vdpActivity_editableCardWarning: "送信する前に、すべての空カードに記入してください。",
            vdpActivity_matchingSuccess: "成功です！",
            vdpActivity_matchingSuccessDescription: "すべてのカードが正確に配置されました。",
            vdpActivity_matchingFail: "そうではありません。",
            vdpActivity_matchingFailDescription:
                "不正解のカードを、左の列に戻します。その後、新しいスポットで一致させます。",
            vdpActivity_matchingReady: "準備完了です。",
            vdpActivity_matchingReadyDescription: "すべてのカードが配置されました。",
            vdpActivity_matchingCheckAnswers: "回答を確認する",
            vdpActivity_matchingContinue: "次のアクティビティに進みます。",
            vdpActivity_cardStackWithVisualAriaLabel: "ビジュアル付きカードスタック",
            vdpActivity_cardStackWithVisualAriaDescription: "カードを正しい順序で並べます",
            vdpActivity_revealCardAriaLabel: "カード開示ステップ",
            vdpActivity_revealCardAriaDescription: "カードを選択して、開示します",
            vdpActivity_dragAndDropAriaLabel: "ドラッグ＆ドロップステップ",
            vdpActivity_dragAndDropAriaDescription: "カードを正しい列にドラッグ＆ドロップします",
            vdpActivity_matchingStepAriaLabel: "カード一致ステップ",
            vdpActivity_matchingStepAriaDescription: "カードを正しい場所に移動して、一致させます",
            vdpActivity_recallStepAriaLabel: "回収ステップ",
            vdpActivity_recallStepAriaDescription: "この回収ステップでは、指示に従ってください",
            vdpActivity_multipleChoiceAriaLabel: "複数選択ステップ",
            vdpActivity_multipleChoiceAriaDescription: "複数の選択オプションから選択します。",
            vdpActivity_textEntryAriaLabel: "テキスト入力アクティビティ",
            vdpActivity_textEntryAriaDescription: "テキストフィールドに回答を入力します",
            vdpActivity_singleAssetStepAriaLabel: "シングルアセットステップ",
            vdpActivity_singleAssetStepAriaDescription: "指示に従ってください。",
            vdpActivity_comparisonStepAriaLabel: "比較ステップ",
            vdpActivity_comparisonStepAriaDescription: "指示に従ってください。",
            vdpActivity_FirstTitlePosition: "あなたは、最初の項目にいます",
            vdpActivity_LastTitlePosition: "あなたは、最後の項目にいます",
            vdpActivity_videoStepAriaLabel: "ビデオステップ",
            vdpActivity_videoStepAriaDescription: "このステップでは、参加者にビデオが表示されます",
            vdpActivity_progressCheckAriaLabel: "進捗状況",
            vdpActivity_progressCheckAriaDescription:
                "あなたは、予定より進んでいます。残りの資料は焦って読まないようにしてください",
            vdpActivity_exitStepAriaLabel: "終了ステップ",
            vdpActivity_exitStepAriaDescription:
                "エクスペリエンスを完了しました。これで、このウィンドウを閉じることができます",
            vdpHud_infoTeamButtonDescription:
                "この項目を選択すると、セッション参加者のリストを表示したり、ナビゲーターの役割をランダムに参加者に切り替えたり、あなたの参加者名を変更したりできます。",
            vdpHud_zoomControlsDescription:
                "これらの操作を使用して、ビジュアルをズームイン（拡大）およびズームアウト（縮小）します。",
            vdpHud_zoomControlsZoomIn: "ズームイン",
            vdpHud_zoomControlsZoomOut: "ズームアウト",
            sidebar_scheduleButtonDescription:
                "各セクションにかかると予想された時間と、各セクションに実際にかかった時間の概要を表示します。",
            sidebar_previousButtonDescription:
                "グループのセッションを前のページに移動するには、このボタンを選択します。",
            sidebar_nextButtonDescription: "グループのセッションを次のページに移動するには、このボタンを選択します。",
            sidebar_navigatorBadgeDescription:
                "ナビゲーターには、グループをセッションの次のステップに進める責任があります。グループをナビゲートするには、[次へ] ボタンと [前へ] ボタンを使用します。",
            sidebar_skipButtonDescription:
                "グループ全体のセッションのウェルカムセクションをスキップするには、このボタンを選択します。",
            genUrl_dataCollectionDefaultLabel: "データ収集デフォルト",
            genUrl_goToSessionRepoReport: "報告ツール",
            genUrl_archiveButton: "アーカイブ",
            genUrl_resurrectButton: "復元",
            genUrl_destroyButton: "廃棄",
            vdpRenderer_refreshDescription: "セッションに再接続するには、ページを更新する必要があります。",
            vdpRenderer_setSelfActiveButtonDescription:
                "このボタンを選択すると、現在のセッションが有効な状態のままになります。",
            vdpNameEntry_saveDescription: "希望する表示名を提出し、セッションが開始するまで待ちます。",
            vdpNameEntry_continueDescription: "希望する表示名を提出し、進行中のセッションに参加します。",
            vdpNameEntry_smallScreenWarningTitle: "ご参加ありがとうございます！",
            vdpNameEntry_smallScreenWarningBody:
                "......最適なエクスペリエンスを得るには、ノートパソコンまたはデスクトップからログインしてください。",
            vdpNameEntry_smallScreenWarningDismiss: "無視する",
            privacyBanner_title: "データプライバシー",
            privacyBanner_dismissDescription:
                "この操作により、サイトのプライバシーとクッキーポリシーの詳細を記載した通知を無視します。",
            adminMessage_unauthorized: "あなたには、次の操作を実行する権限がありません。{0}。",
            adminMessage_unauthorized_viewExp: "すべてのエクスペリエンスを表示する",
            sessionReport_submitButton: "レポートを生成する",
            sessionReport_header_title: "{0} セッション報告ツール",
            sessionReport_header_experience: "エクスペリエンス名：{0}",
            sessionReport_header_errorTitle: "読み込みエラー",
            sessionReport_header_errorSubtitle: "エクスペリエンス情報を取得できませんでした。",
            sessionReport_switch_unknown: "認識できないレポート結果タイプ",
            sessionReport_results_downloadResults: "結果をダウンロードする",
            sessionReport_results_noRecordsTitle: "レコードが見つかりません",
            sessionReport_results_noRecordsSubTitle: "その条件に適合するデータはありません。",
            sessionReport_results_errorTitle: "レポート生成エラー",
            sessionReport_basicInfo_startDate: "開始日",
            sessionReport_basicInfo_endDate: "終了日",
            sessionReport_basicInfo_useSeedData: "シードデータを使用する",
            sessionReport_basicInfo_useSeedData_help:
                "生成されたレポートに、ランダムに生成されたサンプルデータを含めるかどうかを示します。このオプションは、テスト目的のみに使用されます。",
            sessionReport_switch_basicInfo: "基本的なセッション情報",
            activityReport_basicInfo_startDate: "開始日",
            activityReport_basicInfo_endDate: "終了日",
            activityReport_basicInfo_useSeedData: "シードデータを使用する",
            activityReport_basicInfo_useSeedData_help:
                "生成されたレポートに、ランダムに生成されたサンプルデータを含めるかどうかを示します。このオプションは、テスト目的のみに使用されます。",
            activityReport_switch_basicInfo: "基本的なアクティビティ情報",
            activityReport_basicInfoAggregated_startDate: "開始日",
            activityReport_basicInfoAggregated_endDate: "終了日",
            activityReport_basicInfoAggregated_useSeedData: "シードデータを使用する",
            activityReport_basicInfoAggregated_useSeedData_help:
                "生成されたレポートに、ランダムに生成されたサンプルデータを含めるかどうかを示します。このオプションは、テスト目的のみに使用されます。",
            activityReport_switch_basicInfoAggregated: "基本的なアクティビティ情報の集約",
            multipleChoiceReport_basicInfo_startDate: "開始日",
            multipleChoiceReport_basicInfo_endDate: "終了日",
            multipleChoiceReport_basicInfo_useSeedData: "シードデータを使用する",
            multipleChoiceReport_basicInfo_useSeedData_help:
                "生成されたレポートに、ランダムに生成されたサンプルデータを含めるかどうかを示します。このオプションは、テスト目的のみに使用されます。",
            multipleChoiceReport_switch_basicInfo: "基本的な複数選択情報",
            multipleChoiceReport_basicInfo_activityId: "アクティビティIDを入力します",
            multipleChoiceReport_basicInfoAggregated_startDate: "開始日",
            multipleChoiceReport_basicInfoAggregated_endDate: "終了日",
            multipleChoiceReport_basicInfoAggregated_useSeedData: "シードデータを使用する",
            multipleChoiceReport_basicInfoAggregated_useSeedData_help:
                "生成されたレポートに、ランダムに生成されたサンプルデータを含めるかどうかを示します。このオプションは、テスト目的のみに使用されます。",
            multipleChoiceReport_switch_basicInfoAggregated: "基本的な複数選択情報の集約",
            multipleChoiceReport_basicInfoAggregated_activityId: "アクティビティIDを入力します",
            dragAndDropReport_basicInfo_useSeedData: "シードデータを使用する",
            dragAndDropReport_basicInfo_useSeedData_help:
                "生成されたレポートに、ランダムに生成されたサンプルデータを含めるかどうかを示します。このオプションは、テスト目的のみに使用されます。",
            dragAndDropReport_basicInfo_startDate: "開始日",
            dragAndDropReport_basicInfo_endDate: "終了日",
            dragAndDropReport_switch_basicInfo: "基本的なドラッグ＆ドロップ情報",
            dragAndDropReport_basicInfo_activityId: "アクティビティIDを入力します",
            dragAndDropReport_basicInfoAggregated_useSeedData: "シードデータを使用する",
            dragAndDropReport_basicInfoAggregated_useSeedData_help:
                "生成されたレポートに、ランダムに生成されたサンプルデータを含めるかどうかを示します。このオプションは、テスト目的のみに使用されます。",
            dragAndDropReport_basicInfoAggregated_startDate: "開始日",
            dragAndDropReport_basicInfoAggregated_endDate: "終了日",
            dragAndDropReport_switch_basicInfoAggregated: "基本的なドラッグ＆ドロップ情報の集約",
            dragAndDropReport_basicInfoAggregated_activityId: "アクティビティIDを入力します",
            confirmDialog_confirmValuePrompt: "この操作を確認するには、以下の入力に値__*{0}*__を入力します。",
            confirmDialog_confirmButton: "確認",
            confirmDialog_closeButton: "閉じる",
            confirmDialog_error_checkBox: "この操作を正常に確認するには、ボックスをチェックする必要があります。",
            confirmDialog_error_confirmValue: "この操作を正常に確認するには、値__*{0}*__を入力する必要があります。",
            confirmDialog_scheduler_title: "アーカイブを確認する",
            confirmDialog_scheduler_body:
                "{0} という名前のエクスペリエンスをアーカイブしようとしています。これにより、将来のセッションで使用するために生成された可能性のあるセッションリンクを含む、エクスペリエンスへのアクセスが無効になります。この操作を確認するには、ボックスをオンにしてエクスペリエンスの名前を入力します。",
            confirmDialog_scheduler_resurrectTitle: "復元を確認する",
            confirmDialog_scheduler_resurrectBody:
                "{0} という名前のエクスペリエンスを復元しようとしています。これにより、エクスペリエンスにアクセスできるようになります。将来のセッションで使用するために生成されたリンクはすべて有効になります。この操作が完了した後、エクスペリエンスの有効期限を更新する必要がある場合もありますので、注意してください。この操作を確認するには、ボックスをオンにしてエクスペリエンスの名前を入力します。",
            confirmDialog_scheduler_destroyTitle: "廃棄を確認する",
            confirmDialog_scheduler_destroyBody:
                "{0} という名前のエクスペリエンスを廃棄しようとしています。これは、元に戻すことのできない回復不能な操作です。この操作を実行する前に、次について確認する必要があります。\r\n1.エクスペリエンスのすべての資産がプロジェクトランドにダウンロードされ、アーカイブされていること。\r\n2.エクスペリエンスをナビゲートするビデオが収録され、プロジェクトランドに保存されていること。\r\n3.プロジェクトの連絡先（システムの Contentful フィールドにリストされている場合）に連絡して、削除が許容されることを確認していること。\r\n4.セッションリポジトリデータ（テキスト入力操作と一般リポジトリ）がダウンロードされ、プロジェクトランドにアーカイブされていること。\r\n\r\nこの操作を確認するには、ボックスをオンにしてエクスペリエンスの名前を入力します。",
            confirmDialog_dataCollectionDefault_title: "データ収集デフォルトの変更を確認する",
            confirmDialog_dataCollectionDefault_body:
                "{1} エクスペリエンスで、データ収集機能を {0} 選択しました。これは、このエクスペリエンスに対して今後生成されるすべてのセッションに影響するものです。このエクスペリエンスの {0} データ収集をしてもよろしいですか？",
            confirmDialog_dataCollectionDefault_body_enable: "有効化する",
            confirmDialog_dataCollectionDefault_body_disable: "無効化する",
            confirmDialog_enable_NavigatorTitle: "本当によろしいですか？",
            confirmDialog_enable_NavigatorBody:
                "[確認] を選択することで、グループが次の数ページの情報を声に出して読み上げるように指示します。最初の読者になりたくない場合は、[閉じる] を選択する",
            vdpNameEntry_NavigatorSelectionMessage: "開始の際には、指示を読み上げるナビゲーターが必要になります",
            vdpNameEntry_NavigatorButtonMessage: "最初の読者になる場合は、ここを選択する",
        },
    },
    pt: {
        name: "Portuguese",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "Não foi possível encontrar a atividade {0}",
            vdpActivity_closeModal: "Encerramento",
            vdpActivity_correctExclamation: "Correto!",
            vdpActivity_correct: "Correto",
            vdpActivity_correctAnswerBadge: "Resposta correta",
            vdpActivity_partiallyCorrect: "Parcialmente correto!",
            vdpActivity_incorrect: "Incorreto",
            vdpActivity_notCorrect: "Não está correto",
            vdpActivity_continue: "Continuar",
            vdpActivity_checkAnswer: "Verificar resposta",
            vdpActivity_ready: "Pronto.",
            vdpActivity_cardsPlaced: "Todos os cartões foram colocados.",
            vdpActivity_cardsCorrectHeader: "Sucesso!",
            vdpActivity_cardsCorrect: "Todos os cartões foram colocados com sucesso.",
            vdpActivity_cardsCorrectContinue: "Continuar para a próxima atividade.",
            vdpActivity_cardsPartiallyCorrectHeader: "Não exatamente.",
            vdpActivity_cardsPartiallyCorrect: "Revise as respostas incorretas e tente movê-las para um novo local.",
            vdpActivity_cardsCheckAnswers: "Verifique suas respostas",
            vdpActivity_textEntryPlaceholder: "Sua resposta",
            vdpActivity_textEntryNavigatorPrompt: "O Navegador ou Navegadora, {0}, vai inserir o texto abaixo",
            vdpActivity_textEntryPrompt: "Insira o texto abaixo:",
            vdpActivity_textEntrySubmit: "Enviar",
            vdpActivity_selectOne: "Selecionar um",
            vdpActivity_selectOneOrMany: "Selecionar um ou mais",
            vdpActivity_defaultCurrentParticipantName: "Eu",
            vdpActivity_hotspotTooltip: "Selecione os hotspots para saber mais.",
            vdpClipboard_copied: "Copiado para a área de transferência!",
            vdpClipboard_copy: "Copiar para a área de transferência",
            vdpClipboard_error: "Não foi possível copiar para a área de transferência",
            vdpHud_hide: "Ocultar",
            vdpHud_show: "Exibir",
            vdpHud_changeNavigatorTitle: "Passar a função de Navegador(a) para outra pessoa.",
            vdpHud_changeNavigatorText1:
                'Você selecionou "Trocar Navegador(a)". Essa ação vai escolher aleatoriamente outro(a) Navegador(a) e desabilitar brevemente todas as interações nesta página.',
            vdpHud_changeNavigatorText2: "Tem certeza de que deseja trocar o(a) Navegador(a)?",
            vdpHud_close: "Encerramento",
            vdpHud_accept: "Trocar o Navegador(a)",
            vdpHud_back: "Voltar",
            vdpHud_next: "Próximo",
            vdpHud_endExperience: "Fim da Experiência",
            vdpHud_defaultExitMsg:
                "Terminamos nossa experiência. Agradecemos sua participação! Esperamos que vocês tenham achado a discussão de hoje esclarecedora. Você pode fechar esta janela.",
            vdpHud_navigatorReminder: "Você é o/a Navegador(a). Leia o seguinte:",
            vdpHud_timerNoTimeSpecified: "Não há tempo especificado para a seção",
            vdpHud_timerRemaining: "restante na seção",
            vdpHud_timerLessThanOneMinute: "Menos de um minuto restante",
            vdpHud_timerOver: "over",
            vdpHud_roleNone: "Nenhum Designado",
            vdpHud_infoTableSection: "Seção",
            vdpHud_infoTableRecommended: "Recomendado",
            vdpHud_infoTableActual: "Atual",
            vdpHud_infoTableTotal: "Total",
            vdpHud_infoTableRemaining: "Restante",
            vdpHud_infoTeamButton: "Equipe",
            vdpHud_infoTeamParticipantJoining: "Usuário entrando...",
            vdpHud_navigator: "Navegador(a)",
            vdpHud_facilitator: "Facilitador(a)",
            vdpHud_facilitatorMenuMakeNavigator: "Tornar Navegador(a)",
            vdpHud_facilitatorMenuRemoveFacilitator: "Remover a Função de Facilitador(a)",
            vdpHud_removeFacilitatorWarningConfirm: "Confirmar",
            vdpHud_removeFacilitatorWarningCancel: "Cancelar",
            vdpHud_removeFacilitatorWarningMessage:
                "Você está prestes a deixar sua função como Facilitador(a). Tem certeza de que deseja continuar?",
            vdpHud_progressOnPaceMessage: "No ritmo",
            vdpHud_progressAheadOfPaceMessage: "À frente do ritmo",
            vdpHud_progressBehindPaceMessage: "Atrás do ritmo",
            vdpHud_progressTimeRemaining: "{0} restante(s) na seção",
            vdpHud_progressStepsInSection: "Página {0} de {1}",
            vdpHud_zoomControlsTooltip: "Controles de zoom do visual.",
            sidebar_scheduleButton: "Cronograma",
            sidebar_teamAssignNavigator: "Designar Navegador(a)",
            sidebar_teamChooseRandomly: "Escolher Aleatoriamente",
            sidebar_teamChooseHelperText:
                "Escolher um(a) participante elegível aleatoriamente para atuar como Navegador(a). Os participantes não serão escolhidos novamente até que todos tenham tido a sua vez.",
            sidebar_editNameMenuItem: "Editar Nome",
            sidebar_editNameSave: "Salvar Alterações",
            sidebar_nameEditPlaceholder: "Digite o nome pelo qual deseja que te chamem.",
            sidebar_manageRolesMenuLabel: "Gerenciar Funções",
            sidebar_navigatorDescription:
                "Lê perguntas, sugestões e instruções em voz alta. Avança o grupo pela experiência selecionando Próximo e seguindo outras instruções observadas em cada tela.",
            sidebar_facilitatorDescription:
                "Serve como um guia durante a sessão para garantir que o grupo tenha uma discussão rica e que a conversa permaneça no caminho certo.",
            sidebar_previousButton: "Anterior",
            sidebar_nextButton: "Próximo",
            sidebar_lockTooltip: "Apenas o/a Navegador(a) pode alterar a página.",
            sidebar_navigatorBadge: "Você é o/a Navegador(a)",
            sidebar_facilitatorBadge: "Você é o/a Facilitador(a)",
            sidebar_introductionLabel: "Introdução",
            sidebar_introductionInstruction: "Leia em voz alta para o grupo:",
            sidebar_directionsLabel: "Instruções",
            sidebar_nonNavigatorDirectionsLabel: "{0} faz o seguinte:",
            sidebar_directionsInstruction: "Faça o seguinte:",
            sidebar_facilitationNotesLabel: "Notas de Facilitação",
            genUrl_copyLink: "Copiar Link",
            genUrl_sessionLinkDetails:
                "Os links desta sessão desaparecerão quando você atualizar a página. Baixe ou copie esses links antes de sair desta página.",
            genUrl_exportAsCsv: "Exportar como CSV",
            genUrl_copyTable: "Copiar tabela",
            genUrl_linkExpiration: "Expiração do Link",
            genUrl_link: "Link",
            genUrl_title: "{0} Gerador de Links de Sessão",
            genUrl_experience: "Experiência: {0}",
            genUrl_description:
                "Este portal permite que você gere links de sessão para esta experiência. Para gerar links de sessão, insira a quantidade de sessões que gostaria de gerar, escolha uma data em que as sessões devem terminar e selecione Gerar Sessões. Depois que esses links forem gerados, copie-os e cole em seu editor de texto ou programa de planilhas favorito. Depois que você atualizar a página, todos os links desaparecerão.",
            genUrl_numSessions: "Quantidade de sessões:",
            genUrl_sessionsExpirationLabel: "Os links de sessão expiram em:",
            genUrl_sessionsOpenAtLabel: "Os links de sessão são válidos a partir de:",
            genUrl_numSessionsInputLabel: "Quantidade de sessões",
            genUrl_sessionLanguageLabel: "Idioma da Sessão",
            genUrl_numSessionsInputTypeError: "O valor inserido deve ser um número",
            genUrl_closesOnError: "Escolha uma data no futuro",
            genUrl_experienceClosesOnError: "Escolha uma data anterior a {0}",
            genUrl_notifSuccessSingle: "{0} link gerado com sucesso",
            genUrl_notifSuccessMultiple: "{0} links gerados com sucesso",
            genUrl_notifErrorGenerating:
                "Não foi possível gerar as sessões. Tente novamente ou entre em contato com o administrador.",
            genUrl_notifErrorProcessing: "Não foi possível processar a ação. Entre em contato com um administrador.",
            genUrl_sessionsExpireToday: "As sessões expiram hoje",
            genUrl_sessionsExpireInOneDay: "As sessões expirarão em {0} dia",
            genUrl_sessionsExpireInDays: "As sessões expirarão em {0} dias",
            genUrl_sessionsOpenToday: "As sessões serão válidas a partir de hoje",
            genUrl_sessionsOpenOneDay: "As sessões serão válidas em {0} dia",
            genUrl_sessionsOpenDays: "As sessões serão válidas em {0} dias",
            genUrl_generate: "Gerar sessões",
            genUrl_schedulingLinkExpired: "O link programado expirou.",
            genUrl_error: "Erro",
            genUrl_dataCollectionLabel: "Coleta de Dados",
            genUrl_emailContact: "Contato por e-mail",
            vdpLoading_waitForContent: "Aguarde enquanto o conteúdo é carregado...",
            vdpNotFound_title: "Plataforma de Diálogo Virtual",
            vdpNotFound_message: "Não foi possível encontrar a página que você está procurando.",
            vdpRenderer_refresh: "Atualizar",
            vdpRenderer_sessionDisconnectedHeader: "Você foi desconectado(a).",
            vpdRenderer_inactiveDisconnectedBody:
                "Você foi desconectado(a) devido à inatividade. Selecione o botão abaixo para tentar se conectar novamente.",
            vdpRenderer_inactiveHeader: "Você está inativo(a).",
            vdpRenderer_inactiveBody:
                "Você permaneceu inativo(a) por muito tempo. Selecione o botão abaixo para permanecer ativo(a) ou você será removido(a) da sessão quando o temporizador for encerrado.",
            vdpRenderer_setSelfActiveButton: "Defina-se como ativo(a)",
            vdpRenderer_sessionDisconnected: "Tentativa de reconectar",
            vdpRenderer_sessionDisconnectedReload: "Recarregue",
            vdpRenderer_endOfSession: "Fim da Sessão",
            vdpRenderer_invalidMapUrl:
                "Um URL inválido foi fornecido para o mapa. Entre em contato com a Root para obter assistência.",
            vdpRenderer_unexpectedError: "Um erro de sistema inesperado ocorreu. Aqui estão as informações técnicas:",
            vdpRenderer_unexpectedErrorMsg: "Um erro inesperado ocorreu: estado de sessão desconhecido {0}",
            vdpRenderer_defaultEndingMessage: "Você pode fechar a janela e desconectar o áudio.",
            vdpErrorModal_title: "Não foi possível carregar a página",
            vdpErrorModal_message:
                "Algo deu errado! Entre em contato conosco para obter assistência em: vmap.help@rootinc.com",
            vdpErrorModal_body: "O erro pode ser resolvido recarregando esta página ou selecionando abaixo:",
            vdpErrorModal_button: "Recarregar esta experiência.",
            vdpNameEntry_welcomeText: "Desejamos as boas-vindas a vocês",
            vdpNameEntry_defaultWelcomeMessage:
                "Esta sessão de diálogo virtual será completamente interativa. Prepare-se para explorar as informações e compartilhar seus pensamentos e opiniões com seus colegas.",
            vdpNameEntry_inputName: "Como deseja que te chamem?",
            vdpNameEntry_inputNamePlaceholder: "Digite o nome pelo qual você quer que te chamem:",
            vdpNameEntry_inputDepartment: "Em que departamento você trabalha?",
            vdpNameEntry_inputEmail: "Qual é o seu endereço de e-mail?",
            vdpNameEntry_inputTeam: "Em que equipe você está?",
            vdpNameEntry_identifiersName: "Digite o nome",
            vdpNameEntry_identifiersDuplicateName:
                "Parece que já tem alguém com o nome {0} conectado hoje. Gostaria de tentar inserir um nome diferente para que possamos diferenciá-lo(a)? Considere adicionar uma inicial do seu sobrenome ou inserir um apelido.",
            vdpNameEntry_identifiersDepartment: "Digite o nome do departamento",
            vdpNameEntry_identifiersEmail: "Digite o e-mail",
            vdpNameEntry_identifiersInvalidEmailFormat: "Formato de e-mail inválido",
            vdpNameEntry_identifiersTeam: "Digite o nome da equipe",
            vdpNameEntry_welcomeHeader: "Prazer em conhecer vocês, {0}!",
            vdpNameEntry_welcomeAdvance: "Conheça sua equipe",
            vdpNameEntry_claimFacilitatorTitle: "Solicitar função de Facilitador(a)",
            vdpNameEntry_facilitatorClaimed: "Função de Facilitador(a) reivindicada",
            vdpNameEntry_claimFacilitatorButton: "Reivindicar Facilitador(a)",
            vdpNameEntry_claimFacilitatorModalCancel: "Cancelar",
            vdpNameEntry_claimFacilitatorModalParagraphOne:
                "Se você for o Facilitador ou a Facilitadora desta sessão, selecione Reivindicar Facilitador(a) abaixo.",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "Como Facilitador(a), você terá o controle das primeiras páginas para começarmos. Depois o sistema selecionará automaticamente outro Navegador ou Navegadora.",
            vdpNameEntry_claimFacilitatorModalParagraphThree:
                "Se você recebeu um convite para esta sessão como participante, selecione Cancelar.",
            vdpNameEntry_numParticipantsInSession: "{0} participantes estão esperando...",
            vdpNameEntry_oneParticipantsInSession: "1 participante está esperando...",
            vdpNameEntry_save: "Salvar",
            vdpNameEntry_continue: "Continuar",
            vdpNameEntry_thanks: "Agradecemos, {0} Começaremos em breve!",
            vdpNameEntry_intro: "Digite seu nome e aguarde até que todos entrem. Começaremos em breve!",
            vdpNameEntry_introStarted: "Digite seu nome e junte-se aos seus colegas.",
            vdpNameEntry_navDirections: "Aguarde até que a equipe participe.",
            vdpValidationErrorMinLength: "Digite pelo menos {0} caracteres.",
            vdpValidationErrorMaxLength: "Não digite mais do que {0} caracteres.",
            toolbar_stepOf: "Seção {0}, Página {1} - {2} ({3})",
            toolbar_intro: "Etapa {0} da Seção {1}",
            toolbar_welcome: "Sequência de Boas-vindas",
            toolbar_exit: "Sequência de Saída",
            toolbar_reloadContent: "Recarregar Conteúdo",
            toolbar_commentCreationButton: "Comentar ({0})",
            toolbar_buildComments: "Comentários de Construção",
            toolbar_commentListButton: "Visualizar Comentários ({0})",
            toolbar_commentCreationTitle: "Adicionar Comentário de Construção",
            toolbar_commentCreationDescription:
                "Os comentários serão salvos e encaminhados à equipe de construção. Inclua seu nome ou suas iniciais no comentário.",
            toolbar_commentCreationCancelButton: "Cancelar",
            toolbar_commentCreationSubmitButton: "Postar Comentário",
            toolbar_commentCreationSubmitButtonDisabled: "Postando comentários...",
            toolbar_commentCreationPlaceholder: "Inserir Comentário...",
            toolbar_previewMode: "Modo de Pré-visualização:",
            toolbar_aboutPreviewMode: "Sobre o modo de pré-visualização",
            toolbar_focusAreaSelector: "Seletor de Área de Foco",
            toolbar_hotspotSelector: "Seletor de Hotspot",
            toolbar_previewModeModalTitle: "Visualiza Modo",
            toolbar_previewModeModalFirstIntroduction:
                "Você está visualizando esta experiência no Modo de Pré-visualização. Esta visualização foi projetada apenas para revisão interna da Root e fornece acesso às Ferramentas de Criação.",
            toolbar_previewModeModalSecondIntroduction:
                "Essas opções estão disponíveis apenas no Modo de Pré-visualização e não serão visíveis para o cliente ou para os participantes.",
            toolbar_previewModeModalReloadContent: "Recarregar Conteúdo",
            toolbar_previewModeModalReloadContentDescription:
                "Use este botão para atualizar a experiência com as atualizações mais recentes do Contentful sem precisar recarregar a sessão.",
            toolbar_previewModeModalComments: "Comentários",
            toolbar_previewModeModalCommentsDescription:
                "Adicione um comentário de feedback ou indique alguma alteração que seja necessária. Os comentários serão capturados no Contentful para a equipe de construção.",
            toolbar_previewModeModalMapCoordinates: "Coordenadas do Visual",
            toolbar_previewModeModalMapCoordinatesDescription: "Copiar as coordenadas do mapa.",
            toolbar_previewModeModalHotspotSelector: "Seletor de Hotspot",
            toolbar_previewModeModalHotspotSelectorDescription:
                "Visualizar os hotspots e copiar as coordenadas para uso no Contentful.",
            coordinatePreview_coordinatesSelectorTitle: "Seletor de Coordenadas",
            coordinatePreview_hotspotMode: "Modo Hotspot",
            coordinatePreview_hotspotModeCoordinatesCopiedToast:
                "As coordenadas do hotspot foram copiadas para a área de transferência.",
            coordinatePreview_focusAreaMode: "Modo Área de Foco",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast:
                "As coordenadas da área de foco foram copiadas para a área de transferência.",
            coordinatePreview_directionsTitle: "Instruções do seletor de coordenadas",
            coordinatePreview_hotspotDirectionsLineOne: "Selecione um ponto no mapa para criar um hotspot.",
            coordinatePreview_hotspotDirectionsLineTwo:
                "Selecione o botão Copiar em cada hotspot para copiar as coordenadas para o Contentful.",
            coordinatePreview_focusAreaDirectionsLineOne: "Selecione e arraste para desenhar um retângulo no mapa.",
            coordinatePreview_focusAreaDirectionsLineTwo:
                "Coloque o retângulo sobre a área que estará visível para a etapa.",
            coordinatePreview_focusAreaDirectionsLineThree:
                "Selecione o botão Copiar em cada área de foco para copiar as coordenadas para o Contentful.",
            coordinatePreview_reset: "Restaurar",
            coordinatePreview_done: "Pronto",
            navigatorDefaultInstructions_selectNextToContinue: "Selecione ***Próximo*** para continuar.",
            navigatorDefaultInstructions_progressCheckLineOne: "Chegamos ao fim da seção {0}.",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace:
                "Estamos no ritmo. Vamos continuar fazendo um bom trabalho!",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "Você está à frente do ritmo. Vamos tentar não nos apressarmos no material restante.",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "Estamos atrasados. Precisamos acelerar o ritmo para concluir a sessão no tempo.",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav:
                "Na próxima página, vamos apresentar um novo Navegador/uma nova Navegadora para a seção {0}.",
            maintenanceWindow_heading: "A manutenção está programada para {0}, {1} de {2} a {3} {4}.",
            maintenanceWindow_body: "O aplicativo pode não estar disponível durante esse período.",
            maintenanceError_overlap:
                "As datas que você escolheu entram em conflito com o período de manutenção programada para {0}. A manutenção ocorre toda semana de {1} a {2}. Certifique-se de que as datas de início e término não coincidam com uma janela de manutenção.",
            progressCheck_sectionTitle: "Seção {0} de {1}",
            progressCheck_paceCheckTitle: "Verificação do Ritmo da Sessão",
            progressCheck_paceMessageOnPace: "Continue fazendo um bom trabalho!",
            progressCheck_paceMessageAheadOfPace: "Tente não se apressar no restante do material.",
            progressCheck_paceMessageBehindPace: "Você precisa acelerar o ritmo para concluir a sessão no tempo.",
            progressCheck_navigatorChangeTitle: "Troca de Navegador(a)",
            progressCheck_navigatorChangeMessage:
                "Agradecemos por liderar essa parte, {0}. Outro Navegador ou Navegadora será designado(a) para a próxima página.",
            vdpIntro_title: "Introdução",
            vdpIntro_subtitle: "Algumas coisas rápidas antes de começarmos...",
            vdpIntro_joinTheTeam: "Junte-se à equipe",
            vdpIntro_facilitatorRole: "Função de Facilitador(a)",
            vdpIntro_navigatorRole: "Função de Navegador(a)",
            vdpIntro_participantRole: "Função de Participante",
            vdpIntro_iceBreaker: "Conheça a Equipe",
            vdpIntro_exploreTheVisual: "O visual",
            vdpIntro_skipShort: "Pular",
            vdpIntro_skipLong: "Pular Introdução",
            vdpIntro_facilitatorInstructionsOne:
                "Desejamos as Boas-vindas a vocês! A sessão de hoje é diferente de outros cursos ou treinamentos dos quais vocês possam ter participado.",
            vdpIntro_facilitatorInstructionsTwo:
                "Não há professor(a) ou instrutor(a) — vocês vão aprender uns com os outros e com as informações na tela.",
            vdpIntro_facilitatorInstructionsThree:
                "Minha função como Facilitador(a) é manter a conversa no caminho certo e dentro do tempo estipulado.",
            vdpIntro_navigatorInstructionsOne:
                "Nas próximas telas, também serei Navegador(a). A função de Navegador(a) é ler o texto de introdução em cada tela, selecionar o botão ***Próximo*** para nos movermos pela experiência e seguir outras instruções conforme solicitado.",
            vdpIntro_navigatorInstructionsTwo:
                "A função de Navegador(a) será atribuída aleatoriamente e mudará cada vez que passarmos por uma certa quantidade de telas.",
            vdpIntro_participantInstructionsOne:
                "Todos vocês são participantes! Sua função é participar ativamente, compartilhando suas perspectivas com o grupo. Vocês podem ajudar a sessão a correr bem, revezando-se para falar, respeitando as ideias e opiniões dos outros e mantendo a conversa no caminho certo.",
            vdpIntro_participantInstructionsTwo:
                "Vocês podem ver quem está na sessão e a função de cada um usando o botão ***Equipe*** no canto inferior esquerdo.",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "Em seguida, cada um de nós terá a chance de ver como é a função de Navegador(a) enquanto nos apresentamos.",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "Quando você vir o banner ***Você é o(a) Navegador(a)*** acima do texto de Introdução, é a sua vez de ler e seguir as instruções. Alguém tem alguma pergunta?",
            vdpIntro_iceBreakerInstructionsOne: "Oi, Eu sou {0}.",
            vdpIntro_iceBreakerInstructionsDefault: "Um fato sobre mim é _______.",
            vdpIntro_mapInstructionsOne:
                "Agora que todos nós nos conhecemos, vamos aprender mais sobre nossa sessão de hoje.",
            vdpIntro_mapInstructionsTwo:
                "Nossa conversa vai focar em uma imagem chamada Mapa do *Learning Map*®. É uma metáfora ilustrada que representa aspectos do nosso negócio que vamos explorar juntos. ",
            vdpIntro_iceBreakerIntroDirections: "Responda a todas as perguntas.",
            vdpEnd_title: "Obrigada.",
            vdpEnd_body:
                "Terminamos nossa experiência. Agradecemos sua participação! Esperamos que vocês tenham achado a discussão de hoje esclarecedora.",
            vdpEnd_surveyRequest:
                "Reserve alguns minutos para compartilhar suas ideias e reações sobre as informações que discutimos. Seu feedback é importante para o nosso sucesso.",
            vdpEnd_surveyThankYou: "Agradecemos sua resposta.",
            vdpEnd_sessionComplete: "A sessão está concluída.",
            vdpEnd_closeWindow: "Você pode fechar esta janela.",
            privacyBanner_message:
                "A proteção dos seus dados pessoais é muito importante para a Accenture e quaisquer dados pessoais que você fornecer por meio desta ferramenta podem ser processados pela Accenture. A Accenture está comprometida em manter seus dados pessoais seguros e processá-los de acordo com as leis de privacidade aplicáveis e nossas políticas internas. Os dados pessoais que a Accenture processa podem ser transferidos ou acessíveis internacionalmente em todos os negócios globais da Accenture e entre suas entidades e afiliadas e terceiros. Antes de usar esta ferramenta, a Accenture convida você a ler atentamente sua declaração de privacidade [Declaração de Privacidade]({1}) e [Política de Cookies]({0}), que inclui informações importantes sobre por que e como a Accenture está processando seus dados pessoais.",
            privacyBanner_dismiss: "Concordo",
            vdpActivity_New: "nova atividade",
            vdpActivity_submit: "Enviar",
            vdpActivity_submitted: "Enviado",
            vdpActivity_editableCardWarning: "Preencha todos os cartões em branco antes de enviar.",
            vdpActivity_matchingSuccess: "Sucesso!",
            vdpActivity_matchingSuccessDescription: "Todos os cartões foram colocados corretamente.",
            vdpActivity_matchingFail: "Não exatamente.",
            vdpActivity_matchingFailDescription:
                "Arraste os cartões incorretos de volta para a coluna da esquerda. Depois tente combiná-los com um novo ponto.",
            vdpActivity_matchingReady: "Pronto.",
            vdpActivity_matchingReadyDescription: "Todos os cartões foram colocados.",
            vdpActivity_matchingCheckAnswers: "Verifique suas respostas",
            vdpActivity_matchingContinue: "Continuar para a próxima atividade.",
            vdpActivity_cardStackWithVisualAriaLabel: "Pilha de cartões com visuais.",
            vdpActivity_cardStackWithVisualAriaDescription: "Coloque os cartões na ordem correta",
            vdpActivity_revealCardAriaLabel: "Etapa de revelação do cartão",
            vdpActivity_revealCardAriaDescription: "Selecione o cartão para revelar seu conteúdo",
            vdpActivity_dragAndDropAriaLabel: "Etapa de arrastar e soltar",
            vdpActivity_dragAndDropAriaDescription: "Arraste e solte os cartões nas colunas corretas",
            vdpActivity_matchingStepAriaLabel: "Etapa de correspondência de cartões",
            vdpActivity_matchingStepAriaDescription: "Mova o cartão para o local correto para fazer a correspondência",
            vdpActivity_recallStepAriaLabel: "Etapa de recall",
            vdpActivity_recallStepAriaDescription: "Esta é uma etapa de recall, siga as instruções",
            vdpActivity_multipleChoiceAriaLabel: "Etapa de múltipla escolha",
            vdpActivity_multipleChoiceAriaDescription: "Escolha a partir das opções de múltipla escolha.",
            vdpActivity_textEntryAriaLabel: "Atividade de inserção de texto",
            vdpActivity_textEntryAriaDescription: "Envie a sua resposta na caixa de texto",
            vdpActivity_singleAssetStepAriaLabel: "Etapa de ativo único",
            vdpActivity_singleAssetStepAriaDescription: "Siga as instruções.",
            vdpActivity_comparisonStepAriaLabel: "Etapa de comparação",
            vdpActivity_comparisonStepAriaDescription: "Siga as instruções.",
            vdpActivity_FirstTitlePosition: "Você está no primeiro item",
            vdpActivity_LastTitlePosition: "Você está no último item",
            vdpActivity_videoStepAriaLabel: "Etapa do vídeo",
            vdpActivity_videoStepAriaDescription: "Nesta etapa, um vídeo será exibido para os participantes",
            vdpActivity_progressCheckAriaLabel: "Verificação de progresso",
            vdpActivity_progressCheckAriaDescription:
                "Você está à frente do ritmo. Tente não se apressar no restante do material",
            vdpActivity_exitStepAriaLabel: "Etapa de saída",
            vdpActivity_exitStepAriaDescription: "Terminamos nossa experiência. Você pode fechar esta janela",
            vdpHud_infoTeamButtonDescription:
                "Selecione este item para visualizar uma lista dos participantes da sessão, passar a função de Navegador(a) para um(a) participante aleatório(a) e alterar seu nome de participante.",
            vdpHud_zoomControlsDescription: "Utilize essas ações para aumentar ou diminuir o zoom do mapa.",
            vdpHud_zoomControlsZoomIn: "Aumentar o zoom",
            vdpHud_zoomControlsZoomOut: "Diminuir o zoom",
            sidebar_scheduleButtonDescription:
                "Visualize um resumo com a estimativa do tempo que cada seção deve levar e de quanto tempo foi gasto em cada seção.",
            sidebar_previousButtonDescription:
                "Selecione este botão para avançar a sessão do grupo para a página anterior.",
            sidebar_nextButtonDescription: "Selecione este botão para avançar a sessão do grupo para a próxima página.",
            sidebar_navigatorBadgeDescription:
                'O Navegador ou a Navegadora é responsável por avançar o grupo para as próximas etapas da sessão. Use os botões "Próximo" e "Anterior" para navegar.',
            sidebar_skipButtonDescription:
                "Selecione este botão para pular a seção de boas-vindas para a sessão do grupo.",
            genUrl_dataCollectionDefaultLabel: "Padrão de Coleta de Dados",
            genUrl_goToSessionRepoReport: "Ferramenta Reportar",
            genUrl_archiveButton: "Arquivar",
            genUrl_resurrectButton: "Recuperar",
            genUrl_destroyButton: "Destruir",
            vdpRenderer_refreshDescription: "Você precisa atualizar a página para tentar se reconectar à sessão.",
            vdpRenderer_setSelfActiveButtonDescription:
                "Selecione este botão para permanecer ativo(a) e conectado(a) como participante na sessão atual.",
            vdpNameEntry_saveDescription:
                "Envie a forma pela qual você gostaria que te chamassem e aguarde o início da sessão.",
            vdpNameEntry_continueDescription:
                "Envie a forma pela qual você gostaria que te chamassem e participe da sessão em andamento.",
            vdpNameEntry_smallScreenWarningTitle: "Estamos felizes que você esteja aqui!",
            vdpNameEntry_smallScreenWarningBody: "... Para uma melhor experiência, faça login em um laptop ou desktop.",
            vdpNameEntry_smallScreenWarningDismiss: "Dispensar",
            privacyBanner_title: "Privacidade de Dados",
            privacyBanner_dismissDescription:
                "Esta ação dispensará o aviso detalhando as políticas de privacidade e cookies do site.",
            adminMessage_unauthorized: "Você não tem autorização para realizar a seguinte ação: {0}.",
            adminMessage_unauthorized_viewExp: "visualizar todas as experiências.",
            sessionReport_submitButton: "Gerar relatório",
            sessionReport_header_title: "{0} Ferramenta Reportar da sessão",
            sessionReport_header_experience: "Nome da experiência: {0}",
            sessionReport_header_errorTitle: "Erro de carregamento",
            sessionReport_header_errorSubtitle: "Não foi possível recuperar as informações de experiência.",
            sessionReport_switch_unknown: "Tipo de resultado de relatório não reconhecido",
            sessionReport_results_downloadResults: "Baixar resultados",
            sessionReport_results_noRecordsTitle: "Nenhum registro encontrado",
            sessionReport_results_noRecordsSubTitle: "Não há dados que se encaixem nesse critério.",
            sessionReport_results_errorTitle: "Erro ao gerar relatório",
            sessionReport_basicInfo_startDate: "Data de início",
            sessionReport_basicInfo_endDate: "Data de término",
            sessionReport_basicInfo_useSeedData: "Usar dados iniciais",
            sessionReport_basicInfo_useSeedData_help:
                "Indica se o relatório gerado deve incluir dados aleatórios de amostra que foram gerados. Esta opção é destinada apenas para fins de teste.",
            sessionReport_switch_basicInfo: "Informações básicas da sessão",
            activityReport_basicInfo_startDate: "Data de início",
            activityReport_basicInfo_endDate: "Data de término",
            activityReport_basicInfo_useSeedData: "Usar dados iniciais",
            activityReport_basicInfo_useSeedData_help:
                "Indica se o relatório gerado deve incluir dados aleatórios de amostra que foram gerados. Esta opção é destinada apenas para fins de teste.",
            activityReport_switch_basicInfo: "Informações básicas de atividade",
            activityReport_basicInfoAggregated_startDate: "Data de início",
            activityReport_basicInfoAggregated_endDate: "Data de término",
            activityReport_basicInfoAggregated_useSeedData: "Usar dados iniciais",
            activityReport_basicInfoAggregated_useSeedData_help:
                "Indica se o relatório gerado deve incluir dados aleatórios de amostra que foram gerados. Esta opção é destinada apenas para fins de teste.",
            activityReport_switch_basicInfoAggregated: "Informações básicas de atividades agregadas",
            multipleChoiceReport_basicInfo_startDate: "Data de início",
            multipleChoiceReport_basicInfo_endDate: "Data de término",
            multipleChoiceReport_basicInfo_useSeedData: "Usar dados iniciais",
            multipleChoiceReport_basicInfo_useSeedData_help:
                "Indica se o relatório gerado deve incluir dados aleatórios de amostra que foram gerados. Esta opção é destinada apenas para fins de teste.",
            multipleChoiceReport_switch_basicInfo: "Informações básicas de múltipla escolha",
            multipleChoiceReport_basicInfo_activityId: "Insira ID de atividade",
            multipleChoiceReport_basicInfoAggregated_startDate: "Data de início",
            multipleChoiceReport_basicInfoAggregated_endDate: "Data de término",
            multipleChoiceReport_basicInfoAggregated_useSeedData: "Usar dados iniciais",
            multipleChoiceReport_basicInfoAggregated_useSeedData_help:
                "Indica se o relatório gerado deve incluir dados aleatórios de amostra que foram gerados. Esta opção é destinada apenas para fins de teste.",
            multipleChoiceReport_switch_basicInfoAggregated: "Informações básicas de múltipla escolha agregada",
            multipleChoiceReport_basicInfoAggregated_activityId: "Insira ID de atividade",
            dragAndDropReport_basicInfo_useSeedData: "Usar dados iniciais",
            dragAndDropReport_basicInfo_useSeedData_help:
                "Indica se o relatório gerado deve incluir dados aleatórios de amostra que foram gerados. Esta opção é destinada apenas para fins de teste.",
            dragAndDropReport_basicInfo_startDate: "Data de início",
            dragAndDropReport_basicInfo_endDate: "Data de término",
            dragAndDropReport_switch_basicInfo: "Informações básicas de Arrastar e Soltar",
            dragAndDropReport_basicInfo_activityId: "Insira ID de atividade",
            dragAndDropReport_basicInfoAggregated_useSeedData: "Usar dados iniciais",
            dragAndDropReport_basicInfoAggregated_useSeedData_help:
                "Indica se o relatório gerado deve incluir dados aleatórios de amostra que foram gerados. Esta opção é destinada apenas para fins de teste.",
            dragAndDropReport_basicInfoAggregated_startDate: "Data de início",
            dragAndDropReport_basicInfoAggregated_endDate: "Data de término",
            dragAndDropReport_switch_basicInfoAggregated: "Informações básicas de Arrastar e Soltar agregadas",
            dragAndDropReport_basicInfoAggregated_activityId: "Insira ID de atividade",
            confirmDialog_confirmValuePrompt: "Para confirmar esta ação, insira o valor __*{0}*__ na entrada abaixo.",
            confirmDialog_confirmButton: "Confirmar",
            confirmDialog_closeButton: "Encerramento",
            confirmDialog_error_checkBox: "Você deve marcar a caixa para confirmar esta ação com sucesso.",
            confirmDialog_error_confirmValue: "Você deve inserir o valor __*{0}*__ para confirmar esta ação.",
            confirmDialog_scheduler_title: "Confirmar arquivamento",
            confirmDialog_scheduler_body:
                "Você está prestes a arquivar a experiência chamada {0}. Isso desabilitará o acesso à experiência, incluindo quaisquer links de sessão que possam ter sido gerados para sessões futuras. Para confirmar esta ação, marque a caixa e insira o nome da experiência.",
            confirmDialog_scheduler_resurrectTitle: "Confirmar Restaurar",
            confirmDialog_scheduler_resurrectBody:
                "Você está prestes a restaurar a experiência chamada {0}. Isso habilitará o acesso à experiência. Quaisquer links gerados para sessões futuras se tornarão válidos. Observe que talvez seja necessário atualizar a data de expiração da experiência após a conclusão desta ação. Para confirmar esta ação, marque a caixa e insira o nome da experiência.",
            confirmDialog_scheduler_destroyTitle: "Confirmar destruição",
            confirmDialog_scheduler_destroyBody:
                "Você está prestes a destruir a experiência chamada {0}. Esta é uma ação irreversível. Antes de executar esta ação, você deve confirmar o seguinte: \n1. Todos os ativos da experiência foram baixados e arquivados no project land;\n2. Um vídeo de navegação pela experiência foi gravado e armazenado no project land;\n3. Os contatos do projeto (se houver algum listado no campo Contentful em nosso sistema) foram contatados para verificar se a exclusão é aceitável;\n4. Os dados do repositório de sessão - atividades de entrada de texto e o repositório geral - foram baixados e arquivados no project land.;\n\nPara confirmar esta ação, marque a caixa e insira o nome da experiência.",
            confirmDialog_dataCollectionDefault_title: "Confirmar alteração para Padrão de Coleta de Dados",
            confirmDialog_dataCollectionDefault_body:
                "Você escolheu {0} o recurso de coleta de dados na experiência {1}. Isso afetará todas as sessões geradas no futuro para esta experiência. Tem certeza de que deseja {0} a coleta de dados para esta experiência?",
            confirmDialog_dataCollectionDefault_body_enable: "habilitar",
            confirmDialog_dataCollectionDefault_body_disable: "desabilitar",
            confirmDialog_enable_NavigatorTitle: "Tem certeza?",
            confirmDialog_enable_NavigatorBody:
                "Ao selecionar 'Confirmar', você iniciará o grupo lendo as informações nas próximas páginas em voz alta. Selecione 'Fechar' se preferir não ser o primeiro leitor",
            vdpNameEntry_NavigatorSelectionMessage:
                "Vamos precisar de um(a) Navegador(a) para ler as instruções em voz alta para começarmos",
            vdpNameEntry_NavigatorButtonMessage: "Clique aqui para atuar como primeiro leitor",
        },
    },
    "zh-CN": {
        name: "Chinese",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "找不到活动 {0}",
            vdpActivity_closeModal: "结束",
            vdpActivity_correctExclamation: "正确！",
            vdpActivity_correct: "正确",
            vdpActivity_correctAnswerBadge: "正确答案",
            vdpActivity_partiallyCorrect: "部分正确！",
            vdpActivity_incorrect: "不正确",
            vdpActivity_notCorrect: "不正确",
            vdpActivity_continue: "继续",
            vdpActivity_checkAnswer: "查看答案",
            vdpActivity_ready: "准备就绪。",
            vdpActivity_cardsPlaced: "所有卡片均已放置。",
            vdpActivity_cardsCorrectHeader: "成功！",
            vdpActivity_cardsCorrect: "所有卡片均已成功放置。",
            vdpActivity_cardsCorrectContinue: "继续进行下一个活动。",
            vdpActivity_cardsPartiallyCorrectHeader: "不太准确。",
            vdpActivity_cardsPartiallyCorrect: "查看错误的答案并尝试将它们移动到新位置。",
            vdpActivity_cardsCheckAnswers: "检查你的回答",
            vdpActivity_textEntryPlaceholder: "你的回复",
            vdpActivity_textEntryNavigatorPrompt: "引导员 {0} 将在下方输入文本",
            vdpActivity_textEntryPrompt: "在下方输入文本：",
            vdpActivity_textEntrySubmit: "提交",
            vdpActivity_selectOne: "选择一项",
            vdpActivity_selectOneOrMany: "选择一项或多项",
            vdpActivity_defaultCurrentParticipantName: "我",
            vdpActivity_hotspotTooltip: "选择热点以了解更多信息。",
            vdpClipboard_copied: "已复制到剪贴板！",
            vdpClipboard_copy: "复制到剪贴板",
            vdpClipboard_error: "无法复制到剪贴板",
            vdpHud_hide: "隐藏",
            vdpHud_show: "显示",
            vdpHud_changeNavigatorTitle: "将引导员更改为新人员。",
            vdpHud_changeNavigatorText1:
                "你选择了“更改引导员”。这将随机选择另一个引导员，并暂时禁用此页面上的所有交互。",
            vdpHud_changeNavigatorText2: "你确定要更改引导员吗？",
            vdpHud_close: "结束",
            vdpHud_accept: "更改引导员",
            vdpHud_back: "背面",
            vdpHud_next: "下一页",
            vdpHud_endExperience: "结束体验",
            vdpHud_defaultExitMsg: "我们已经完成体验。感谢参与！我们希望你发现今天的讨论很有见地。你可以关闭此窗口。",
            vdpHud_navigatorReminder: "你是引导员。请朗读以下内容：",
            vdpHud_timerNoTimeSpecified: "未指定本节的时间",
            vdpHud_timerRemaining: "本节剩余",
            vdpHud_timerLessThanOneMinute: "还剩不到一分钟",
            vdpHud_timerOver: "over",
            vdpHud_roleNone: "未分配",
            vdpHud_infoTableSection: "章节",
            vdpHud_infoTableRecommended: "推荐",
            vdpHud_infoTableActual: "实际",
            vdpHud_infoTableTotal: "总计",
            vdpHud_infoTableRemaining: "剩余",
            vdpHud_infoTeamButton: "团队",
            vdpHud_infoTeamParticipantJoining: "用户正在加入……",
            vdpHud_navigator: "引导员",
            vdpHud_facilitator: "课程导师",
            vdpHud_facilitatorMenuMakeNavigator: "成为引导员",
            vdpHud_facilitatorMenuRemoveFacilitator: "删除课程导师角色",
            vdpHud_removeFacilitatorWarningConfirm: "确认",
            vdpHud_removeFacilitatorWarningCancel: "取消",
            vdpHud_removeFacilitatorWarningMessage: "你即将失去课程导师角色。你确定要继续吗？",
            vdpHud_progressOnPaceMessage: "跟上进度",
            vdpHud_progressAheadOfPaceMessage: "领先于进度",
            vdpHud_progressBehindPaceMessage: "落后于进度",
            vdpHud_progressTimeRemaining: "本节剩余 {0}",
            vdpHud_progressStepsInSection: "第 {0} 页，共 {1} 页",
            vdpHud_zoomControlsTooltip: "视图材料缩放控件",
            sidebar_scheduleButton: "时间安排",
            sidebar_teamAssignNavigator: "分配引导员",
            sidebar_teamChooseRandomly: "随机选择",
            sidebar_teamChooseHelperText:
                "随机选择符合条件的参与者作为引导员。在每个人都轮到之前，一位参与者不会再次被选中。",
            sidebar_editNameMenuItem: "编辑名称",
            sidebar_editNameSave: "保存更改",
            sidebar_nameEditPlaceholder: "输入你想让大家称呼你的名字……",
            sidebar_manageRolesMenuLabel: "管理角色",
            sidebar_navigatorDescription:
                "大声朗读问题、提示和说明。通过选择“下一步”并按照每个屏幕上显示的其他说明，推进小组完成体验。",
            sidebar_facilitatorDescription: "在整个课程过程中充当指南，以确保小组进行丰富的讨论并且对话顺利进行。",
            sidebar_previousButton: "上一页",
            sidebar_nextButton: "下一页",
            sidebar_lockTooltip: "只有引导员可以更改页面。",
            sidebar_navigatorBadge: "你是引导员",
            sidebar_facilitatorBadge: "你是课程导师",
            sidebar_introductionLabel: "引言",
            sidebar_introductionInstruction: "向小组大声朗读：",
            sidebar_directionsLabel: "说明",
            sidebar_nonNavigatorDirectionsLabel: "{0} 执行以下操作：",
            sidebar_directionsInstruction: "执行以下操作：",
            sidebar_facilitationNotesLabel: "引导教学备注",
            genUrl_copyLink: "复制链接",
            genUrl_sessionLinkDetails: "当你刷新页面时，这些课程链接将消失。请务必在离开此页面之前下载或复制这些链接。",
            genUrl_exportAsCsv: "导出为 CSV",
            genUrl_copyTable: "复制表格",
            genUrl_linkExpiration: "链接过期",
            genUrl_link: "链接",
            genUrl_title: "{0} 课程链接生成器",
            genUrl_experience: "体验：{0}",
            genUrl_description:
                "此门户网站允许你为本体验生成课程链接。要生成课程链接，请输入要生成的课程数，选择课程的过期日期，然后选择生成课程。生成这些链接后，将它们复制并粘贴到你收藏的文本编辑器或电子表格程序中。刷新页面后，所有链接都将消失。",
            genUrl_numSessions: "课程数：",
            genUrl_sessionsExpirationLabel: "课程链接的过期时间：",
            genUrl_sessionsOpenAtLabel: "课程链接的有效期从以下时间开始：",
            genUrl_numSessionsInputLabel: "课程数",
            genUrl_sessionLanguageLabel: "课程语言",
            genUrl_numSessionsInputTypeError: "必须为数字",
            genUrl_closesOnError: "选择一个将来的日期",
            genUrl_experienceClosesOnError: "选择早于 {0} 的日期",
            genUrl_notifSuccessSingle: "已成功生成 {0} 个链接",
            genUrl_notifSuccessMultiple: "已成功生成 {0} 个链接",
            genUrl_notifErrorGenerating: "无法生成课程。请重试或联系管理员。",
            genUrl_notifErrorProcessing: "无法处理。请联系管理员。",
            genUrl_sessionsExpireToday: "课程将于今天过期",
            genUrl_sessionsExpireInOneDay: "课程将于 {0} 天后过期",
            genUrl_sessionsExpireInDays: "课程将于 {0} 天后过期",
            genUrl_sessionsOpenToday: "课程将于今天开始",
            genUrl_sessionsOpenOneDay: "课程将于 {0} 天后开始",
            genUrl_sessionsOpenDays: "课程将于 {0} 天后开始",
            genUrl_generate: "生成课程",
            genUrl_schedulingLinkExpired: "安排的链接已过期。",
            genUrl_error: "错误",
            genUrl_dataCollectionLabel: "数据采集",
            genUrl_emailContact: "电子邮件联系",
            vdpLoading_waitForContent: "请稍候，你的内容正在加载……",
            vdpNotFound_title: "虚拟对话平台",
            vdpNotFound_message: "找不到你要查找的页面。",
            vdpRenderer_refresh: "刷新",
            vdpRenderer_sessionDisconnectedHeader: "你已断开连接。",
            vpdRenderer_inactiveDisconnectedBody: "你因非活动状态而断开连接。选择下方的按钮以尝试重新连接。",
            vdpRenderer_inactiveHeader: "你处于非活动状态。",
            vdpRenderer_inactiveBody:
                "你处于非活动状态的时间过长。选择下方的按钮以保持活动状态，否则当计时器到时间后，你将退出课程。",
            vdpRenderer_setSelfActiveButton: "将自己设置为活动状态",
            vdpRenderer_sessionDisconnected: "尝试重新连接",
            vdpRenderer_sessionDisconnectedReload: "请重新加载",
            vdpRenderer_endOfSession: "课程结束",
            vdpRenderer_invalidMapUrl: "为视图材料提供了无效的 URL。请联系 Root 寻求帮助。",
            vdpRenderer_unexpectedError: "发生意外的系统错误。以下是技术细节：",
            vdpRenderer_unexpectedErrorMsg: "发生意外错误：未知课程状态 {0}",
            vdpRenderer_defaultEndingMessage: "你现在可以关闭窗口并断开与音频的连接。",
            vdpErrorModal_title: "无法加载页面",
            vdpErrorModal_message: "某些地方出错！如需帮助，请通过以下方式联系我们：vmap.help@rootinc.com",
            vdpErrorModal_body: "可以通过重新加载此页面或选择以下内容来解决此错误：",
            vdpErrorModal_button: "重新加载此体验。",
            vdpNameEntry_welcomeText: "欢迎",
            vdpNameEntry_defaultWelcomeMessage:
                "这个虚拟对话课程是完全互动的。准备好探索信息并与同行分享你的想法和意见。",
            vdpNameEntry_inputName: "你想让大家称呼你什么？",
            vdpNameEntry_inputNamePlaceholder: "输入你想让大家称呼你的名字：",
            vdpNameEntry_inputDepartment: "你在哪个部门工作？",
            vdpNameEntry_inputEmail: "你的电子邮件地址是什么？",
            vdpNameEntry_inputTeam: "你是哪个团队的？",
            vdpNameEntry_identifiersName: "请输入名称",
            vdpNameEntry_identifiersDuplicateName:
                "看起来今天已经有一个 {0} 连接到课程。你想尝试输入其他名称以便我们进行区分吗？考虑为你的姓氏添加首字母或输入昵称。",
            vdpNameEntry_identifiersDepartment: "请输入部门名称",
            vdpNameEntry_identifiersEmail: "请输入电子邮件地址",
            vdpNameEntry_identifiersInvalidEmailFormat: "电子邮件格式无效",
            vdpNameEntry_identifiersTeam: "请输入团队名称",
            vdpNameEntry_welcomeHeader: "很高兴认识你，{0}！",
            vdpNameEntry_welcomeAdvance: "认识你的团队",
            vdpNameEntry_claimFacilitatorTitle: "确认课程导师角色",
            vdpNameEntry_facilitatorClaimed: "已确认课程导师角色",
            vdpNameEntry_claimFacilitatorButton: "确认课程导师",
            vdpNameEntry_claimFacilitatorModalCancel: "取消",
            vdpNameEntry_claimFacilitatorModalParagraphOne: "如果你是本课程的课程导师，请选择下方的“确认课程导师”。",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "作为课程导师，你可以控制前几页，以便我们开始。然后，系统将自动选择新的引导员。",
            vdpNameEntry_claimFacilitatorModalParagraphThree: "如果你作为参与者受邀参加此课程，请选择“取消”。",
            vdpNameEntry_numParticipantsInSession: "{0} 名参与者正在等待……",
            vdpNameEntry_oneParticipantsInSession: "1 名参与者正在等待……",
            vdpNameEntry_save: "保存",
            vdpNameEntry_continue: "继续",
            vdpNameEntry_thanks: "谢谢，{0}。我们很快就开始！",
            vdpNameEntry_intro: "输入你的名称并等待其他人加入。我们很快就开始！",
            vdpNameEntry_introStarted: "输入你的名称并加入你的同事。",
            vdpNameEntry_navDirections: "等待团队加入。",
            vdpValidationErrorMinLength: "请输入至少 {0} 个字符。",
            vdpValidationErrorMaxLength: "请输入不超过 {0} 个字符。",
            toolbar_stepOf: "第 {0} 节，页码 {1} - {2} ({3})",
            toolbar_intro: "第 {1} 节的步骤 {0}",
            toolbar_welcome: "欢迎序列",
            toolbar_exit: "退出序列",
            toolbar_reloadContent: "重新加载内容",
            toolbar_commentCreationButton: "评论 ({0})",
            toolbar_buildComments: "构建评论",
            toolbar_commentListButton: "查看评论 ({0})",
            toolbar_commentCreationTitle: "添加构建评论",
            toolbar_commentCreationDescription: "将保存评论并发送给构建团队。请在你的评论中包括你的姓名或姓名首字母。",
            toolbar_commentCreationCancelButton: "取消",
            toolbar_commentCreationSubmitButton: "发表评论",
            toolbar_commentCreationSubmitButtonDisabled: "发表评论……",
            toolbar_commentCreationPlaceholder: "输入评论……",
            toolbar_previewMode: "预览模式：",
            toolbar_aboutPreviewMode: "关于预览模式",
            toolbar_focusAreaSelector: "焦点区域选择器",
            toolbar_hotspotSelector: "热点选择器",
            toolbar_previewModeModalTitle: "预览模式",
            toolbar_previewModeModalFirstIntroduction:
                "你正在预览模式下查看本体验。此视图仅供 Root 内部审查，并可访问创作工具。",
            toolbar_previewModeModalSecondIntroduction: "这些选项仅在预览模式下可用，客户端或参与者不可见。",
            toolbar_previewModeModalReloadContent: "重新加载内容",
            toolbar_previewModeModalReloadContentDescription:
                "使用此按钮可使用 Contentful 中的最新更新刷新体验，而无需重新加载课程。",
            toolbar_previewModeModalComments: "评论",
            toolbar_previewModeModalCommentsDescription:
                "添加评论以提供反馈或记录需要进行的任何更改。评论将在 Contentful 中为构建团队捕获。",
            toolbar_previewModeModalMapCoordinates: "视图材料坐标",
            toolbar_previewModeModalMapCoordinatesDescription: "复制视图材料的坐标。",
            toolbar_previewModeModalHotspotSelector: "热点选择器",
            toolbar_previewModeModalHotspotSelectorDescription: "预览热点位置并复制坐标以在 Contentful 中使用。",
            coordinatePreview_coordinatesSelectorTitle: "坐标选择器",
            coordinatePreview_hotspotMode: "热点模式",
            coordinatePreview_hotspotModeCoordinatesCopiedToast: "热点坐标已复制到剪贴板。",
            coordinatePreview_focusAreaMode: "焦点区域模式",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast: "焦点区域坐标已复制到剪贴板。",
            coordinatePreview_directionsTitle: "坐标选择器说明",
            coordinatePreview_hotspotDirectionsLineOne: "在视图材料上选择一个点以创建热点。",
            coordinatePreview_hotspotDirectionsLineTwo: "选择每个热点上的“复制”按钮，将坐标复制到 Contentful。",
            coordinatePreview_focusAreaDirectionsLineOne: "选择并拖动以在视图材料上绘制矩形。",
            coordinatePreview_focusAreaDirectionsLineTwo: "将矩形放在步骤可见的区域上。",
            coordinatePreview_focusAreaDirectionsLineThree: "选择每个焦点区域上的“复制”按钮，将坐标复制到 Contentful。",
            coordinatePreview_reset: "重置",
            coordinatePreview_done: "已完成",
            navigatorDefaultInstructions_selectNextToContinue: "选择 ***下一步*** 继续。",
            navigatorDefaultInstructions_progressCheckLineOne: "我们已经到了第 {0} 节的结尾。",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace: "我们跟上了进度。让我们再接再厉！",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "我们领先于进度。让我们尽量不要急于浏览剩余的材料。",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "我们落后于进度。我们需要加快进程才能按时完成课程。",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav: "在下一页上，我们将介绍第 {0} 节的新引导员。",
            maintenanceWindow_heading: "计划在 {0}，{1} 的 {2} - {3} {4} 进行维护。",
            maintenanceWindow_body: "在此期间，应用程序可能不可用。",
            maintenanceError_overlap:
                "你选择的日期与 {0} 上的计划维护期冲突。从 {1} 到 {2} 每周进行一次维护。请确保开始日期和过期日期不与维护时段重叠。",
            progressCheck_sectionTitle: "第 {0} 节，共 {1} 节",
            progressCheck_paceCheckTitle: "课程速度检查",
            progressCheck_paceMessageOnPace: "再接再厉！",
            progressCheck_paceMessageAheadOfPace: "尽量不要急于浏览剩余的材料。",
            progressCheck_paceMessageBehindPace: "你需要加快进程才能按时完成课程。",
            progressCheck_navigatorChangeTitle: "引导员更改",
            progressCheck_navigatorChangeMessage: "感谢你的引导，{0}。将在下一页分配一个新的引导员。",
            vdpIntro_title: "引言",
            vdpIntro_subtitle: "在我们开始之前，先做一些简单的事情......",
            vdpIntro_joinTheTeam: "加入团队",
            vdpIntro_facilitatorRole: "课程导师角色",
            vdpIntro_navigatorRole: "引导员角色",
            vdpIntro_participantRole: "参与者角色",
            vdpIntro_iceBreaker: "认识团队",
            vdpIntro_exploreTheVisual: "视图材料",
            vdpIntro_skipShort: "跳过",
            vdpIntro_skipLong: "跳过引言",
            vdpIntro_facilitatorInstructionsOne: "欢迎！今天的课程与你可能参加过的其他课程或培训不同。",
            vdpIntro_facilitatorInstructionsTwo: "这里没有老师或培训师——你将与其他参与者相互学习并学习屏幕上的信息。",
            vdpIntro_facilitatorInstructionsThree: "我作为课程导师负责确保对话顺利并按时进行。",
            vdpIntro_navigatorInstructionsOne:
                "在接下来的几个屏幕中，我还将充当我们的引导员。引导员的角色是朗读每个屏幕上的引言文本，选择 ***下一步*** 按钮继续体验，并根据提示按照其他指示操作。",
            vdpIntro_navigatorInstructionsTwo: "将随机分配引导员角色，并且每隔几个屏幕更改一次。",
            vdpIntro_participantInstructionsOne:
                "你们都是参与者！你的角色是通过与小组分享你的观点来积极参与。你们将通过轮流发言，尊重他人的想法和意见并确保对话顺利进行，从而圆满地学完本课程。",
            vdpIntro_participantInstructionsTwo:
                "你可以使用左下角的 ***团队*** 按钮查看参加课程的成员以及具每个人员的角色。",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "接下来，我们每个人都将有机会在自我介绍时了解引导员角色是什么样的。",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "当你在引言文本上方看到 ***你是引导员*** 横幅时，轮到你朗读并按照说明进行操作了。大家有什么问题吗？",
            vdpIntro_iceBreakerInstructionsOne: "嗨，我是 {0}。",
            vdpIntro_iceBreakerInstructionsDefault: "关于我的一个事实是_______。",
            vdpIntro_mapInstructionsOne: "现在我们都已经见面了，让我们更多地了解我们今天的课程。",
            vdpIntro_mapInstructionsTwo:
                "我们的对话将集中在一张名为 *Learning Map*® 视图材料的图像上。这是一个含有寓意的图片，代表了我们将一起探索的业务问题的各个方面。",
            vdpIntro_iceBreakerIntroDirections: "请回答任何问题。",
            vdpEnd_title: "谢谢。",
            vdpEnd_body: "我们已经完成体验。感谢参与！我们希望你发现今天的讨论很有见地。",
            vdpEnd_surveyRequest: "请花几分钟时间分享你对我们所讨论的信息的想法和反应。你的反馈对我们的成功非常重要。",
            vdpEnd_surveyThankYou: "非常感谢你的回复。",
            vdpEnd_sessionComplete: "课程已完成。",
            vdpEnd_closeWindow: "你可以关闭此窗口。",
            privacyBanner_message:
                "保护你的个人数据对埃森哲非常重要，你通过此工具提供的任何个人数据都可能由埃森哲处理。埃森哲致力于根据适用的隐私法和我们的内部政策保护并处理你的个人数据。埃森哲处理的个人数据可能会在埃森哲的全球业务中以及在其实体和关联公司与第三方之间进行国际传输或访问。在使用此工具之前，埃森哲邀请你仔细阅读其隐私声明 [隐私声明]({1}) 和 [Cookie 政策]({0})，其中包括有关埃森哲为何以及如何处理你的个人数据的重要信息。",
            privacyBanner_dismiss: "同意",
            vdpActivity_New: "新活动",
            vdpActivity_submit: "提交",
            vdpActivity_submitted: "已提交",
            vdpActivity_editableCardWarning: "请在提交前填写所有空的卡片。",
            vdpActivity_matchingSuccess: "成功！",
            vdpActivity_matchingSuccessDescription: "所有卡片均已正确放置。",
            vdpActivity_matchingFail: "不太准确。",
            vdpActivity_matchingFailDescription: "将错误的卡片拖回左列。然后尝试将它们匹配到新位置。",
            vdpActivity_matchingReady: "准备就绪。",
            vdpActivity_matchingReadyDescription: "所有卡片均已放置。",
            vdpActivity_matchingCheckAnswers: "检查你的回答",
            vdpActivity_matchingContinue: "继续进行下一个活动。",
            vdpActivity_cardStackWithVisualAriaLabel: "含有视图材料的堆叠卡片",
            vdpActivity_cardStackWithVisualAriaDescription: "将卡片按正确的顺序排列",
            vdpActivity_revealCardAriaLabel: "显示卡片步骤",
            vdpActivity_revealCardAriaDescription: "选择卡片以显示卡片的内容",
            vdpActivity_dragAndDropAriaLabel: "拖放步骤",
            vdpActivity_dragAndDropAriaDescription: "将卡片拖放到正确的列中",
            vdpActivity_matchingStepAriaLabel: "匹配卡片步骤",
            vdpActivity_matchingStepAriaDescription: "将卡片移动到正确的位置以匹配它",
            vdpActivity_recallStepAriaLabel: "撤回步骤",
            vdpActivity_recallStepAriaDescription: "这是一个撤回步骤，请按照说明进行操作",
            vdpActivity_multipleChoiceAriaLabel: "多项选择步骤",
            vdpActivity_multipleChoiceAriaDescription: "从多项选择选项中进行选择。",
            vdpActivity_textEntryAriaLabel: "文本输入活动",
            vdpActivity_textEntryAriaDescription: "将你的回复提交到文本字段中",
            vdpActivity_singleAssetStepAriaLabel: "单一资源步骤",
            vdpActivity_singleAssetStepAriaDescription: "按照说明进行操作。",
            vdpActivity_comparisonStepAriaLabel: "比较步骤",
            vdpActivity_comparisonStepAriaDescription: "按照说明进行操作。",
            vdpActivity_FirstTitlePosition: "你在第一项",
            vdpActivity_LastTitlePosition: "你在最后一项",
            vdpActivity_videoStepAriaLabel: "视频步骤",
            vdpActivity_videoStepAriaDescription: "在此步骤中，将向参与者展示视频",
            vdpActivity_progressCheckAriaLabel: "进度检查",
            vdpActivity_progressCheckAriaDescription: "你领先于进度。尽量不要急于浏览剩余的材料",
            vdpActivity_exitStepAriaLabel: "退出步骤",
            vdpActivity_exitStepAriaDescription: "我们已经完成体验。你现在可以关闭此窗口",
            vdpHud_infoTeamButtonDescription:
                "选择此项可查看课程参与者列表，将引导员角色轮换为随机参与者，并更改参与者姓名。",
            vdpHud_zoomControlsDescription: "使用这些操作可放大和缩小视图材料。",
            vdpHud_zoomControlsZoomIn: "放大",
            vdpHud_zoomControlsZoomOut: "缩小",
            sidebar_scheduleButtonDescription: "查看摘要，估计每个章节预计花费的时间以及每个章节已花费的时间。",
            sidebar_previousButtonDescription: "选择此按钮可将小组的课程前进到上一页。",
            sidebar_nextButtonDescription: "选择此按钮可将小组的课程前进到下一页。",
            sidebar_navigatorBadgeDescription:
                "引导员负责将小组推进到课程中的后续步骤。使用“下一步”和“上一步”按钮导航小组。",
            sidebar_skipButtonDescription: "选择此按钮可跳过整个小组课程的欢迎部分。",
            genUrl_dataCollectionDefaultLabel: "数据采集默认值",
            genUrl_goToSessionRepoReport: "报告工具",
            genUrl_archiveButton: "存档",
            genUrl_resurrectButton: "恢复",
            genUrl_destroyButton: "销毁",
            vdpRenderer_refreshDescription: "你需要刷新页面才能尝试重新连接到课程。",
            vdpRenderer_setSelfActiveButtonDescription: "选择此按钮可保持活动状态并连接到当前课程的参与者。",
            vdpNameEntry_saveDescription: "提交你想让大家称呼你的名字并等待课程开始。",
            vdpNameEntry_continueDescription: "提交你想让大家称呼你的名字并加入正在进行的课程。",
            vdpNameEntry_smallScreenWarningTitle: "你能来到这里我们非常高兴！",
            vdpNameEntry_smallScreenWarningBody: "……为了获得最佳体验，请使用笔记本电脑或台式电脑登录。",
            vdpNameEntry_smallScreenWarningDismiss: "终止",
            privacyBanner_title: "数据隐私",
            privacyBanner_dismissDescription: "此操作将关闭详细说明网站隐私和 Cookie 政策的通知。",
            adminMessage_unauthorized: "你无权执行以下操作：{0}。",
            adminMessage_unauthorized_viewExp: "查看所有体验",
            sessionReport_submitButton: "生成报告",
            sessionReport_header_title: "{0} 课程报告工具",
            sessionReport_header_experience: "体验名称：{0}",
            sessionReport_header_errorTitle: "加载错误",
            sessionReport_header_errorSubtitle: "无法检索体验信息。",
            sessionReport_switch_unknown: "无法识别的报表结果类型",
            sessionReport_results_downloadResults: "下载结果",
            sessionReport_results_noRecordsTitle: "未找到记录",
            sessionReport_results_noRecordsSubTitle: "没有符合该标准的数据。",
            sessionReport_results_errorTitle: "生成报告时出错",
            sessionReport_basicInfo_startDate: "开始日期",
            sessionReport_basicInfo_endDate: "结束日期",
            sessionReport_basicInfo_useSeedData: "使用种子数据",
            sessionReport_basicInfo_useSeedData_help:
                "指示生成的报告是否应包含生成的随机样本数据。此选项仅用于测试目的。",
            sessionReport_switch_basicInfo: "基本课程信息",
            activityReport_basicInfo_startDate: "开始日期",
            activityReport_basicInfo_endDate: "结束日期",
            activityReport_basicInfo_useSeedData: "使用种子数据",
            activityReport_basicInfo_useSeedData_help:
                "指示生成的报告是否应包含生成的随机样本数据。此选项仅用于测试目的。",
            activityReport_switch_basicInfo: "基本活动信息",
            activityReport_basicInfoAggregated_startDate: "开始日期",
            activityReport_basicInfoAggregated_endDate: "结束日期",
            activityReport_basicInfoAggregated_useSeedData: "使用种子数据",
            activityReport_basicInfoAggregated_useSeedData_help:
                "指示生成的报告是否应包含生成的随机样本数据。此选项仅用于测试目的。",
            activityReport_switch_basicInfoAggregated: "汇总的基本活动信息",
            multipleChoiceReport_basicInfo_startDate: "开始日期",
            multipleChoiceReport_basicInfo_endDate: "结束日期",
            multipleChoiceReport_basicInfo_useSeedData: "使用种子数据",
            multipleChoiceReport_basicInfo_useSeedData_help:
                "指示生成的报告是否应包含生成的随机样本数据。此选项仅用于测试目的。",
            multipleChoiceReport_switch_basicInfo: "基本多项选择信息",
            multipleChoiceReport_basicInfo_activityId: "输入活动 ID",
            multipleChoiceReport_basicInfoAggregated_startDate: "开始日期",
            multipleChoiceReport_basicInfoAggregated_endDate: "结束日期",
            multipleChoiceReport_basicInfoAggregated_useSeedData: "使用种子数据",
            multipleChoiceReport_basicInfoAggregated_useSeedData_help:
                "指示生成的报告是否应包含生成的随机样本数据。此选项仅用于测试目的。",
            multipleChoiceReport_switch_basicInfoAggregated: "汇总的基本多项选择信息",
            multipleChoiceReport_basicInfoAggregated_activityId: "输入活动 ID",
            dragAndDropReport_basicInfo_useSeedData: "使用种子数据",
            dragAndDropReport_basicInfo_useSeedData_help:
                "指示生成的报告是否应包含生成的随机样本数据。此选项仅用于测试目的。",
            dragAndDropReport_basicInfo_startDate: "开始日期",
            dragAndDropReport_basicInfo_endDate: "结束日期",
            dragAndDropReport_switch_basicInfo: "基本拖放信息",
            dragAndDropReport_basicInfo_activityId: "输入活动 ID",
            dragAndDropReport_basicInfoAggregated_useSeedData: "使用种子数据",
            dragAndDropReport_basicInfoAggregated_useSeedData_help:
                "指示生成的报告是否应包含生成的随机样本数据。此选项仅用于测试目的。",
            dragAndDropReport_basicInfoAggregated_startDate: "开始日期",
            dragAndDropReport_basicInfoAggregated_endDate: "结束日期",
            dragAndDropReport_switch_basicInfoAggregated: "汇总的基本拖放信息",
            dragAndDropReport_basicInfoAggregated_activityId: "输入活动 ID",
            confirmDialog_confirmValuePrompt: "要确认此操作，请在下面的输入框中输入值 __*{0}*__。",
            confirmDialog_confirmButton: "确认",
            confirmDialog_closeButton: "结束",
            confirmDialog_error_checkBox: "你必须选中该框才能成功确认此操作。",
            confirmDialog_error_confirmValue: "你必须输入值 __*{0}*__ 才能成功确认此操作。",
            confirmDialog_scheduler_title: "确认存档",
            confirmDialog_scheduler_body:
                "你将要存档名称为 {0} 的体验。这将禁用对体验的访问，包括可能已为将来课程生成的任何课程链接。要确认此操作，请选中该框并输入体验的名称。",
            confirmDialog_scheduler_resurrectTitle: "确认恢复",
            confirmDialog_scheduler_resurrectBody:
                "你将恢复名称为 {0} 的体验。这将启用对体验的访问。为将来课程生成的任何链接都将生效。请注意，此操作完成后，你可能需要更新体验的过期日期。要确认此操作，请选中该框并输入体验的名称。",
            confirmDialog_scheduler_destroyTitle: "确认销毁",
            confirmDialog_scheduler_destroyBody:
                "你将销毁名称为 {0} 的体验。这是一个无法撤消的不可恢复的操作。在执行此操作之前，你必须确认以下内容：\r\n1.体验的所有项目均已下载并存档在 Project Land 中；\r\n2.已拍摄了一段浏览体验的视频并将其存储在 Project Land 中；\r\n3.已联系项目联系人（如果系统中的 Contentful 字段中列出了任何联系人），以验证是否可以接受删除；\r\n4.课程存储库数据（文本输入活动和通用存储库）已下载并存档在 Project Land 中。\r\n\r\n要确认此操作，请选中该框并输入体验的名称。",
            confirmDialog_dataCollectionDefault_title: "确认对数据采集默认值的更改",
            confirmDialog_dataCollectionDefault_body:
                "你已在 {1} 体验中选择 {0} 数据采集功能。这将影响将来为此体验生成的所有课程。你确定要为此体验 {0} 数据采集吗？",
            confirmDialog_dataCollectionDefault_body_enable: "启用",
            confirmDialog_dataCollectionDefault_body_disable: "禁用",
            confirmDialog_enable_NavigatorTitle: "是否确定？",
            confirmDialog_enable_NavigatorBody:
                "通过选择“确认”，你将通过大声朗读接下来几页的信息来启动小组课程。如果你不想成为第一个朗读者，请选择“关闭”",
            vdpNameEntry_NavigatorSelectionMessage: "需要一个引导员大声朗读说明以让我们开始课程",
            vdpNameEntry_NavigatorButtonMessage: "在此处选择以担任第一个朗读者",
        },
    },
    "nl-BE": {
        name: "Dutch (Belguim)",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "Activiteit is niet gevonden {0}",
            vdpActivity_New: "nieuwe activiteit",
            vdpActivity_closeModal: "Afsluiten",
            vdpActivity_correctExclamation: "Juist!",
            vdpActivity_correct: "Juist",
            vdpActivity_correctAnswerBadge: "Juist antwoord",
            vdpActivity_partiallyCorrect: "Deels juist!",
            vdpActivity_incorrect: "Fout",
            vdpActivity_notCorrect: "Niet juist",
            vdpActivity_continue: "Doorgaan",
            vdpActivity_checkAnswer: "Antwoord controleren",
            vdpActivity_submit: "Indienen",
            vdpActivity_submitted: "Ingediend",
            vdpActivity_ready: "Klaar.",
            vdpActivity_cardsPlaced: "Alle kaarten zijn geplaatst.",
            vdpActivity_cardsCorrectHeader: "Goed zo!",
            vdpActivity_cardsCorrect: "Alle kaarten zijn goed geplaatst.",
            vdpActivity_cardsCorrectContinue: "Doorgaan naar de volgende activiteit.",
            vdpActivity_cardsPartiallyCorrectHeader: "Niet helemaal juist.",
            vdpActivity_cardsPartiallyCorrect:
                "Bekijk de foute antwoorden en probeer ze op een nieuwe plaats te zetten.",
            vdpActivity_cardsCheckAnswers: "Controleer je antwoorden",
            vdpActivity_textEntryPlaceholder: "Je antwoord",
            vdpActivity_textEntryNavigatorPrompt: "De navigator, {0}, zal hieronder tekst invoeren",
            vdpActivity_textEntryPrompt: "Tekst hieronder invoeren:",
            vdpActivity_textEntrySubmit: "Indienen",
            vdpActivity_editableCardWarning: "Vul alle lege kaarten in voordat je ze indient.",
            vdpActivity_selectOne: "Selecteer één",
            vdpActivity_selectOneOrMany: "Selecteer één of meerdere",
            vdpActivity_defaultCurrentParticipantName: "Ik",
            vdpActivity_hotspotTooltip: "Selecteer de aandachtspunten voor meer informatie.",
            vdpActivity_matchingSuccess: "Goed zo!",
            vdpActivity_matchingSuccessDescription: "Alle kaarten zijn juist geplaatst.",
            vdpActivity_matchingFail: "Niet helemaal juist.",
            vdpActivity_matchingFailDescription:
                "Sleep de foute kaarten terug naar de linkerkolom. Probeer ze te matchen met een nieuwe plek.",
            vdpActivity_matchingReady: "Klaar.",
            vdpActivity_matchingReadyDescription: "Alle kaarten zijn geplaatst.",
            vdpActivity_matchingCheckAnswers: "Controleer je antwoorden",
            vdpActivity_matchingContinue: "Doorgaan naar de volgende activiteit.",
            vdpActivity_cardStackWithVisualAriaLabel: "Stapel kaarten met afbeeldingen",
            vdpActivity_cardStackWithVisualAriaDescription: "Plaats de kaarten in de juiste volgorde",
            vdpActivity_revealCardAriaLabel: "Stap Kaart onthullen",
            vdpActivity_revealCardAriaDescription: "Selecteer de kaart om ze te onthullen",
            vdpActivity_dragAndDropAriaLabel: "Stap Slepen en Neerzetten",
            vdpActivity_dragAndDropAriaDescription: "Sleep de kaarten naar de juiste kolommen",
            vdpActivity_matchingStepAriaLabel: "Stap Kaarten Matchen",
            vdpActivity_matchingStepAriaDescription: "Verplaats de kaart naar de juiste plek zodat er een match is",
            vdpActivity_recallStepAriaLabel: "Stap Terugroepen",
            vdpActivity_recallStepAriaDescription: "Dit is een terugroepstap, volg de instructies",
            vdpActivity_multipleChoiceAriaLabel: "Stap Meerkeuze",
            vdpActivity_multipleChoiceAriaDescription: "Kies uit de meerkeuzeopties.",
            vdpActivity_textEntryAriaLabel: "Activiteit Tekstinvoer",
            vdpActivity_textEntryAriaDescription: "Geef je antwoord in het tekstveld",
            vdpActivity_singleAssetStepAriaLabel: "Stap Eén Geheel",
            vdpActivity_singleAssetStepAriaDescription: "Volg de instructies.",
            vdpActivity_comparisonStepAriaLabel: "Stap Vergelijking",
            vdpActivity_comparisonStepAriaDescription: "Volg de instructies.",
            vdpActivity_FirstTitlePosition: "Je bent bij het eerste item",
            vdpActivity_LastTitlePosition: "Je bent bij het laatste item",
            vdpActivity_videoStepAriaLabel: "Stap Video",
            vdpActivity_videoStepAriaDescription: "In deze stap wordt er een video getoond aan de deelnemers",
            vdpActivity_progressCheckAriaLabel: "Voortgang controleren",
            vdpActivity_progressCheckAriaDescription:
                "Je ligt voor op schema. Neem het resterende materiaal niet te vlug door",
            vdpActivity_exitStepAriaLabel: "Stap Verlaten",
            vdpActivity_exitStepAriaDescription: "We hebben onze ervaring voltooid. Je mag het venster nu sluiten",
            vdpClipboard_copied: "Gekopieerd naar het klembord!",
            vdpClipboard_copy: "Kopiëren naar het klembord",
            vdpClipboard_error: "Kan niet naar het klembord kopiëren",
            vdpHud_hide: "Verbergen",
            vdpHud_show: "Weergeven",
            vdpHud_changeNavigatorTitle: "De navigator veranderen naar een nieuwe persoon.",
            vdpHud_changeNavigatorText1:
                'Je hebt "De navigator veranderen" geselecteerd. Hierdoor wordt willekeurig een andere navigator gekozen en worden alle interacties op deze pagina kort uitgeschakeld.',
            vdpHud_changeNavigatorText2: "Ben je zeker dat je van navigator wil veranderen?",
            vdpHud_close: "Afsluiten",
            vdpHud_accept: "De navigator veranderen",
            vdpHud_back: "Terug",
            vdpHud_next: "Volgende",
            vdpHud_endExperience: "Oefening beëindigen",
            vdpHud_defaultExitMsg:
                "We hebben onze ervaring voltooid. Bedankt voor je deelname! We hopen dat de bespreking van vandaag je veel nieuwe inzichten heeft gegeven. Je mag dit venster sluiten.",
            vdpHud_navigatorReminder: "Jij bent de navigator Lees a.u.b. het volgende:",
            vdpHud_timerNoTimeSpecified: "Geen tijd aangegeven voor dit deel",
            vdpHud_timerRemaining: "dat resteert in deel",
            vdpHud_timerLessThanOneMinute: "Minder dan één minuut",
            vdpHud_timerOver: "over",
            vdpHud_roleNone: "Niets toegewezen",
            vdpHud_infoTableSection: "Deel",
            vdpHud_infoTableRecommended: "Aanbevolen",
            vdpHud_infoTableActual: "Werkelijk",
            vdpHud_infoTableTotal: "Totaal",
            vdpHud_infoTableRemaining: "Resterend",
            vdpHud_infoTeamButton: "Team",
            vdpHud_infoTeamButtonDescription:
                "Selecteer dit item om een lijst met deelnemers aan de sessie te bekijken, de rol van navigator aan een willekeurige deelnemer te geven en je deelnemersnaam te wijzigen.",
            vdpHud_infoTeamParticipantJoining: "Gebruiker wordt toegevoegd...",
            vdpHud_navigator: "Navigator",
            vdpHud_facilitator: "Facilitator",
            vdpHud_facilitatorMenuMakeNavigator: "Navigator maken",
            vdpHud_facilitatorMenuRemoveFacilitator: "De functie van facilitator verwijderen",
            vdpHud_removeFacilitatorWarningConfirm: "Bevestigen",
            vdpHud_removeFacilitatorWarningCancel: "Annuleren",
            vdpHud_removeFacilitatorWarningMessage:
                "Je staat op het punt je functie van facilitator te verlaten. Ben je zeker dat je wil doorgaan?",
            vdpHud_progressOnPaceMessage: "Op schema",
            vdpHud_progressAheadOfPaceMessage: "Voor op schema",
            vdpHud_progressBehindPaceMessage: "Achter op schema",
            vdpHud_progressTimeRemaining: "{0} over in deel",
            vdpHud_progressStepsInSection: "Pagina {0} van {1}",
            vdpHud_zoomControlsTooltip: "Zoomknoppen voor de afbeelding",
            vdpHud_zoomControlsDescription: "Gebruik deze acties om in en uit te zoomen op de afbeelding.",
            vdpHud_zoomControlsZoomIn: "Inzoomen",
            vdpHud_zoomControlsZoomOut: "Uitzoomen",
            sidebar_scheduleButton: "Plannen",
            sidebar_scheduleButtonDescription:
                "Bekijk een overzicht met een schatting van de tijd die elk deel naar verwachting in beslag zal nemen en hoeveel tijd er aan elk deel is besteed.",
            sidebar_teamAssignNavigator: "Navigator toewijzen",
            sidebar_teamChooseRandomly: "Willekeurig kiezen",
            sidebar_teamChooseHelperText:
                "Kiest willekeurig een in aanmerking komende deelnemer voor de functie van navigator. Deelnemers zullen pas opnieuw gekozen worden, als iedereen al aan de beurt geweest is.",
            sidebar_editNameMenuItem: "Naam bewerken",
            sidebar_editNameSave: "Wijzigingen opslaan",
            sidebar_nameEditPlaceholder: "Voer je (roep)naam in...",
            sidebar_manageRolesMenuLabel: "Rollen beheren",
            sidebar_navigatorDescription:
                "Leest vragen, berichten en instructies luidop voor. Begeleidt de groep door de ervaring door op Volgende te klikken en de aanwijzingen op elk scherm op te volgen.",
            sidebar_facilitatorDescription:
                "Dient als gids tijdens de sessie om ervoor te zorgen dat de groep een vruchtbare discussie heeft en het gesprek gaande blijft.",
            sidebar_previousButton: "Vorige",
            sidebar_previousButtonDescription:
                "Selecteer deze knop om de sessie van de groep naar de vorige pagina te verplaatsen.",
            sidebar_nextButton: "Volgende",
            sidebar_nextButtonDescription:
                "Selecteer deze knop om de sessie van de groep naar de volgende pagina te verplaatsen.",
            sidebar_lockTooltip: "Enkel de navigator kan de pagina veranderen.",
            sidebar_navigatorBadge: "Jij bent de navigator",
            sidebar_navigatorBadgeDescription:
                "De navigator is verantwoordelijk voor de doorstroming naar de volgende stappen in de sessie. Gebruik de knoppen 'Volgende' en 'Vorige' om de groep door de sessie te leiden.",
            sidebar_facilitatorBadge: "Jij bent een facilitator",
            sidebar_introductionLabel: "Inleiding",
            sidebar_introductionInstruction: "Lees voor aan de groep:",
            sidebar_directionsLabel: "Richtlijnen",
            sidebar_nonNavigatorDirectionsLabel: "{0} doet het volgende:",
            sidebar_directionsInstruction: "Doe het volgende:",
            sidebar_facilitationNotesLabel: "Faciliterende opmerkingen",
            sidebar_skipButtonDescription:
                "Selecteer deze knop om het welkomstdeel over te slaan voor de sessie van de volledige groep.",
            genUrl_copyLink: "Link kopiëren",
            genUrl_sessionLinkDetails:
                "Deze sessielinks zullen verdwijnen wanneer je de pagina vernieuwt. Zorg ervoor dat je deze links downloadt of kopieert voordat je deze pagina verlaat.",
            genUrl_exportAsCsv: "Exporteren als CSV",
            genUrl_copyTable: "Tabel kopiëren",
            genUrl_linkExpiration: "Vervaldatum van de link",
            genUrl_link: "Link",
            genUrl_title: "{0} Sessielinkgenerator",
            genUrl_experience: "Oefening: {0}",
            genUrl_description:
                "Via dit portaal genereer je sessielinks voor deze ervaring. Om sessielinks te genereren, voer je het aantal sessies in dat je wilt genereren, kies je een datum waarop de sessies moeten verlopen en selecteer je Sessies genereren. Zodra deze sessielinks gegenereerd zijn, kopieer en plak je ze in je favoriete teksteditor of spreadsheetprogramma. Zodra je de pagina vernieuwt, zullen alle links verdwijnen.",
            genUrl_numSessions: "Aantal sessies:",
            genUrl_sessionsExpirationLabel: "Sessielinks vervallen op:",
            genUrl_sessionsOpenAtLabel: "Sessielinks zijn geldig vanaf:",
            genUrl_numSessionsInputLabel: "Aantal sessies",
            genUrl_sessionLanguageLabel: "Taal van de sessie",
            genUrl_numSessionsInputTypeError: "Moet een cijfer zijn",
            genUrl_closesOnError: "Kies een datum in de toekomst",
            genUrl_experienceClosesOnError: "Kies een datum vóór {0}",
            genUrl_notifSuccessSingle: "{0} link met succes gegenereerd",
            genUrl_notifSuccessMultiple: "{0} links met succes gegenereerd",
            genUrl_notifErrorGenerating:
                "Er kunnen geen sessies gegenereerd worden. Probeer het opnieuw of neem contact op met de beheerder.",
            genUrl_notifErrorProcessing: "Kan niet verwerkt worden. Neem contact op met een beheerder.",
            genUrl_sessionsExpireToday: "De sessies zullen vandaag vervallen",
            genUrl_sessionsExpireInOneDay: "De sessies zullen vervallen in {0} dag",
            genUrl_sessionsExpireInDays: "De sessies zullen vervallen in {0} dagen",
            genUrl_sessionsOpenToday: "De sessies zullen vandaag geopend worden",
            genUrl_sessionsOpenOneDay: "De sessies zullen openen in {0} dag",
            genUrl_sessionsOpenDays: "De sessies zullen openen in {0} dagen",
            genUrl_generate: "Sessies genereren",
            genUrl_schedulingLinkExpired: "Planningslink is vervallen.",
            genUrl_error: "Fout",
            genUrl_dataCollectionLabel: "Gegevensverzameling",
            genUrl_dataCollectionDefaultLabel: "Standaard gegevensverzameling",
            genUrl_emailContact: "Contact e-mailen",
            genUrl_goToSessionRepoReport: "Meldingstool",
            genUrl_archiveButton: "Archiveren",
            genUrl_resurrectButton: "Opnieuw opstarten",
            genUrl_destroyButton: "Vernietigen",
            vdpLoading_waitForContent: "Even geduld terwijl je inhoud laadt...",
            vdpNotFound_title: "Virtueel dialoogplatform",
            vdpNotFound_message: "De pagina die je zoekt is niet gevonden.",
            vdpRenderer_refresh: "Vernieuwen",
            vdpRenderer_refreshDescription:
                "Je moet de pagina vernieuwen om opnieuw verbinding te maken met de sessie.",
            vdpRenderer_sessionDisconnectedHeader: "De verbinding is verbroken.",
            vpdRenderer_inactiveDisconnectedBody:
                "De verbinding is verbroken wegens inactiviteit. Selecteer de knop hieronder om te proberen opnieuw verbinding te maken.",
            vdpRenderer_inactiveHeader: "Je bent inactief.",
            vdpRenderer_inactiveBody:
                "Je was te lang inactief. Selecteer de knop hieronder om actief te blijven of je wordt uit de sessie verwijderd zodra de timer afloopt.",
            vdpRenderer_setSelfActiveButton: "Jezelf als actief instellen",
            vdpRenderer_setSelfActiveButtonDescription:
                "Selecteer deze knop om actief te blijven en de verbinding als deelnemer aan de huidige sessie te behouden.",
            vdpRenderer_sessionDisconnected: "Proberen opnieuw verbinding te maken",
            vdpRenderer_sessionDisconnectedReload: "Laad opnieuw",
            vdpRenderer_endOfSession: "Einde van de sessie",
            vdpRenderer_invalidMapUrl:
                "Er is een ongeldige URL verstrekt voor de afbeelding. Neem contact op met Root voor hulp.",
            vdpRenderer_unexpectedError:
                "Er is een onverwachte systeemfout opgetreden. Hier zijn de technische gegevens:",
            vdpRenderer_unexpectedErrorMsg: "Er is een onverwachte fout opgetreden: onbekende sessiestatus {0}",
            vdpRenderer_defaultEndingMessage: "Je mag nu je venster afsluiten en de audio loskoppelen.",
            vdpErrorModal_title: "Pagina kan niet geladen worden",
            vdpErrorModal_message:
                "Er is iets fout gegaan! Neem contact met ons op voor hulp via: vmap.help@rootinc.com",
            vdpErrorModal_body:
                "De fout kan verholpen worden door deze pagina opnieuw te laden of het onderstaande te selecteren:",
            vdpErrorModal_button: "Deze ervaring opnieuw laden.",
            vdpNameEntry_welcomeText: "Welkom",
            vdpNameEntry_defaultWelcomeMessage:
                "Deze virtuele dialoogsessie is volledig interactief. Bereid je alvast voor om informatie te verkennen en je gedachten en mening te delen met je collega's.",
            vdpNameEntry_inputName: "Hoe wil je genoemd worden?",
            vdpNameEntry_inputNamePlaceholder: "Voer je (roep)naam in:",
            vdpNameEntry_inputDepartment: "In welke afdeling werk je?",
            vdpNameEntry_inputEmail: "Wat is je e-mailadres?",
            vdpNameEntry_inputTeam: "In welk team zit je?",
            vdpNameEntry_saveDescription: "Voer je (roep)naam in en wacht totdat de sessie start.",
            vdpNameEntry_continueDescription: "Voer je (roep)naam in en neem deel aan de lopende sessie.",
            vdpNameEntry_identifiersName: "Voer je naam in",
            vdpNameEntry_identifiersDuplicateName:
                "Het lijkt erop dat er al een {0} aangesloten is vandaag. Wil je een andere naam invoeren, zodat we jullie uit elkaar kunnen houden? Je kan misschien een initiaal toevoegen aan je familienaam of een roepnaam invoeren.",
            vdpNameEntry_identifiersDepartment: "Voer de naam van je afdeling in",
            vdpNameEntry_identifiersEmail: "Voer je e-mailadres in",
            vdpNameEntry_identifiersInvalidEmailFormat: "Ongeldig e-mailformaat",
            vdpNameEntry_identifiersTeam: "Voer een teamnaam in",
            vdpNameEntry_welcomeHeader: "Aangename kennismaking, {0}!",
            vdpNameEntry_welcomeAdvance: "Maak kennis met je team",
            vdpNameEntry_claimFacilitatorTitle: "De rol van facilitator opeisen",
            vdpNameEntry_facilitatorClaimed: "De functie van facilitator is opgeëist",
            vdpNameEntry_claimFacilitatorButton: "Functie van facilitator opeisen",
            vdpNameEntry_claimFacilitatorModalCancel: "Annuleren",
            vdpNameEntry_claimFacilitatorModalParagraphOne:
                "Als je de facilitator van deze sessie bent, klik dan hieronder op Functie van facilitator opeisen.",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "Als facilitator heb je de controle over de eerste pagina's, zodat we goed van start kunnen gaan. Vervolgens zal het systeem automatisch een nieuwe navigator selecteren.",
            vdpNameEntry_claimFacilitatorModalParagraphThree:
                "Als je voor deze sessie bent uitgenodigd als deelnemer, selecteer dan Annuleren.",
            vdpNameEntry_smallScreenWarningTitle: "We zijn blij dat je er bent!",
            vdpNameEntry_smallScreenWarningBody: "... Voor de beste ervaring log je in vanaf een laptop of desktop.",
            vdpNameEntry_smallScreenWarningDismiss: "Afwijzen",
            vdpNameEntry_numParticipantsInSession: "Er zijn {0} deelnemers aan het wachten...",
            vdpNameEntry_oneParticipantsInSession: "Er is 1 deelnemer aan het wachten...",
            vdpNameEntry_save: "Opslaan",
            vdpNameEntry_continue: "Doorgaan",
            vdpNameEntry_thanks: "Bedankt, {0}. We beginnen binnenkort!",
            vdpNameEntry_intro: "Voer je naam in en wacht op andere deelnemers. We beginnen binnenkort!",
            vdpNameEntry_introStarted: "Voer je naam in en sluit aan bij je collega's.",
            vdpNameEntry_navDirections: "Wacht totdat het team meedoet.",
            vdpValidationErrorMinLength: "Voer ten minste {0} tekens in.",
            vdpValidationErrorMaxLength: "Voer niet meer dan {0} tekens in.",
            toolbar_stepOf: "Deel {0}, Pagina {1} - {2} ({3})",
            toolbar_intro: "Stap {0} van Deel {1}",
            toolbar_welcome: "Verwelkoming",
            toolbar_exit: "Verwelkoming verlaten",
            toolbar_reloadContent: "Inhoud opnieuw laden",
            toolbar_commentCreationButton: "Commentaar ({0})",
            toolbar_buildComments: "Bouwcommentaren",
            toolbar_commentListButton: "Commentaren ({0}) bekijken",
            toolbar_commentCreationTitle: "Bouwcommentaren toevoegen",
            toolbar_commentCreationDescription:
                "De commentaren worden opgeslagen en naar het bouwteam gestuurd. Voeg je naam of initialen toe aan je commentaar.",
            toolbar_commentCreationCancelButton: "Annuleren",
            toolbar_commentCreationSubmitButton: "Commentaar plaatsen",
            toolbar_commentCreationSubmitButtonDisabled: "Commentaar plaatsen...",
            toolbar_commentCreationPlaceholder: "Commentaar invoeren...",
            toolbar_previewMode: "Voorbeeldmodus:",
            toolbar_aboutPreviewMode: "Over de voorbeeldmodus",
            toolbar_focusAreaSelector: "Selectie focusgebied",
            toolbar_hotspotSelector: "Selectie aandachtspunt",
            toolbar_previewModeModalTitle: "Voorbeeldmodus",
            toolbar_previewModeModalFirstIntroduction:
                "Je bekijkt deze ervaring in de voorbeeldmodus. Deze weergave is alleen bedoeld voor interne beoordeling door Root en geeft toegang tot de auteursinstrumenten.",
            toolbar_previewModeModalSecondIntroduction:
                "Deze opties zijn enkel beschikbaar in de voorbeeldmodus en zullen niet zichtbaar zijn voor de cliënt of deelnemers.",
            toolbar_previewModeModalReloadContent: "Inhoud opnieuw laden",
            toolbar_previewModeModalReloadContentDescription:
                "Gebruik deze knop om de ervaring te vernieuwen met de recentste updates van Contentful zonder dat je de sessie opnieuw hoeft te laden.",
            toolbar_previewModeModalComments: "Commentaren",
            toolbar_previewModeModalCommentsDescription:
                "Voeg commentaar toe om feedback te geven of gewenste wijzigingen te vermelden. De commentaren worden opgeslagen in Contentful voor het bouwteam.",
            toolbar_previewModeModalMapCoordinates: "Coördinaten van de afbeelding",
            toolbar_previewModeModalMapCoordinatesDescription: "Kopieer de coördinaten van de afbeelding.",
            toolbar_previewModeModalHotspotSelector: "Selectie aandachtspunt",
            toolbar_previewModeModalHotspotSelectorDescription:
                "Bekijk de locaties van de aandachtspunten en kopieer de coördinaten voor gebruik in Contentful.",
            coordinatePreview_coordinatesSelectorTitle: "Selectie coördinaten",
            coordinatePreview_hotspotMode: "Aandachtspuntmodus",
            coordinatePreview_hotspotModeCoordinatesCopiedToast:
                "De coördinaten van het aandachtspunt zijn gekopieerd naar je klembord.",
            coordinatePreview_focusAreaMode: "Focusgebiedmodus",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast:
                "De coördinaten van het focusgebied zijn gekopieerd naar je klembord.",
            coordinatePreview_directionsTitle: "Richtlijnen voor de coördinatenselectie",
            coordinatePreview_hotspotDirectionsLineOne:
                "Selecteer een punt op de afbeelding om een aandachtspunt te creëren.",
            coordinatePreview_hotspotDirectionsLineTwo:
                "Klik op de knop Kopiëren op elk aandachtspunt om de coördinaten te kopiëren naar Contentful.",
            coordinatePreview_focusAreaDirectionsLineOne: "Klik en sleep om een rechthoek te tekenen op de afbeelding.",
            coordinatePreview_focusAreaDirectionsLineTwo:
                "Plaats de rechthoek over het gebied dat zichtbaar zal zijn voor een stap.",
            coordinatePreview_focusAreaDirectionsLineThree:
                "Klik op de knop Kopiëren op elk focusgebied om de coördinaten te kopiëren naar Contentful.",
            coordinatePreview_reset: "Resetten",
            coordinatePreview_done: "Klaar",
            navigatorDefaultInstructions_selectNextToContinue: "Selecteer ***Volgende*** om verder te gaan.",
            navigatorDefaultInstructions_progressCheckLineOne: "We hebben het einde van deel {0} bereikt.",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace: "We zitten op schema. Laten we zo verdergaan!",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "We zitten voor op schema. Laten we proberen om het resterende materiaal niet te vlug door te nemen.",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "We zitten achter op schema. We zullen vlugger moeten werken om de sessie op tijd klaar te krijgen.",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav:
                "Op de volgende pagina duiden we een nieuwe navigator aan voor deel {0}.",
            maintenanceWindow_heading: "Er is onderhoud gepland op {0}, {1} van {2} - {3} {4}.",
            maintenanceWindow_body: "Het is mogelijk dat de toepassing tijdens die periode niet beschikbaar is.",
            maintenanceError_overlap:
                "De datums die je hebt gekozen, vallen op een geplande onderhoudsperiode op {0}. Elke week wordt er van {1} tot {2} onderhoud uitgevoerd. Zorg ervoor dat de start- en vervaldatums niet overlappen met een onderhoudsperiode.",
            progressCheck_sectionTitle: "Deel {0} van {1}",
            progressCheck_paceCheckTitle: "Controle sessietempo",
            progressCheck_paceMessageOnPace: "Doe zo verder!",
            progressCheck_paceMessageAheadOfPace: "Neem het resterende materiaal niet te vlug door.",
            progressCheck_paceMessageBehindPace: "Je zal vlugger moeten werken om de sessie op tijd klaar te krijgen.",
            progressCheck_navigatorChangeTitle: "Wissel van navigator",
            progressCheck_navigatorChangeMessage:
                "Bedankt om te leiden, {0}. Op de volgende pagina zal er een nieuwe navigator aangewezen worden.",
            vdpIntro_title: "Inleiding",
            vdpIntro_subtitle: "Nog even snel een paar punten voordat we beginnen...",
            vdpIntro_joinTheTeam: "Kom bij het team",
            vdpIntro_facilitatorRole: "Functie van facilitator",
            vdpIntro_navigatorRole: "Functie van navigator",
            vdpIntro_participantRole: "Functie van deelnemer",
            vdpIntro_iceBreaker: "Maak kennis met het team",
            vdpIntro_exploreTheVisual: "De afbeelding",
            vdpIntro_skipShort: "Overslaan",
            vdpIntro_skipLong: "Inleiding overslaan",
            vdpIntro_facilitatorInstructionsOne:
                "Welkom! De sessie van vandaag is anders dan andere cursussen of trainingen die je mogelijk al hebt gevolgd.",
            vdpIntro_facilitatorInstructionsTwo:
                "Er is geen leerkracht of trainer – jullie zullen van elkaar leren en van de informatie op het scherm.",
            vdpIntro_facilitatorInstructionsThree:
                "Het is mijn rol als facilitator om het gesprek gaande te houden en de tijd in het oog te houden.",
            vdpIntro_navigatorInstructionsOne:
                "Voor de volgende paar schermen zal ik ook jullie navigator zijn. De navigator leest de inleidingstekst op elk scherm, selecteert de knop ***Volgende*** om door de ervaring te navigeren en volgt andere richtlijnen als hij/zij daarom gevraagd wordt.",
            vdpIntro_navigatorInstructionsTwo:
                "De functie van navigator wordt willekeurig toegewezen en verandert om de paar schermen.",
            vdpIntro_participantInstructionsOne:
                "Jullie zijn allemaal deelnemers! Jullie moeten actief deelnemen door je meningen te delen met de groep. Je draagt bij aan het vlotte verloop van de sessie door om de beurt te spreken, elkaars gedachten en meningen te respecteren en het gesprek gaande te houden.",
            vdpIntro_participantInstructionsTwo:
                "Met de knop ***Team*** in de linkeronderhoek kan je zien wie er deelneemt aan deze sessie en wie welke functie heeft.",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "Wanneer we onszelf voorstellen, krijgen we de kans om te zien wat de functie van navigator inhoudt.",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "Als je de banner ***Jij bent de navigator*** boven de inleidingstekst ziet, is het aan jou om te lezen en de richtlijnen te volgen. Heeft er iemand vragen?",
            vdpIntro_iceBreakerInstructionsOne: "Hallo, ik ben {0}.",
            vdpIntro_iceBreakerInstructionsDefault: "Een weetje over mij is _______.",
            vdpIntro_mapInstructionsOne:
                "Nu we elkaar hebben leren kennen, wil ik iets meer vertellen over de sessie van vandaag.",
            vdpIntro_mapInstructionsTwo:
                "Ons gesprek wordt gebaseerd op een afbeelding die *Learning Map*® wordt genoemd. Het is een geïllustreerde metafoor die de aspecten van ons bedrijf vertegenwoordigt en die we samen zullen verkennen. ",
            vdpIntro_iceBreakerIntroDirections: "Beantwoord eventuele vragen.",
            vdpEnd_title: "Bedankt.",
            vdpEnd_body:
                "We hebben onze ervaring voltooid. Bedankt voor je deelname! We hopen dat de bespreking van vandaag je veel nieuwe inzichten heeft gegeven.",
            vdpEnd_surveyRequest:
                "Neem even de tijd om je gedachten en reacties te delen over de informatie die we besproken hebben. Je feedback is van groot belang.",
            vdpEnd_surveyThankYou: "Bedankt voor je antwoord.",
            vdpEnd_sessionComplete: "De sessie is afgerond.",
            vdpEnd_closeWindow: "Je mag dit venster sluiten.",
            privacyBanner_title: "Gegevensprivacy",
            privacyBanner_message:
                "De bescherming van je persoonlijke gegevens is van groot belang voor Accenture en alle persoonlijke gegevens die je via deze tool verstrekt kunnen door Accenture worden verwerkt. Accenture doet er alles aan om je persoonsgegevens veilig te bewaren en te verwerken in overeenstemming met de relevante privacywetgeving en onze interne beleidslijnen. De persoonsgegevens die Accenture verwerkt, kunnen internationaal worden overgedragen of beschikbaar zijn binnen de internationale activiteiten van Accenture, evenals tussen de dochterondernemingen van Accenture en derden. Voordat je deze tool gebruikt, nodigt Accenture je uit om zorgvuldig de privacyverklaring [Privacyverklaring]({1}) en het [Cookiebeleid]({0}) te lezen, waarin je belangrijke informatie vindt over waarom en hoe Accenture je persoonsgegevens verwerkt.",
            privacyBanner_dismiss: "Akkoord",
            privacyBanner_dismissDescription:
                "Met deze actie wordt de melding over het privacy- en cookiebeleid van de site verwijderd.",
            adminMessage_unauthorized: "Je hebt geen toestemming om de volgende actie uit te voeren: {0}.",
            adminMessage_unauthorized_viewExp: "alle ervaringen bekijken",
            sessionReport_submitButton: "Rapport genereren",
            sessionReport_header_title: "{0} Sessierapportagetool",
            sessionReport_header_experience: "Naam van de ervaring: {0}",
            sessionReport_header_errorTitle: "Fout bij het laden",
            sessionReport_header_errorSubtitle: "Er kon geen informatie over de ervaring worden opgehaald.",
            sessionReport_switch_unknown: "Niet herkend type rapportresultaat",
            sessionReport_results_downloadResults: "Resultaten downloaden",
            sessionReport_results_noRecordsTitle: "Geen dossiers gevonden",
            sessionReport_results_noRecordsSubTitle: "Er zijn geen gegevens die aan dat criterium voldoen.",
            sessionReport_results_errorTitle: "Fout bij het genereren van rapport",
            sessionReport_basicInfo_startDate: "Startdatum",
            sessionReport_basicInfo_endDate: "Einddatum",
            sessionReport_basicInfo_useSeedData: "Seed-gegevens gebruiken",
            sessionReport_basicInfo_useSeedData_help:
                "Geeft aan of het gegenereerde rapport willekeurige voorbeeldgegevens moet bevatten. Deze optie is alleen bedoeld voor testdoeleinden.",
            sessionReport_switch_basicInfo: "Basisinfo over de sessie",
            activityReport_basicInfo_startDate: "Startdatum",
            activityReport_basicInfo_endDate: "Einddatum",
            activityReport_basicInfo_useSeedData: "Seed-gegevens gebruiken",
            activityReport_basicInfo_useSeedData_help:
                "Geeft aan of het gegenereerde rapport willekeurige voorbeeldgegevens moet bevatten. Deze optie is alleen bedoeld voor testdoeleinden.",
            activityReport_switch_basicInfo: "Basisinfo over de activiteit",
            activityReport_basicInfoAggregated_startDate: "Startdatum",
            activityReport_basicInfoAggregated_endDate: "Einddatum",
            activityReport_basicInfoAggregated_useSeedData: "Seed-gegevens gebruiken",
            activityReport_basicInfoAggregated_useSeedData_help:
                "Geeft aan of het gegenereerde rapport willekeurige voorbeeldgegevens moet bevatten. Deze optie is alleen bedoeld voor testdoeleinden.",
            activityReport_switch_basicInfoAggregated: "Geaggregeerde basisinfo over de activiteit",
            multipleChoiceReport_basicInfo_startDate: "Startdatum",
            multipleChoiceReport_basicInfo_endDate: "Einddatum",
            multipleChoiceReport_basicInfo_useSeedData: "Seed-gegevens gebruiken",
            multipleChoiceReport_basicInfo_useSeedData_help:
                "Geeft aan of het gegenereerde rapport willekeurige voorbeeldgegevens moet bevatten. Deze optie is alleen bedoeld voor testdoeleinden.",
            multipleChoiceReport_switch_basicInfo: "Basisinfo over de meerkeuze",
            multipleChoiceReport_basicInfo_activityId: "Activiteit-ID invoeren",
            multipleChoiceReport_basicInfoAggregated_startDate: "Startdatum",
            multipleChoiceReport_basicInfoAggregated_endDate: "Einddatum",
            multipleChoiceReport_basicInfoAggregated_useSeedData: "Seed-gegevens gebruiken",
            multipleChoiceReport_basicInfoAggregated_useSeedData_help:
                "Geeft aan of het gegenereerde rapport willekeurige voorbeeldgegevens moet bevatten. Deze optie is alleen bedoeld voor testdoeleinden.",
            multipleChoiceReport_switch_basicInfoAggregated: "Geaggregeerde basisinfo over de meerkeuze",
            multipleChoiceReport_basicInfoAggregated_activityId: "Activiteit-ID invoeren",
            dragAndDropReport_basicInfo_useSeedData: "Seed-gegevens gebruiken",
            dragAndDropReport_basicInfo_useSeedData_help:
                "Geeft aan of het gegenereerde rapport willekeurige voorbeeldgegevens moet bevatten. Deze optie is alleen bedoeld voor testdoeleinden.",
            dragAndDropReport_basicInfo_startDate: "Startdatum",
            dragAndDropReport_basicInfo_endDate: "Einddatum",
            dragAndDropReport_switch_basicInfo: "Basisinfo over slepen & neerzetten",
            dragAndDropReport_basicInfo_activityId: "Activiteit-ID invoeren",
            dragAndDropReport_basicInfoAggregated_useSeedData: "Seed-gegevens gebruiken",
            dragAndDropReport_basicInfoAggregated_useSeedData_help:
                "Geeft aan of het gegenereerde rapport willekeurige voorbeeldgegevens moet bevatten. Deze optie is alleen bedoeld voor testdoeleinden.",
            dragAndDropReport_basicInfoAggregated_startDate: "Startdatum",
            dragAndDropReport_basicInfoAggregated_endDate: "Einddatum",
            dragAndDropReport_switch_basicInfoAggregated: "Geaggregeerde basisinfo over slepen & neerzetten",
            dragAndDropReport_basicInfoAggregated_activityId: "Activiteit-ID invoeren",
            confirmDialog_confirmValuePrompt:
                "Om deze actie te bevestigen, voer je de waarde __*{0}*__ in het onderstaande veld in.",
            confirmDialog_confirmButton: "Bevestigen",
            confirmDialog_closeButton: "Afsluiten",
            confirmDialog_error_checkBox: "Je moet het vakje aanvinken om deze actie te bevestigen.",
            confirmDialog_error_confirmValue: "Je moet de waarde __*{0}*__ invoeren om deze actie te bevestigen.",
            confirmDialog_scheduler_title: "Archiveren bevestigen",
            confirmDialog_scheduler_body:
                "Je staat op het punt om de ervaring {0} te archiveren. Hiermee wordt de toegang tot de ervaring uitgeschakeld, inclusief eventuele sessielinks die gegenereerd werden voor toekomstige sessies. Vink het vakje aan en voer de naam van de ervaring in om deze actie te bevestigen.",
            confirmDialog_scheduler_resurrectTitle: "Opnieuw opstarten bevestigen",
            confirmDialog_scheduler_resurrectBody:
                "Je staat op het punt om de ervaring {0} opnieuw op te starten. Hierdoor wordt de toegang tot de ervaring geactiveerd. Alle gegenereerde links voor toekomstige sessies zullen geldig worden. Houd er rekening mee dat je mogelijk de vervaldatum voor de ervaring moet bijwerken nadat deze actie is voltooid. Vink het vakje aan en voer de naam van de ervaring in om deze actie te bevestigen.",
            confirmDialog_scheduler_destroyTitle: "Vernietigen bevestigen",
            confirmDialog_scheduler_destroyBody:
                "Je staat op het punt om de ervaring {0} te vernietigen. Dit is een onherroepelijke actie die niet ongedaan gemaakt kan worden. Voordat je deze actie uitvoert, moet je het volgende bevestigen: \r\n1. Alle onderdelen van de ervaring zijn gedownload en gearchiveerd in projectland;\r\n2. Er is een video opgenomen van de navigatie en die video is opgeslagen in projectland;\r\n3. Er is contact opgenomen met de contactpersonen van het project (indien die voorkomen in het Contentful-veld in ons systeem) om te controleren of de verwijdering is toegestaan;\r\n4. De archiefgegevens van de sessie - tekstinvoeractiviteiten en de algemene archiefgegevens - zijn gedownload en gearchiveerd in projectland.;\r\n\r\nVink het vakje aan en voer de naam van de ervaring in om deze actie te bevestigen.",
            confirmDialog_dataCollectionDefault_title: "Verandering naar standaard gegevensverzameling bevestigen",
            confirmDialog_dataCollectionDefault_body:
                "Je hebt ervoor gekozen om de gegevensverzamelingsfunctie op de ervaring {1} te {0}. Dit heeft gevolgen voor alle sessies die in de toekomst worden gegenereerd voor deze ervaring. Ben je zeker dat je de gegevensverzameling voor deze ervaring wilt {0}?",
            confirmDialog_dataCollectionDefault_body_enable: "activeren",
            confirmDialog_dataCollectionDefault_body_disable: "deactiveren",
            confirmDialog_enable_NavigatorTitle: "Weet je het zeker?",
            confirmDialog_enable_NavigatorBody:
                "Als je 'Bevestigen' kiest, ga je met de groep aan de slag door de informatie op de volgende pagina's hardop voor te lezen. Selecteer 'Afsluiten' als je niet als eerste wilt voorlezen",
            vdpNameEntry_NavigatorSelectionMessage:
                "We hebben een navigator nodig om de instructies voor te lezen, zodat we aan de slag kunnen.",
            vdpNameEntry_NavigatorButtonMessage: "Klik hier om als eerste voor te lezen",
        },
    },
    "th-TH": {
        name: "Thai",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "ค้นหากิจกรรม {0} ไม่พบ",
            vdpActivity_New: "กิจกรรมใหม่",
            vdpActivity_closeModal: "ปิด",
            vdpActivity_correctExclamation: "ถูกต้อง!",
            vdpActivity_correct: "ถูกต้อง",
            vdpActivity_correctAnswerBadge: "คำตอบถูกต้อง",
            vdpActivity_partiallyCorrect: "ถูกต้องเป็นบางส่วน!",
            vdpActivity_incorrect: "ไม่ถูกต้อง",
            vdpActivity_notCorrect: "ผิด",
            vdpActivity_continue: "ดำเนินการต่อ",
            vdpActivity_checkAnswer: "ตรวจคำตอบ",
            vdpActivity_submit: "ส่ง",
            vdpActivity_submitted: "ส่งแล้ว",
            vdpActivity_ready: "พร้อม",
            vdpActivity_cardsPlaced: "วางการ์ดครบทุกใบแล้ว",
            vdpActivity_cardsCorrectHeader: "สำเร็จ!",
            vdpActivity_cardsCorrect: "วางการ์ดครบทุกใบเรียบร้อยแล้ว",
            vdpActivity_cardsCorrectContinue: "ดำเนินการต่อไปยังกิจกรรมถัดไป",
            vdpActivity_cardsPartiallyCorrectHeader: "ยังไม่ถูกเสียทีเดียว",
            vdpActivity_cardsPartiallyCorrect: "ตรวจสอบคำตอบที่ไม่ถูกต้องแล้วลองย้ายคำตอบเหล่านั้นไปยังที่ใหม่",
            vdpActivity_cardsCheckAnswers: "ตรวจคำตอบของคุณ",
            vdpActivity_textEntryPlaceholder: "คำตอบของคุณ",
            vdpActivity_textEntryNavigatorPrompt: "ผู้นำทาง {0} จะป้อนข้อความด้านล่าง",
            vdpActivity_textEntryPrompt: "ป้อนข้อความด้านล่าง:",
            vdpActivity_textEntrySubmit: "ส่ง",
            vdpActivity_editableCardWarning: "กรุณากรอกข้อมูลในการ์ดที่ว่างทั้งหมดก่อนส่ง",
            vdpActivity_selectOne: "เลือกหนึ่งรายการ",
            vdpActivity_selectOneOrMany: "เลือกหนึ่งหรือหลายรายการ",
            vdpActivity_defaultCurrentParticipantName: "ฉัน",
            vdpActivity_hotspotTooltip: "เลือกจุดที่น่าสนใจเพื่อเรียนรู้เพิ่มเติม",
            vdpActivity_matchingSuccess: "สำเร็จ!",
            vdpActivity_matchingSuccessDescription: "วางการ์ดครบทุกใบอย่างถูกต้องแล้ว",
            vdpActivity_matchingFail: "ยังไม่ถูกเสียทีเดียว",
            vdpActivity_matchingFailDescription:
                "ลากการ์ดที่ไม่ถูกต้องกลับไปยังคอลัมน์ด้านซ้าย แล้วลองจับคู่อีกครั้งในตำแหน่งใหม่",
            vdpActivity_matchingReady: "พร้อม",
            vdpActivity_matchingReadyDescription: "วางการ์ดครบทุกใบแล้ว",
            vdpActivity_matchingCheckAnswers: "ตรวจคำตอบของคุณ",
            vdpActivity_matchingContinue: "ดำเนินการต่อไปยังกิจกรรมถัดไป",
            vdpActivity_cardStackWithVisualAriaLabel: "กองการ์ดข้อมูลพร้อมภาพประกอบ",
            vdpActivity_cardStackWithVisualAriaDescription: "เรียงการ์ดให้อยู่ในลำดับที่ถูกต้อง",
            vdpActivity_revealCardAriaLabel: "ขั้นตอนการเปิดเผยการ์ดข้อมูล",
            vdpActivity_revealCardAriaDescription: "เลือกการ์ดข้อมูลเพื่อเปิดเผย",
            vdpActivity_dragAndDropAriaLabel: "ขั้นตอนลากและวาง",
            vdpActivity_dragAndDropAriaDescription: "ลากและวางการ์ดข้อมูลในคอลัมน์ที่ถูกต้อง",
            vdpActivity_matchingStepAriaLabel: "ขั้นตอนการจับคู่การ์ดข้อมูล",
            vdpActivity_matchingStepAriaDescription: "ย้ายการ์ดข้อมูลไปยังตำแหน่งที่ถูกต้องเพื่อจับคู่",
            vdpActivity_recallStepAriaLabel: "ขั้นตอนการทบทวน",
            vdpActivity_recallStepAriaDescription: "นี่คือขั้นตอนการทบทวน กรุณาทำตามคำแนะนำ",
            vdpActivity_multipleChoiceAriaLabel: "ขั้นตอนตัวเลือกหลายข้อ",
            vdpActivity_multipleChoiceAriaDescription: "เลือกจากตัวเลือกหลายข้อ",
            vdpActivity_textEntryAriaLabel: "กิจกรรมป้อนข้อความ",
            vdpActivity_textEntryAriaDescription: "ส่งคำตอบของคุณในช่องข้อความ",
            vdpActivity_singleAssetStepAriaLabel: "ขั้นตอนเนื้อหาเดียว",
            vdpActivity_singleAssetStepAriaDescription: "ทำตามคำแนะนำ",
            vdpActivity_comparisonStepAriaLabel: "ขั้นตอนเปรียบเทียบ",
            vdpActivity_comparisonStepAriaDescription: "ทำตามคำแนะนำ",
            vdpActivity_FirstTitlePosition: "คุณอยู่ที่รายการแรก",
            vdpActivity_LastTitlePosition: "คุณอยู่ที่รายการสุดท้าย",
            vdpActivity_videoStepAriaLabel: "ขั้นตอนวิดีโอ",
            vdpActivity_videoStepAriaDescription: "ในขั้นตอนนี้จะมีการแสดงวิดีโอให้ผู้เข้าร่วมชม",
            vdpActivity_progressCheckAriaLabel: "ตรวจสอบความคืบหน้า",
            vdpActivity_progressCheckAriaDescription: "คุณเร็วกว่ากำหนดเวลา พยายามอย่าเร่งเรียนเนื้อหาที่เหลือ",
            vdpActivity_exitStepAriaLabel: "ขั้นตอนออกจากระบบ",
            vdpActivity_exitStepAriaDescription:
                "เราเรียนรู้ผ่านประสบการณ์นี้เสร็จสิ้นแล้ว ตอนนี้คุณสามารถปิดหน้าต่างนี้ได้",
            vdpClipboard_copied: "คัดลอกไปยังคลิปบอร์ดแล้ว!",
            vdpClipboard_copy: "คัดลอกไปยังคลิปบอร์ด",
            vdpClipboard_error: "ไม่สามารถคัดลอกไปยังคลิปบอร์ดได้",
            vdpHud_hide: "ซ่อน",
            vdpHud_show: "แสดง",
            vdpHud_changeNavigatorTitle: "เปลี่ยนผู้นำทางเป็นคนใหม่",
            vdpHud_changeNavigatorText1:
                "คุณเลือก “เปลี่ยนผู้นำทาง” แล้ว ระบบจะสุ่มเลือกผู้นำทางคนใหม่และจะปิดการโต้ตอบทั้งหมดในหน้านี้ชั่วคราว",
            vdpHud_changeNavigatorText2: "คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนผู้นำทาง",
            vdpHud_close: "ปิด",
            vdpHud_accept: "เปลี่ยนผู้นำทาง",
            vdpHud_back: "ย้อนกลับ",
            vdpHud_next: "ถัดไป",
            vdpHud_endExperience: "สิ้นสุดประสบการณ์",
            vdpHud_defaultExitMsg:
                "เราเรียนผ่านรู้ประสบการณ์นี้เสร็จสิ้นแล้ว ขอบคุณที่เข้าร่วม! เราหวังว่าการสนทนาในวันนี้จะมีประโยชน์ คุณสามารถปิดหน้าต่างนี้ได้",
            vdpHud_navigatorReminder: "คุณคือผู้นำทาง กรุณาอ่านข้อความต่อไปนี้:",
            vdpHud_timerNoTimeSpecified: "ส่วนนี้ไม่มีกำหนดเวลา",
            vdpHud_timerRemaining: "เวลาที่เหลือในส่วนนี้",
            vdpHud_timerLessThanOneMinute: "เหลือเวลาไม่ถึง 1 นาที",
            vdpHud_timerOver: "over",
            vdpHud_roleNone: "ไม่ได้กำหนด",
            vdpHud_infoTableSection: "ส่วน",
            vdpHud_infoTableRecommended: "แนะนำ",
            vdpHud_infoTableActual: "จริง",
            vdpHud_infoTableTotal: "รวม",
            vdpHud_infoTableRemaining: "ที่เหลือ",
            vdpHud_infoTeamButton: "ทีม",
            vdpHud_infoTeamButtonDescription:
                "เลือกรายการนี้เพื่อดูรายชื่อผู้เข้าร่วมการฝึกอบรม สุ่มเลือกผู้เข้าร่วมคนใดก็ได้ให้รับบทบาทผู้นำทาง และเปลี่ยนชื่อผู้เข้าร่วมของคุณ",
            vdpHud_infoTeamParticipantJoining: "ผู้ใช้กำลังเข้าร่วม...",
            vdpHud_navigator: "ผู้นำทาง",
            vdpHud_facilitator: "วิทยากร",
            vdpHud_facilitatorMenuMakeNavigator: "ทำให้เป็นผู้นำทาง",
            vdpHud_facilitatorMenuRemoveFacilitator: "ลบบทบาทวิทยากร",
            vdpHud_removeFacilitatorWarningConfirm: "ยืนยัน",
            vdpHud_removeFacilitatorWarningCancel: "ยกเลิก",
            vdpHud_removeFacilitatorWarningMessage:
                "คุณกำลังจะออกจากบทบาทวิทยากร คุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อ",
            vdpHud_progressOnPaceMessage: "ทันตามกำหนดเวลา",
            vdpHud_progressAheadOfPaceMessage: "เร็วกว่ากำหนดเวลา",
            vdpHud_progressBehindPaceMessage: "ช้ากว่ากำหนดเวลา",
            vdpHud_progressTimeRemaining: "เวลาที่เหลือในส่วนนี้ {0}",
            vdpHud_progressStepsInSection: "หน้า {0} จาก {1}",
            vdpHud_zoomControlsTooltip: "การควบคุมการย่อ/ขยายภาพประกอบ",
            vdpHud_zoomControlsDescription: "ใช้การกระทำเหล่านี้เพื่อขยายและย่อภาพประกอบ",
            vdpHud_zoomControlsZoomIn: "ขยาย",
            vdpHud_zoomControlsZoomOut: "ย่อ",
            sidebar_scheduleButton: "ตารางเวลา",
            sidebar_scheduleButtonDescription:
                "ดูสรุปเวลาโดยประมาณในการเรียนแต่ละส่วนและเวลาจริงที่ใช้ในการเรียนแต่ละส่วน",
            sidebar_teamAssignNavigator: "กำหนดผู้นำทาง",
            sidebar_teamChooseRandomly: "เลือกแบบสุ่ม",
            sidebar_teamChooseHelperText:
                "สุ่มเลือกผู้เข้าร่วมที่มีคุณสมบัติเหมาะสมให้รับทำหน้าที่เป็นผู้นำทาง ผู้เข้าร่วมจะไม่ถูกเลือกอีกจนกว่าทุกคนได้รับโอกาสครบแล้ว",
            sidebar_editNameMenuItem: "แก้ไขชื่อ",
            sidebar_editNameSave: "บันทึกการเปลี่ยนแปลง",
            sidebar_nameEditPlaceholder: "กรอกชื่อที่คุณต้องการให้เรียก...",
            sidebar_manageRolesMenuLabel: "จัดการบทบาท",
            sidebar_navigatorDescription:
                "อ่านออกเสียงคำถาม ข้อความแจ้ง และคำแนะนำ นำกลุ่มสัมผัสประสบการณ์ให้ครบโดยการเลือก “ถัดไป” และทำตามคำสั่งอื่นๆ ที่ระบุไว้ในแต่ละหน้า",
            sidebar_facilitatorDescription:
                "ทำหน้าที่เป็นผู้แนะนำตลอดคาบเรียนเพื่อให้แน่ใจว่ากลุ่มมีการหารือกันอย่างจริงจังและการสนทนาไม่ออกนอกประเด็น",
            sidebar_previousButton: "ก่อนหน้า",
            sidebar_previousButtonDescription: "เลือกปุ่มนี้เพื่อเลื่อนคาบเรียนของกลุ่มไปยังหน้าก่อนหน้า",
            sidebar_nextButton: "ถัดไป",
            sidebar_nextButtonDescription: "เลือกปุ่มนี้เพื่อเลื่อนคาบเรียนของกลุ่มไปยังหน้าถัดไป",
            sidebar_lockTooltip: "เฉพาะผู้นำทางเท่านั้นที่สามารถเปลี่ยนหน้าได้",
            sidebar_navigatorBadge: "คุณคือผู้นำทาง",
            sidebar_navigatorBadgeDescription:
                "ผู้นำทางมีหน้าที่รับผิดชอบในการพากลุ่มไปยังขั้นตอนถัดไปในคาบเรียน ใช้ปุ่ม “ถัดไป” และ “ก่อนหน้า” เพื่อช่วยนำทางกลุ่ม",
            sidebar_facilitatorBadge: "คุณคือวิทยากร",
            sidebar_introductionLabel: "การแนะนำ",
            sidebar_introductionInstruction: "อ่านออกเสียงให้กลุ่มฟัง:",
            sidebar_directionsLabel: "คำสั่ง",
            sidebar_nonNavigatorDirectionsLabel: "{0} ทำสิ่งต่อไปนี้:",
            sidebar_directionsInstruction: "ทำสิ่งต่อไปนี้:",
            sidebar_facilitationNotesLabel: "หมายเหตุสำหรับการสอน",
            sidebar_skipButtonDescription: "เลือกปุ่มนี้เพื่อข้ามส่วนต้อนรับสำหรับคาบเรียนของกลุ่มทั้งหมด",
            genUrl_copyLink: "คัดลอกลิงก์",
            genUrl_sessionLinkDetails:
                "ลิงก์คาบเรียนเหล่านี้จะหายไปเมื่อคุณรีเฟรชหน้า กรุณาดาวน์โหลดหรือคัดลอกลิงก์เหล่านี้ก่อนออกจากหน้านี้",
            genUrl_exportAsCsv: "ส่งออกเป็น CSV",
            genUrl_copyTable: "คัดลอกตาราง",
            genUrl_linkExpiration: "วันหมดอายุของลิงก์",
            genUrl_link: "ลิงก์",
            genUrl_title: "เครื่องมือสร้างลิงก์คาบเรียน {0}",
            genUrl_experience: "ประสบการณ์: {0}",
            genUrl_description:
                "คุณสามารถสร้างลิงก์คาบเรียนสำหรับประสบการณ์นี้ได้ในพอร์ทัลนี้ เพื่อสร้างลิงก์คาบเรียน ให้ป้อนจำนวนคาบเรียนที่คุณต้องการสร้าง เลือกวันที่คาบเรียนจะหมดอายุ แล้วเลือก “สร้างคาบเรียน” เมื่อสร้างลิงก์เหล่านี้เสร็จแล้ว ให้คัดลอกและวางลงในโปรแกรมแก้ไขข้อความหรือโปรแกรม Spreadsheet ที่คุณชื่นชอบ เมื่อคุณรีเฟรชหน้า ลิงก์ทั้งหมดจะหายไป",
            genUrl_numSessions: "จำนวนคาบเรียน:",
            genUrl_sessionsExpirationLabel: "ลิงก์คาบเรียนหมดอายุในวันที่:",
            genUrl_sessionsOpenAtLabel: "ลิงก์คาบเรียนเริ่มใช้งานได้ในวันที่:",
            genUrl_numSessionsInputLabel: "จำนวนคาบเรียน",
            genUrl_sessionLanguageLabel: "ภาษาในคาบเรียน",
            genUrl_numSessionsInputTypeError: "ต้องเป็นตัวเลข",
            genUrl_closesOnError: "เลือกวันที่ในอนาคต",
            genUrl_experienceClosesOnError: "เลือกวันที่ก่อน {0}",
            genUrl_notifSuccessSingle: "สร้างสำเร็จ {0} ลิงก์",
            genUrl_notifSuccessMultiple: "สร้างสำเร็จ {0} ลิงก์",
            genUrl_notifErrorGenerating: "ไม่สามารถสร้างคาบเรียนได้ กรุณาลองอีกครั้งหรือติดต่อผู้ดูแลระบบ",
            genUrl_notifErrorProcessing: "ไม่สามารถประมวลผลได้ ติดต่อผู้ดูแลระบบ",
            genUrl_sessionsExpireToday: "คาบเรียนจะหมดอายุวันนี้",
            genUrl_sessionsExpireInOneDay: "คาบเรียนจะหมดอายุใน {0} วัน",
            genUrl_sessionsExpireInDays: "คาบเรียนจะหมดอายุใน {0} วัน",
            genUrl_sessionsOpenToday: "คาบเรียนจะเปิดวันนี้",
            genUrl_sessionsOpenOneDay: "คาบเรียนจะเปิดใน {0} วัน",
            genUrl_sessionsOpenDays: "คาบเรียนจะเปิดใน {0} วัน",
            genUrl_generate: "สร้างคาบเรียน",
            genUrl_schedulingLinkExpired: "ลิงก์การกำหนดเวลาหมดอายุแล้ว",
            genUrl_error: "ข้อผิดพลาด",
            genUrl_dataCollectionLabel: "การเก็บรวบรวมข้อมูล",
            genUrl_dataCollectionDefaultLabel: "ค่าเริ่มต้นการเก็บรวบรวมข้อมูล",
            genUrl_emailContact: "การติดต่อทางอีเมล",
            genUrl_goToSessionRepoReport: "เครื่องมือรายงาน",
            genUrl_archiveButton: "คลังเอกสาร",
            genUrl_resurrectButton: "กู้คืน",
            genUrl_destroyButton: "ทำลาย",
            vdpLoading_waitForContent: "กรุณารอในขณะที่ระบบกำลังโหลดเนื้อหาของคุณ...",
            vdpNotFound_title: "แพลตฟอร์มการสนทนาเชิงโต้ตอบเสมือน",
            vdpNotFound_message: "ไม่พบหน้าที่คุณกำลังมองหา",
            vdpRenderer_refresh: "รีเฟรช",
            vdpRenderer_refreshDescription: "คุณต้องรีเฟรชหน้าเพื่อพยายามเชื่อมต่อกับคาบเรียนอีกครั้ง",
            vdpRenderer_sessionDisconnectedHeader: "คุณถูกตัดการเชื่อมต่อ",
            vpdRenderer_inactiveDisconnectedBody:
                "คุณถูกตัดการเชื่อมต่อเนื่องจากไม่มีการใช้งาน เลือกปุ่มด้านล่างเพื่อพยายามเชื่อมต่ออีกครั้ง",
            vdpRenderer_inactiveHeader: "คุณไม่มีการใช้งาน",
            vdpRenderer_inactiveBody:
                "คุณไม่มีการใช้งานเป็นเวลานานเกินไป เลือกปุ่มด้านล่างเพื่อคงสถานะใช้งาน มิฉะนั้นคุณจะถูกลบออกจากคาบเรียนเมื่อหมดเวลานับถอยหลัง",
            vdpRenderer_setSelfActiveButton: "ตั้งสถานะของคุณเป็นกำลังใช้งาน",
            vdpRenderer_setSelfActiveButtonDescription:
                "เลือกปุ่มนี้เพื่อเป็นผู้เข้าร่วมที่ยังคงใช้งานและเชื่อมต่อกับคาบเรียนปัจจุบัน",
            vdpRenderer_sessionDisconnected: "กำลังพยายามเชื่อมต่ออีกครั้ง",
            vdpRenderer_sessionDisconnectedReload: "กรุณาโหลดใหม่",
            vdpRenderer_endOfSession: "สิ้นสุดคาบเรียน",
            vdpRenderer_invalidMapUrl: "URL ที่ให้มาสำหรับภาพประกอบไม่ถูกต้อง กรุณาติดต่อ Root เพื่อขอความช่วยเหลือ",
            vdpRenderer_unexpectedError: "เกิดข้อผิดพลาดของระบบที่ไม่คาดคิด นี่คือรายละเอียดทางเทคนิค:",
            vdpRenderer_unexpectedErrorMsg: "เกิดข้อผิดพลาดที่ไม่คาดคิด: สถานะคาบเรียนที่ไม่รู้จัก {0}",
            vdpRenderer_defaultEndingMessage: "คุณสามารถปิดหน้าต่างและตัดการเชื่อมต่อจากเสียงได้แล้ว",
            vdpErrorModal_title: "ไม่สามารถโหลดหน้าได้",
            vdpErrorModal_message:
                "เกิดข้อผิดพลาดบางประการ! หากต้องการความช่วยเหลือ กรุณาติดต่อเราที่: vmap.help@rootinc.com",
            vdpErrorModal_body: "ข้อผิดพลาดนี้อาจแก้ไขได้โดยการโหลดหน้านี้ใหม่หรือเลือกจากด้านล่างนี้:",
            vdpErrorModal_button: "โหลดประสบการณ์นี้ใหม่",
            vdpNameEntry_welcomeText: "ยินดีต้อนรับ",
            vdpNameEntry_defaultWelcomeMessage:
                "ในคาบเรียนการสนทนาเชิงโต้ตอบเสมือนจริงนี้ คุณจะสามารถโต้ตอบได้อย่างเต็มที่ เตรียมพร้อมที่จะศึกษาข้อมูลและแบ่งปันความคิดและความเห็นของคุณกับเพื่อนร่วมงาน",
            vdpNameEntry_inputName: "คุณต้องการให้เรียกคุณว่าอะไร",
            vdpNameEntry_inputNamePlaceholder: "กรอกชื่อที่คุณต้องการให้เรียก:",
            vdpNameEntry_inputDepartment: "คุณอยู่ในแผนกไหน",
            vdpNameEntry_inputEmail: "ที่อยู่อีเมลของคุณคืออะไร",
            vdpNameEntry_inputTeam: "คุณอยู่ในทีมไหน",
            vdpNameEntry_saveDescription: "ส่งชื่อที่คุณต้องการให้เรียกแล้วรอให้คาบเรียนเริ่ม",
            vdpNameEntry_continueDescription: "ส่งชื่อที่คุณต้องการให้เรียกแล้วเข้าร่วมคาบเรียนที่กำลังดำเนินอยู่",
            vdpNameEntry_identifiersName: "กรุณาป้อนชื่อ",
            vdpNameEntry_identifiersDuplicateName:
                "ดูเหมือนว่ามี {0} เชื่อมต่ออยู่แล้วในวันนี้ คุณต้องการลองป้อนชื่ออื่นที่ไม่ซ้ำใครหรือไม่ ลองเพิ่มตัวอักษรย่อของนามสกุลของคุณหรือป้อนชื่อเล่น",
            vdpNameEntry_identifiersDepartment: "กรุณาป้อนชื่อแผนก",
            vdpNameEntry_identifiersEmail: "กรุณาป้อนอีเมล",
            vdpNameEntry_identifiersInvalidEmailFormat: "รูปแบบอีเมลไม่ถูกต้อง",
            vdpNameEntry_identifiersTeam: "กรุณาป้อนชื่อทีม",
            vdpNameEntry_welcomeHeader: "ยินดีที่ได้พบคุณ {0}!",
            vdpNameEntry_welcomeAdvance: "พบกับทีมของคุณ",
            vdpNameEntry_claimFacilitatorTitle: "รับบทบาทวิทยากร",
            vdpNameEntry_facilitatorClaimed: "รับบทบาทวิทยากรแล้ว",
            vdpNameEntry_claimFacilitatorButton: "รับเป็นวิทยากร",
            vdpNameEntry_claimFacilitatorModalCancel: "ยกเลิก",
            vdpNameEntry_claimFacilitatorModalParagraphOne:
                "หากคุณเป็นวิทยากรในคาบเรียนนี้ กรุณาเลือก “รับบทบาทวิทยากร” ด้านล่าง",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "ในฐานะวิทยากร คุณจะสามารถควบคุมหน้าแรกๆ เพื่อให้เราเริ่มต้นได้ จากนั้น ระบบจะเลือกผู้นำทางคนใหม่โดยอัตโนมัติ",
            vdpNameEntry_claimFacilitatorModalParagraphThree:
                "หากคุณได้รับเชิญให้เข้าร่วมคาบเรียนนี้ในฐานะผู้เข้าร่วม กรุณาเลือก “ยกเลิก”",
            vdpNameEntry_smallScreenWarningTitle: "เราดีใจที่คุณอยู่ที่นี่!",
            vdpNameEntry_smallScreenWarningBody:
                "... เพื่อประสบการณ์ที่ดีที่สุด กรุณาเข้าสู่ระบบจากแล็ปท็อปหรือเดสก์ท็อป",
            vdpNameEntry_smallScreenWarningDismiss: "เลิก",
            vdpNameEntry_numParticipantsInSession: "ผู้เข้าร่วม {0} คนกำลังรออยู่...",
            vdpNameEntry_oneParticipantsInSession: "ผู้เข้าร่วม 1 คนกำลังรออยู่...",
            vdpNameEntry_save: "บันทึก",
            vdpNameEntry_continue: "ดำเนินการต่อ",
            vdpNameEntry_thanks: "ขอบคุณ {0} เราจะเริ่มต้นในไม่ช้า!",
            vdpNameEntry_intro: "ป้อนชื่อของคุณแล้วรอให้คนอื่นเข้าร่วม เราจะเริ่มต้นในไม่ช้า!",
            vdpNameEntry_introStarted: "ป้อนชื่อของคุณแล้วเข้าร่วมกับเพื่อนร่วมงานของคุณ",
            vdpNameEntry_navDirections: "รอให้ทีมเข้าร่วม",
            vdpValidationErrorMinLength: "กรุณาป้อนอักขระอย่างน้อย {0} ตัว",
            vdpValidationErrorMaxLength: "กรุณาป้อนอักขระไม่เกิน {0} ตัว",
            toolbar_stepOf: "ส่วน {0} หน้า {1} - {2} ({3})",
            toolbar_intro: "ขั้นตอนที่ {0} ของส่วน {1}",
            toolbar_welcome: "ลำดับการต้อนรับ",
            toolbar_exit: "ลำดับการออก",
            toolbar_reloadContent: "โหลดเนื้อหาใหม่",
            toolbar_commentCreationButton: "ความคิดเห็น ({0})",
            toolbar_buildComments: "สร้างความคิดเห็น",
            toolbar_commentListButton: "ดูความคิดเห็น ({0})",
            toolbar_commentCreationTitle: "เพิ่ม “สร้างความคิดเห็น”",
            toolbar_commentCreationDescription:
                "ระบบจะบันทึกความคิดเห็นและส่งไปยังทีมสร้าง กรุณาใส่ชื่อหรืออักษรย่อของชื่อของคุณไว้กับความคิดเห็นของคุณ",
            toolbar_commentCreationCancelButton: "ยกเลิก",
            toolbar_commentCreationSubmitButton: "โพสต์ความคิดเห็น",
            toolbar_commentCreationSubmitButtonDisabled: "กำลังโพสต์ความคิดเห็น...",
            toolbar_commentCreationPlaceholder: "ป้อนความคิดเห็น...",
            toolbar_previewMode: "โหมดแสดงตัวอย่าง:",
            toolbar_aboutPreviewMode: "เกี่ยวกับโหมดแสดงตัวอย่าง",
            toolbar_focusAreaSelector: "ตัวเลือกเรื่องที่ต้องมุ่งเน้น",
            toolbar_hotspotSelector: "ตัวเลือกจุดที่น่าสนใจ",
            toolbar_previewModeModalTitle: "โหมดแสดงตัวอย่าง",
            toolbar_previewModeModalFirstIntroduction:
                "คุณกำลังดูประสบการณ์นี้ในโหมดแสดงตัวอย่าง มุมมองนี้ออกแบบมาสำหรับการตรวจสอบภายในของ Root เท่านั้นและมีไว้เข้าถึงเครื่องมือการสร้าง",
            toolbar_previewModeModalSecondIntroduction:
                "ตัวเลือกเหล่านี้มีอยู่ในโหมดแสดงตัวอย่างเท่านั้นและจะไม่ปรากฏแก่ลูกค้าหรือผู้เข้าร่วม",
            toolbar_previewModeModalReloadContent: "โหลดเนื้อหาใหม่",
            toolbar_previewModeModalReloadContentDescription:
                "ใช้ปุ่มนี้เพื่อรีเฟรชประสบการณ์ที่มีข้อมูลอัปเดตล่าสุดจาก Contentful โดยไม่ต้องโหลดคาบเรียนใหม่",
            toolbar_previewModeModalComments: "ความคิดเห็น",
            toolbar_previewModeModalCommentsDescription:
                "เพิ่มความคิดเห็นเพื่อให้ผลตอบรับและข้อเสนอแนะเกี่ยวกับการเปลี่ยนแปลงที่ต้องทำ ความคิดเห็นจะถูกบันทึกใน Contentful สำหรับทีมสร้าง",
            toolbar_previewModeModalMapCoordinates: "พิกัดภาพประกอบ",
            toolbar_previewModeModalMapCoordinatesDescription: "คัดลอกพิกัดของภาพประกอบ",
            toolbar_previewModeModalHotspotSelector: "ตัวเลือกจุดที่น่าสนใจ",
            toolbar_previewModeModalHotspotSelectorDescription:
                "ดูตำแหน่งจุดที่น่าสนใจและคัดลอกพิกัดเพื่อใช้ใน Contentful",
            coordinatePreview_coordinatesSelectorTitle: "ตัวเลือกพิกัด",
            coordinatePreview_hotspotMode: "โหมดจุดที่น่าสนใจ",
            coordinatePreview_hotspotModeCoordinatesCopiedToast: "คัดลอกพิกัดจุดที่น่าสนใจไปยังคลิปบอร์ดของคุณแล้ว",
            coordinatePreview_focusAreaMode: "โหมดเรื่องที่ต้องมุ่งเน้น",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast:
                "คัดลอกพิกัดเรื่องที่ต้องมุ่งเน้นไปยังคลิปบอร์ดของคุณแล้ว",
            coordinatePreview_directionsTitle: "คำสั่งสำหรับตัวเลือกพิกัด",
            coordinatePreview_hotspotDirectionsLineOne: "เลือกจุดบนภาพประกอบเพื่อสร้างจุดที่น่าสนใจ",
            coordinatePreview_hotspotDirectionsLineTwo:
                "เลือกปุ่ม “คัดลอก” บนแต่ละจุดที่น่าสนใจเพื่อคัดลอกพิกัดไปยัง Contentful",
            coordinatePreview_focusAreaDirectionsLineOne: "เลือกและลากเพื่อวาดสี่เหลี่ยมผืนผ้าบนภาพประกอบ",
            coordinatePreview_focusAreaDirectionsLineTwo: "วางสี่เหลี่ยมผืนผ้าทับพื้นที่ที่จะแสดงในขั้นตอน",
            coordinatePreview_focusAreaDirectionsLineThree:
                "เลือกปุ่ม “คัดลอก” บนแต่ละเรื่องที่ต้องมุ่งเน้นเพื่อคัดลอกพิกัดไปยัง Contentful",
            coordinatePreview_reset: "รีเซ็ต",
            coordinatePreview_done: "เสร็จสิ้น",
            navigatorDefaultInstructions_selectNextToContinue: "เลือก ***ถัดไป*** เพื่อดำเนินการต่อ",
            navigatorDefaultInstructions_progressCheckLineOne: "เรามาถึงจุดสิ้นสุดของส่วน {0} แล้ว",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace: "เราทันตามกำหนดเวลา เราทำให้ได้ดีอย่างนี้ต่อไปนะ!",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "เราเร็วกว่ากำหนดเวลา ลองพยายามอย่าเร่งเรียนเนื้อหาที่เหลือ",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "เราช้ากว่ากำหนดเวลา เราต้องเร่งมือให้เร็วขึ้นเพื่อเรียนคาบเรียนนี้ให้เสร็จตามเวลาที่กำหนด",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav:
                "ในหน้าถัดไป เราจะแนะนำผู้นำทางคนใหม่สำหรับส่วน {0}",
            maintenanceWindow_heading: "การดูแลและปรับปรุงระบบจะมีขึ้นในวันที่ {0}, {1} ตั้งแต่ {2} - {3} {4}",
            maintenanceWindow_body: "แอปพลิเคชันอาจไม่สามารถใช้งานได้ในช่วงเวลานี้",
            maintenanceError_overlap:
                "วันที่ที่คุณเลือกขัดแย้งกับช่วงเวลาการดูแลและปรับปรุงระบบที่กำหนดไว้ในวันที่ {0} การดูแลและปรับปรุงระบบจะมีขึ้นทุกสัปดาห์ตั้งแต่ {1} ถึง {2} กรุณาตรวจสอบให้แน่ใจว่าวันที่เริ่มต้นและวันหมดอายุไม่ทับซ้อนกับช่วงเวลาการดูแลและปรับปรุงระบบ",
            progressCheck_sectionTitle: "ส่วน {0} จาก {1}",
            progressCheck_paceCheckTitle: "ตรวจสอบความคืบหน้าในการเรียน",
            progressCheck_paceMessageOnPace: "ทำให้ได้ดีอย่างนี้ต่อไปนะ!",
            progressCheck_paceMessageAheadOfPace: "พยายามอย่าเร่งเรียนเนื้อหาที่เหลือ",
            progressCheck_paceMessageBehindPace:
                "คุณต้องเร่งมือให้เร็วขึ้นเพื่อเรียนคาบเรียนนี้ให้เสร็จตามเวลาที่กำหนด",
            progressCheck_navigatorChangeTitle: "เปลี่ยนผู้นำทาง",
            progressCheck_navigatorChangeMessage: "ขอบคุณที่เป็นผู้นำ {0} จะมีการกำหนดผู้นำทางคนใหม่ในหน้าถัดไป",
            vdpIntro_title: "การแนะนำ",
            vdpIntro_subtitle: "ก่อนที่เราจะเริ่มต้น มีสิ่งที่ควรทราบบางประการ...",
            vdpIntro_joinTheTeam: "เข้าร่วมทีม",
            vdpIntro_facilitatorRole: "บทบาทวิทยากร",
            vdpIntro_navigatorRole: "บทบาทผู้นำทาง",
            vdpIntro_participantRole: "บทบาทผู้เข้าร่วม",
            vdpIntro_iceBreaker: "พบกับทีม",
            vdpIntro_exploreTheVisual: "ภาพประกอบ",
            vdpIntro_skipShort: "ข้าม",
            vdpIntro_skipLong: "ข้ามการแนะนำ",
            vdpIntro_facilitatorInstructionsOne:
                "ยินดีต้อนรับ! คาบเรียนในวันนี้จะแตกต่างจากหลักสูตรหรือการฝึกอบรมอื่นๆ ที่คุณเคยเข้าร่วม",
            vdpIntro_facilitatorInstructionsTwo: "ไม่มีครูหรือผู้ฝึกสอน คุณจะเรียนรู้จากกันและกันและข้อมูลบนหน้าจอ",
            vdpIntro_facilitatorInstructionsThree:
                "บทบาทของฉันในฐานะวิทยากรคือการควบคุมการสนทนาให้ไม่ออกนอกประเด็นและเป็นไปตามกำหนดเวลา",
            vdpIntro_navigatorInstructionsOne:
                "ในอีกไม่กี่หน้าถัดไป ฉันจะทำหน้าที่เป็นผู้นำทางของเราด้วย บทบาทของผู้นำทางคือการอ่านคำแนะนำในแต่ละหน้า เลือกปุ่ม ***ถัดไป*** เพื่อพาเราสัมผัสประสบการณ์นี้ และปฏิบัติตามคำสั่งอื่นๆ ตามที่ระบบแจ้ง",
            vdpIntro_navigatorInstructionsTwo: "บทบาทผู้นำทางจะถูกกำหนดแบบสุ่มและจะเปลี่ยนทุก 2-3 หน้า",
            vdpIntro_participantInstructionsOne:
                "ทุกคนคือผู้เข้าร่วม! บทบาทของคุณคือการมีส่วนร่วมอย่างกระตือรือร้นโดยการแบ่งปันมุมมองของคุณกับกลุ่ม คุณจะช่วยให้คาบเรียนดำเนินไปอย่างราบรื่นโดยการผลัดกันพูด เคารพความคิดและความเห็นของผู้อื่น และควบคุมการสนทนาให้ไม่ออกนอกประเด็น",
            vdpIntro_participantInstructionsTwo:
                "คุณสามารถดูได้ว่ามีใครบ้างในคาบเรียนและใครมีบทบาทใดโดยใช้ปุ่ม ***ทีม*** ที่มุมซ้ายล่าง",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "ถัดไป เราทุกคนจะมีโอกาสได้ลองรับบทบาทเป็นผู้นำทางในขณะที่เราแนะนำตัวเอง",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "เมื่อคุณเห็นแบนเนอร์ ***คุณคือผู้นำทาง*** เหนือคำแนะนำ นั่นหมายความว่าถึงตาคุณที่จะอ่านและปฏิบัติตามคำสั่ง มีใครมีคำถามไหม",
            vdpIntro_iceBreakerInstructionsOne: "สวัสดี ฉันชื่อ {0}",
            vdpIntro_iceBreakerInstructionsDefault: "ข้อเท็จจริงอย่างหนึ่งเกี่ยวกับฉันคือ _______",
            vdpIntro_mapInstructionsOne:
                "ตอนนี้เราทุกคนก็ได้พบกันแล้ว มาทำความเข้าใจเพิ่มเติมเกี่ยวกับคาบเรียนของเราในวันนี้กันดีกว่า",
            vdpIntro_mapInstructionsTwo:
                "การสนทนาของเราจะมุ่งเน้นไปที่ภาพหนึ่งซึ่งเรียกว่าภาพประกอบ *Learning Map*® ซึ่งเป็นภาพเชิงเปรียบเทียบที่แสดงให้เห็นแง่มุมต่างๆ ของธุรกิจของเรา ซึ่งเราจะสำรวจไปด้วยกัน ",
            vdpIntro_iceBreakerIntroDirections: "ตอบคำถามใดๆ ที่มี",
            vdpEnd_title: "ขอบคุณ",
            vdpEnd_body:
                "เราเรียนผ่านรู้ประสบการณ์นี้เสร็จสิ้นแล้ว ขอบคุณที่เข้าร่วม! เราหวังว่าการสนทนาในวันนี้จะมีประโยชน์",
            vdpEnd_surveyRequest:
                "กรุณาใช้เวลาสักครู่เพื่อแบ่งปันความคิดและปฏิกิริยาของคุณที่มีต่อข้อมูลที่เราพูดคุยกัน ความคิดเห็นของคุณมีความสำคัญต่อความสำเร็จของเรา",
            vdpEnd_surveyThankYou: "ขอบคุณสำหรับคำตอบของคุณ",
            vdpEnd_sessionComplete: "คาบเรียนนี้เสร็จสิ้นแล้ว",
            vdpEnd_closeWindow: "คุณสามารถปิดหน้าต่างนี้ได้",
            privacyBanner_title: "ความเป็นส่วนตัวของข้อมูล",
            privacyBanner_message:
                "การปกป้องข้อมูลส่วนบุคคลของคุณมีความสำคัญมากสำหรับ Accenture และข้อมูลส่วนบุคคลใดๆ ที่คุณระบุผ่านเครื่องมือนี้อาจถูกประมวลผลโดย Accenture Accenture มุ่งมั่นที่จะรักษาความปลอดภัยของข้อมูลส่วนบุคคลของคุณและประมวลผลข้อมูลส่วนบุคคลของคุณตามกฎหมายความเป็นส่วนตัวที่บังคับใช้และนโยบายภายในของเรา ข้อมูลส่วนบุคคลที่ Accenture ประมวลผลอาจถูกถ่ายโอนข้ามประเทศหรือเข้าถึงได้จากประเทศอื่นระหว่างธุรกิจทั่วโลกของ Accenture และระหว่างหน่วยงานและบริษัทในเครือ รวมถึงบุคคลที่สาม ก่อนที่คุณจะใช้เครื่องมือนี้ Accenture ขอเชิญให้คุณอ่านคำชี้แจงความเป็นส่วนตัว [คำชี้แจงความเป็นส่วนตัว]({1}) และ [นโยบายคุกกี้]({0}) อย่างละเอียด ซึ่งมีข้อมูลสำคัญเกี่ยวกับเหตุผลและวิธีที่ Accenture ประมวลผลข้อมูลส่วนบุคคลของคุณ",
            privacyBanner_dismiss: "ตกลง",
            privacyBanner_dismissDescription:
                "การดำเนินการนี้จะปิดการแจ้งเตือนที่อธิบายเกี่ยวกับนโยบายความเป็นส่วนตัวและคุกกี้ของเว็บไซต์",
            adminMessage_unauthorized: "คุณไม่ได้รับอนุญาตให้ดำเนินการต่อไปนี้: {0}",
            adminMessage_unauthorized_viewExp: "ดูประสบการณ์ทั้งหมด",
            sessionReport_submitButton: "สร้างรายงาน",
            sessionReport_header_title: "เครื่องมือรายงานคาบเรียน {0}",
            sessionReport_header_experience: "ชื่อประสบการณ์: {0}",
            sessionReport_header_errorTitle: "เกิดข้อผิดพลาดในการโหลด",
            sessionReport_header_errorSubtitle: "ไม่สามารถดึงข้อมูลประสบการณ์ได้",
            sessionReport_switch_unknown: "ประเภทผลลัพธ์รายงานที่ไม่รู้จัก",
            sessionReport_results_downloadResults: "ดาวน์โหลดผลลัพธ์",
            sessionReport_results_noRecordsTitle: "ไม่พบระเบียนข้อมูล",
            sessionReport_results_noRecordsSubTitle: "ไม่มีข้อมูลที่ตรงกับเกณฑ์ดังกล่าว",
            sessionReport_results_errorTitle: "เกิดข้อผิดพลาดในการสร้างรายงาน",
            sessionReport_basicInfo_startDate: "วันที่เริ่มต้น",
            sessionReport_basicInfo_endDate: "วันที่สิ้นสุด",
            sessionReport_basicInfo_useSeedData: "ใช้ข้อมูลต้นแบบ",
            sessionReport_basicInfo_useSeedData_help:
                "ระบุว่ารายงานที่สร้างขึ้นควรรวมข้อมูลตัวอย่างแบบสุ่มที่สร้างขึ้นหรือไม่ ตัวเลือกนี้ใช้สำหรับการทดสอบเท่านั้น",
            sessionReport_switch_basicInfo: "ข้อมูลคาบเรียนพื้นฐาน",
            activityReport_basicInfo_startDate: "วันที่เริ่มต้น",
            activityReport_basicInfo_endDate: "วันที่สิ้นสุด",
            activityReport_basicInfo_useSeedData: "ใช้ข้อมูลต้นแบบ",
            activityReport_basicInfo_useSeedData_help:
                "ระบุว่ารายงานที่สร้างขึ้นควรรวมข้อมูลตัวอย่างแบบสุ่มที่สร้างขึ้นหรือไม่ ตัวเลือกนี้ใช้สำหรับการทดสอบเท่านั้น",
            activityReport_switch_basicInfo: "ข้อมูลกิจกรรมพื้นฐาน",
            activityReport_basicInfoAggregated_startDate: "วันที่เริ่มต้น",
            activityReport_basicInfoAggregated_endDate: "วันที่สิ้นสุด",
            activityReport_basicInfoAggregated_useSeedData: "ใช้ข้อมูลต้นแบบ",
            activityReport_basicInfoAggregated_useSeedData_help:
                "ระบุว่ารายงานที่สร้างขึ้นควรรวมข้อมูลตัวอย่างแบบสุ่มที่สร้างขึ้นหรือไม่ ตัวเลือกนี้ใช้สำหรับการทดสอบเท่านั้น",
            activityReport_switch_basicInfoAggregated: "ข้อมูลกิจกรรมพื้นฐานแบบรวม",
            multipleChoiceReport_basicInfo_startDate: "วันที่เริ่มต้น",
            multipleChoiceReport_basicInfo_endDate: "วันที่สิ้นสุด",
            multipleChoiceReport_basicInfo_useSeedData: "ใช้ข้อมูลต้นแบบ",
            multipleChoiceReport_basicInfo_useSeedData_help:
                "ระบุว่ารายงานที่สร้างขึ้นควรรวมข้อมูลตัวอย่างแบบสุ่มที่สร้างขึ้นหรือไม่ ตัวเลือกนี้ใช้สำหรับการทดสอบเท่านั้น",
            multipleChoiceReport_switch_basicInfo: "ข้อมูลตัวเลือกหลายข้อพื้นฐาน",
            multipleChoiceReport_basicInfo_activityId: "ป้อนรหัสกิจกรรม",
            multipleChoiceReport_basicInfoAggregated_startDate: "วันที่เริ่มต้น",
            multipleChoiceReport_basicInfoAggregated_endDate: "วันที่สิ้นสุด",
            multipleChoiceReport_basicInfoAggregated_useSeedData: "ใช้ข้อมูลต้นแบบ",
            multipleChoiceReport_basicInfoAggregated_useSeedData_help:
                "ระบุว่ารายงานที่สร้างขึ้นควรรวมข้อมูลตัวอย่างแบบสุ่มที่สร้างขึ้นหรือไม่ ตัวเลือกนี้ใช้สำหรับการทดสอบเท่านั้น",
            multipleChoiceReport_switch_basicInfoAggregated: "ข้อมูลตัวเลือกหลายข้อพื้นฐานแบบรวม",
            multipleChoiceReport_basicInfoAggregated_activityId: "ป้อนรหัสกิจกรรม",
            dragAndDropReport_basicInfo_useSeedData: "ใช้ข้อมูลต้นแบบ",
            dragAndDropReport_basicInfo_useSeedData_help:
                "ระบุว่ารายงานที่สร้างขึ้นควรรวมข้อมูลตัวอย่างแบบสุ่มที่สร้างขึ้นหรือไม่ ตัวเลือกนี้ใช้สำหรับการทดสอบเท่านั้น",
            dragAndDropReport_basicInfo_startDate: "วันที่เริ่มต้น",
            dragAndDropReport_basicInfo_endDate: "วันที่สิ้นสุด",
            dragAndDropReport_switch_basicInfo: "ข้อมูลการลากและวางพื้นฐาน",
            dragAndDropReport_basicInfo_activityId: "ป้อนรหัสกิจกรรม",
            dragAndDropReport_basicInfoAggregated_useSeedData: "ใช้ข้อมูลต้นแบบ",
            dragAndDropReport_basicInfoAggregated_useSeedData_help:
                "ระบุว่ารายงานที่สร้างขึ้นควรรวมข้อมูลตัวอย่างแบบสุ่มที่สร้างขึ้นหรือไม่ ตัวเลือกนี้ใช้สำหรับการทดสอบเท่านั้น",
            dragAndDropReport_basicInfoAggregated_startDate: "วันที่เริ่มต้น",
            dragAndDropReport_basicInfoAggregated_endDate: "วันที่สิ้นสุด",
            dragAndDropReport_switch_basicInfoAggregated: "ข้อมูลการลากและวางพื้นฐานแบบรวม",
            dragAndDropReport_basicInfoAggregated_activityId: "ป้อนรหัสกิจกรรม",
            confirmDialog_confirmValuePrompt: "เพื่อยืนยันการดำเนินการนี้ ให้ป้อนค่า __*{0}*__ ในช่องด้านล่าง",
            confirmDialog_confirmButton: "ยืนยัน",
            confirmDialog_closeButton: "ปิด",
            confirmDialog_error_checkBox: "คุณต้องทำเครื่องหมายในช่องจึงจะยืนยันการดำเนินการนี้สำเร็จ",
            confirmDialog_error_confirmValue: "คุณต้องป้อนค่า __*{0}*__ จึงจะยืนยันการดำเนินการนี้สำเร็จ",
            confirmDialog_scheduler_title: "ยืนยันการจัดเก็บในคลังเอกสาร",
            confirmDialog_scheduler_body:
                "คุณกำลังจะจัดเก็บประสบการณ์ที่ชื่อว่า {0} ในคลังเอกสาร การดำเนินการนี้จะปิดการเข้าถึงประสบการณ์ รวมถึงลิงก์คาบเรียนใดๆ ที่อาจถูกสร้างขึ้นสำหรับคาบเรียนในอนาคต เพื่อยืนยันการดำเนินการนี้ ให้ทำเครื่องหมายในช่องและป้อนชื่อประสบการณ์",
            confirmDialog_scheduler_resurrectTitle: "ยืนยันการกู้คืน",
            confirmDialog_scheduler_resurrectBody:
                "คุณกำลังจะกู้คืนประสบการณ์ที่ชื่อว่า {0} การดำเนินการนี้จะช่วยให้เข้าถึงประสบการณ์ได้ ลิงก์ใดๆ ที่สร้างขึ้นสำหรับคาบเรียนในอนาคตจะสามารถใช้ได้ โปรดทราบว่าคุณอาจต้องอัปเดตวันหมดอายุสำหรับประสบการณ์นี้หลังจากที่การดำเนินการนี้เสร็จสิ้น เพื่อยืนยันการดำเนินการนี้ ให้ทำเครื่องหมายในช่องและป้อนชื่อประสบการณ์",
            confirmDialog_scheduler_destroyTitle: "ยืนยันการทำลาย",
            confirmDialog_scheduler_destroyBody:
                "คุณกำลังจะทำลายประสบการณ์ที่ชื่อว่า {0} นี่เป็นการดำเนินการที่ไม่สามารถกู้คืนและไม่สามารถยกเลิกได้ ก่อนที่จะดำเนินการนี้ คุณต้องยืนยันสิ่งต่อไปนี้: \r\n1. เนื้อหาทั้งหมดของประสบการณ์ได้ถูกดาวน์โหลดและจัดเก็บไว้ในคลังเอกสารของโครงการแล้ว\r\n2. มีการบันทึกวิดีโอการเรียนรู้ผ่านประสบการณ์นี้และจัดเก็บไว้ในโครงการแล้ว\r\n3. ติดต่อกับผู้ดูแลโครงการแล้ว (หากมีรายชื่ออยู่ในช่อง Contentful ในระบบของเรา) เพื่อยืนยันว่าการลบเป็นที่ยอมรับได้\r\n4. ข้อมูลคลังคาบเรียน - กิจกรรมการป้อนข้อความและคลังข้อมูลทั่วไป - ได้ถูกดาวน์โหลดและจัดเก็บไว้ในโครงการแล้ว\r\n\r\nเพื่อยืนยันการดำเนินการนี้ ให้ทำเครื่องหมายในช่องและป้อนชื่อประสบการณ์",
            confirmDialog_dataCollectionDefault_title: "ยืนยันการเปลี่ยนแปลงค่าเริ่มต้นการเก็บรวบรวมข้อมูล",
            confirmDialog_dataCollectionDefault_body:
                "คุณได้เลือกที่จะ {0} คุณสมบัติการเก็บรวบรวมข้อมูลในประสบการณ์ {1} ซึ่งจะส่งผลกระทบต่อคาบเรียนทั้งหมดที่สร้างขึ้นในอนาคตสำหรับประสบการณ์นี้ คุณแน่ใจหรือไม่ว่าต้องการ {0} การเก็บรวบรวมข้อมูลสำหรับประสบการณ์นี้",
            confirmDialog_dataCollectionDefault_body_enable: "เปิดใช้งาน",
            confirmDialog_dataCollectionDefault_body_disable: "ปิดใช้งาน",
            confirmDialog_enable_NavigatorTitle: "คุณแน่ใจหรือไม่",
            confirmDialog_enable_NavigatorBody:
                "หากเลือก “ยืนยัน” คุณจะทำให้กลุ่มเริ่มคาบเรียนโดยการอ่านออกเสียงข้อมูลใน 2-3 หน้าถัดไป เลือก “ปิด” หากคุณไม่ต้องการเป็นผู้อ่านคนแรก",
            vdpNameEntry_NavigatorSelectionMessage: "เราต้องการผู้นำทางเพื่ออ่านออกเสียงคำแนะนำเพื่อให้เราเริ่มต้นได้",
            vdpNameEntry_NavigatorButtonMessage: "เลือกที่นี่เพื่อทำหน้าที่เป็นผู้อ่านคนแรก",
        },
    },
};

export default APP_STRING as any;
